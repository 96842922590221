import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router';
import PagesOverviewComponent from './pages/PagesOverviewComponent';
import PageCreatePageComponent from './pages/PageCreatePageComponent';
import PageEditComponent from './pages/PageEditComponent';

function PagesComponent({ match }: RouteComponentProps) {
    return (
        <section className="app-content content--pages">
            <Switch>
                <Route path={`${match.path}/`} component={PagesOverviewComponent} exact={true} />
                <Route path={`${match.path}/create`} component={PageCreatePageComponent} />
                <Route path={`${match.path}/:pageID`} component={PageEditComponent} />
            </Switch>
        </section>
    );
}

export default PagesComponent;
// PageCreateComponent
