import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    ContainerComponent,
    ContainerHeader,
    ContainerHeadline,
    ContainerInfo,
} from 'components/Containers';
import { Portal } from 'store/portals/types';

function PortalIndexComponent({ portal }: { portal: Portal }) {
    const { t } = useTranslation();
    return (
        <ContainerComponent>
            <ContainerHeader>
                <ContainerHeadline>{portal.name}</ContainerHeadline>
            </ContainerHeader>
            <ContainerInfo>{t('portals:overview.info')}</ContainerInfo>
        </ContainerComponent>
    );
}

export default PortalIndexComponent;
