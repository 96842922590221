import { combineReducers } from 'redux';
import { PageActionTypes, PortalActionTypes } from 'store/actions';
import { PageListAction, PagePaginationMap, PageItems, PageAction, PagePagination } from './types';

export const initialPaginationMapState = Object.freeze<PagePaginationMap>({});
export const initialPageItems = Object.freeze<PageItems>({});

const pages = (state: PagePaginationMap = initialPaginationMapState, action: PageListAction) => {
    const { type, payload } = action;
    switch (type) {
        case PageActionTypes.PAGE_LIST_REQUEST: {
            const currentPage = Object.assign({}, state[payload.page]);
            const updatedPage = Object.assign({}, currentPage, {
                isFetching: payload.isFetching,
                error: null,
            });
            return Object.assign({}, state, {
                [payload.page]: updatedPage,
            });
        }
        case PageActionTypes.PAGE_LIST_FAILURE: {
            const currentPage = Object.assign({}, state[payload.page]);
            const updatedPage = Object.assign({}, currentPage, {
                isFetching: payload.isFetching,
                error: payload.error,
            });
            return Object.assign({}, state, {
                [payload.page]: updatedPage,
            });
        }
        case PageActionTypes.PAGE_LIST_SUCCESS: {
            const currentPage = Object.assign({}, state[payload.page]);
            const updatedPage = Object.assign({}, currentPage, {
                isFetching: payload.isFetching,
                error: null,
                ids: (payload.pages || []).map((page) => page.id),
            });
            return Object.assign({}, state, {
                [payload.page]: updatedPage,
            });
        }
        default:
            return state;
    }
};

const pageItems = (state: PageItems = initialPageItems, action: PageListAction | PageAction) => {
    const { type, payload } = action;
    switch (type) {
        case PortalActionTypes.PORTAL_PAGES_SUCCESS:
        case PageActionTypes.PAGE_LIST_SUCCESS: {
            let _pages = {};
            for (const page of payload.pages || []) {
                _pages = Object.assign({}, _pages, {
                    [page.id]: {
                        isFetching: false,
                        node: page,
                    },
                });
            }
            return Object.assign({}, state, _pages);
        }
        case PortalActionTypes.PORTAL_GET_SUCCESS: {
            const {
                portal: { root_page },
            } = payload;
            if (!root_page) {
                return state;
            }
            return Object.assign({}, state, {
                [root_page.id]: {
                    isFetching: false,
                    node: root_page,
                },
            });
        }
        case PageActionTypes.PAGE_GET_SUCCESS: {
            return Object.assign({}, state, {
                [payload.id]: {
                    isFetching: false,
                    node: payload.page,
                },
            });
        }
        default:
            return state;
    }
};

const currentPage = (currentPage = 1, action: PageListAction) => {
    switch (action.type) {
        case PageActionTypes.PAGE_LIST_REQUEST:
            return action.payload.page;
    }
    return currentPage;
};

const pagination = combineReducers<PagePagination, PageListAction>({
    pages,
    currentPage,
});

export default combineReducers({
    pages: pageItems,
    pagination,
});
