import React from 'react';
import { useSelector } from 'react-redux';
import { viewerSelector } from 'store/viewer/selectors';

function DashboardComponent() {
    const { isFetching, viewer } = useSelector(viewerSelector);
    return (
        <div className="app-content content-pages">
            <div className="overview pages-overview">
                <pre>{JSON.stringify(viewer, null, 2)}</pre>
            </div>
        </div>
    );
}

export default DashboardComponent;
