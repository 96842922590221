import React from 'react';
import styled from '@emotion/styled';

export const ListHeadline = styled.h1`
    font-size: 18px;
`;

export const ListHeader = styled.header`
    padding: 0.25rem 0.5rem;
    border-bottom: 1px solid #707070;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${ListHeadline} {
        margin-bottom: 0;
    }
`;

export const ListControls = styled.div`
    .button__text {
        display: none;
    }
`;

export const ListItemControls = styled.div`
    display: flex;
    flex-shrink: 0;
    align-self: stretch;
    align-items: center;
    .button__text {
        display: none;
    }
`;
export const ListItemText = styled.span``;
export const ListItemField = styled.div`
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    align-items: center;
`;

export function ListItemTitleField({ children }) {
    return (
        <ListItemField>
            <ListItemText>{children}</ListItemText>
        </ListItemField>
    );
}

export const ListItem = styled.li`
    display: flex;
    padding: 0.3rem 0.5rem;
    color: #4c4c4c;
`;

export const ListBody = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    ${ListItem}:nth-of-type(2n+1) {
        background-color: #e0e0e0;
    }
`;

export const ListContainer = styled.section`
display: flex;
flex-direction: column;
padding: 1rem 2rem;
${ListHeader} + ${ListBody} {
    margin-top: 1rem;
}
`;
