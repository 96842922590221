import { ViewerActionTypes } from 'store/actions';
import { LoginAction, Viewer, ViewerAction, LogoutAction } from './types';

function requestLogin(): LoginAction {
    return {
        type: ViewerActionTypes.LOGIN_REQUEST,
        payload: {
            isFetching: true,
            error: null,
        },
    };
}

function receiveLogin(user: Viewer): LoginAction {
    return {
        type: ViewerActionTypes.LOGIN_SUCCESS,
        payload: {
            isFetching: false,
            error: null,
            viewer: user,
        },
    };
}

function errorLogin(message: string): LoginAction {
    return {
        type: ViewerActionTypes.LOGIN_FAILURE,
        payload: {
            isFetching: false,
            error: message,
        },
    };
}

function requestViewer(): ViewerAction {
    return {
        type: ViewerActionTypes.VIEWER_REQUEST,
        payload: {
            isFetching: true,
            error: null,
        },
    };
}

export function receiveViewer(user: Viewer): ViewerAction {
    return {
        type: ViewerActionTypes.VIEWER_SUCCESS,
        payload: {
            isFetching: false,
            error: null,
            viewer: user,
        },
    };
}

function errorViewer(message: string): ViewerAction {
    return {
        type: ViewerActionTypes.VIEWER_FAILURE,
        payload: {
            isFetching: false,
            error: message,
        },
    };
}

function requestLogout(): LogoutAction {
    return {
        type: ViewerActionTypes.LOGOUT_REQUEST,
        payload: {
            isFetching: true,
            error: null,
        },
    };
}

function receiveLogout(): LogoutAction {
    return {
        type: ViewerActionTypes.LOGOUT_SUCCESS,
        payload: {
            isFetching: false,
            error: null,
        },
    };
}

function errorLogout(message: string): LogoutAction {
    return {
        type: ViewerActionTypes.LOGOUT_FAILURE,
        payload: {
            isFetching: false,
            error: message,
        },
    };
}

type AsyncViewerAction = (a: any) => Promise<ViewerAction>;
type AsyncLoginAction = (a: any) => Promise<LoginAction>;
type AsyncLogoutAction = (a: any) => Promise<LogoutAction>;

export function fetchUser(): AsyncViewerAction {
    return async (dispatch) => {
        await dispatch(requestViewer());
        const res = await fetch('/api/auth/me');
        if (res.status >= 200 && res.status <= 300) {
            const user = await res.json();
            return dispatch(receiveViewer(user));
        }
        throw dispatch(errorViewer('Invalid Credentials'));
    };
}

export function loginUser(username: string, password: string): AsyncLoginAction {
    return async (dispatch) => {
        await dispatch(requestLogin());
        const res = await fetch('/api/auth/login', {
            method: 'POST',
            mode: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        });
        if (res.status >= 200 && res.status <= 300) {
            const user = await res.json();
            return dispatch(receiveLogin(user));
        }
        throw dispatch(errorLogin('Invalid Credentials'));
    };
}

export function logoutUser(): AsyncLogoutAction {
    return async (dispatch) => {
        await dispatch(requestLogout());
        const res = await fetch('/api/auth/logout', {
            method: 'POST',
            mode: 'same-origin',
        });
        if (res.status === 303) {
            return dispatch(receiveLogout());
        }
        throw dispatch(errorLogout('No Session'));
    };
}
