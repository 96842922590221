import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { templateSelector } from 'store/templates/selectors';
import { Loading } from 'components/general/LoadinInfo';
import { fetchTemplate } from 'store/templates/actions';
import { InputGroup, InputLabel } from '../../../components/inputs/InputComponent';

type ColumnSelectionProps = {
    template: string;
    value: string;
    onChange: (x: any) => void;
};

function ColumnSelection({ template, value, onChange: handleChangeFn }: ColumnSelectionProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isFetching, node } = useSelector(templateSelector(template));
    const [resolved, setResolved] = useState(false);

    useEffect(() => {
        dispatch(fetchTemplate(template))
            .then((res) => {
                setResolved(true);
            })
            .catch((res) => {
                setResolved(true);
            });
    }, [dispatch, template]);

    if (!resolved || isFetching) {
        return <Loading />;
    }
    if (!node) {
        return <pre>{JSON.stringify({ node })}</pre>;
    }
    const { columns } = node;
    const options = columns.map((item) => ({
        label: item.name,
        value: item.column,
    }));
    const current = options.filter((item) => item.value === value)[0];
    return (
        <InputGroup>
            <InputLabel htmlFor="column-selection">{t('content:create.column.title')}</InputLabel>
            <Select
                id="column-selection"
                value={current}
                options={options}
                onChange={handleChangeFn}
            />
        </InputGroup>
    );
}

export default ColumnSelection;
