import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';
import { ContainerHeader, ContainerHeadline, ContainerHeaderOptions } from 'components/Containers';

import {
    fetchContent,
    updateContent,
    updateContentCrops,
    createContentCrops,
    deleteContentCrops,
} from 'store/contents/actions';
import { contentSelecor } from 'store/contents/selectors';
import { Loading } from 'components/general/LoadinInfo';
import ContentEditor from './components/ContentEditor';

function ContentEditComponent({ page, match, location, history }) {
    const {
        params: { contentID },
    } = match;
    const { search } = location;
    const params = new URLSearchParams(search);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isFetching } = useSelector(contentSelecor(contentID));
    const [resolved, setResolved] = useState(false);
    const [isBlocking, setBlocking] = useState(false);
    const [state, setState] = useState({});
    const [items, setItems] = useState({});
    useEffect(() => {
        dispatch(fetchContent(contentID || ''))
            .then((res) => {
                const {
                    payload: {
                        content: { crops, ...next },
                    },
                } = res;
                setState(next);
                setItems({
                    images: crops.map((a) => ({
                        ...a,
                        create: false,
                        delete: false,
                    })),
                });
                return res;
            })
            .then((res) => setResolved(true))
            .catch((res) => setResolved(true));
    }, [dispatch, contentID]);

    const handleContentChange = (data) => {
        const currentState = JSON.stringify(state);
        const next = {
            ...state,
            ...data,
        };
        if (!isBlocking) {
            setBlocking(currentState !== JSON.stringify(next));
        }
        setState(next);
    };

    const handleBack = (e) => {
        const inPortal = params.get('portal');
        const inPage = params.get('page');
        history.push(`/pages/${inPage}${inPortal ? `?portal=${inPortal}` : ''}`);
    };

    const handleSave = () => {
        const {
            id,
            after = '',
            position,
            column,
            page,
            type,
            created_at,
            updated_at,
            status,
            ...rest
        } = state;
        const values = [];
        for (const key in rest) {
            if (Object.prototype.hasOwnProperty.call(rest, key)) {
                values.push({
                    field: key,
                    value: rest[key],
                });
            }
        }
        const update = { after, column, page, type, values };
        const { images = [] } = items;
        const deleteImages = images.filter((item) => item.delete && !item.create);
        const createImages = images.filter((item) => item.create && !item.delete);
        const updateImages = images.filter((item) => !item.create && !item.delete && item.update);
        dispatch(updateContent(id, update))
            .then(createContentCrops(id, createImages))
            .then(updateContentCrops(id, updateImages))
            .then(deleteContentCrops(id, deleteImages))
            .then(dispatch(fetchContent(id)))
            .then((res) => {
                console.info(res);
                setBlocking(false);
                return;
            })
            .catch((err) => {
                console.info(err);
            });
    };

    const handleItemAdd = (type, data) => {
        const result = Object.assign({ images: [] }, items);
        if (type === 'image') {
            for (const element of data) {
                result.images.push({
                    ...element,
                    create: true,
                    update: false,
                    delete: false,
                });
            }
        }
        setItems(result);
    };
    const handleItemChange = (type, id, data) => {
        const result = Object.assign({ images: [] }, items);
        if (type === 'image') {
            const fileIndex = result.images.findIndex((item) => item.id === id);
            result.images[fileIndex] = Object.assign({}, result.images[fileIndex], data, {
                update: true,
            });
        }
        setItems(result);
    };
    const handleItemDelete = (type, id) => {
        const result = Object.assign({ images: [] }, items);
        if (type === 'image') {
            const fileIndex = result.images.findIndex((item) => item.id === id);
            result.images[fileIndex] = Object.assign({}, result.images[fileIndex], {
                delete: true,
            });
        }
        setItems(result);
    };

    if (!resolved || isFetching) {
        return <Loading />;
    }

    return (
        <React.Fragment>
            <Prompt when={isBlocking} message={() => 'Sie haben nicht gespeicherte Änderungen!'} />
            {page && (
                <ContainerHeader>
                    <ContainerHeadline>
                        {t('content:edit.title', { title: page.name })}
                    </ContainerHeadline>
                    <ContainerHeaderOptions>
                        <button
                            onClick={handleBack}
                            title={t('content:create.back.button')}
                            className="options button button--link"
                        >
                            <FontAwesomeIcon icon={faArrowLeft} className="options__icon" />
                            <span className="options__text">{t('content:create.save.button')}</span>
                        </button>
                        <button
                            onClick={handleSave}
                            title={t('content:create.save.button')}
                            className="options button button--link"
                        >
                            <FontAwesomeIcon icon={faSave} className="options__icon" />
                            <span className="options__text">{t('content:create.save.button')}</span>
                        </button>
                    </ContainerHeaderOptions>
                </ContainerHeader>
            )}
            <ContentEditor
                page={page}
                content={state}
                items={items}
                onChange={handleContentChange}
                onItemAdd={handleItemAdd}
                onItemChange={handleItemChange}
                onItemDelete={handleItemDelete}
            />
        </React.Fragment>
    );
}

export default ContentEditComponent;
