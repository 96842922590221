import styled from '@emotion/styled';

export const OptionPanel = styled.div`
    padding: 0.75rem 1rem;
    & + & {
        border-top: 1px solid #ccc;
        margin-top: -1px;
    }
`;

export const OptionPanelHeader = styled.div`
    border-bottom: 1px solid #ccc;
    margin-bottom: 1rem;
`;

export const OptionPanelHeadline = styled.h4`
    margin-bottom: 0.25rem;
`;

export const OptionPanelContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const OptionPanelCell = styled.div`
    width: 1%;
    flex: 1 1 auto;
    & + & {
        margin-left: 1rem;
    }
`;

export const OptionPanelRow = styled.div`
    display: flex;
    flex-direction: row;
    & + & {
        margin-top: 1rem;
    }
`;
export const OptionPanelFooter = styled.div``;
