import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { viewerSelector } from 'store/viewer/selectors';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { isFetching, viewer } = useSelector(viewerSelector);
    const routeRenderer = (props) => {
        if (isFetching) {
            return null;
        }
        if (viewer) {
            return <Component {...props} />;
        }
        const { location } = props;
        const target = {
            pathname: '/auth/login',
            state: { from: location },
        };
        return <Redirect to={target} />;
    };
    return <Route {...rest} render={routeRenderer} />;
};

export default PrivateRoute;
