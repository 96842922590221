import classnames from 'classnames';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function ControlButton({ type, label: tLabel, prefix = 'button', className = '', ...props }) {
    const { icon, onClick: handleClick } = props;
    const { t } = useTranslation();
    const label = t(tLabel);
    return (
        <button
            type={type}
            onClick={handleClick}
            title={label}
            className={classnames(prefix, className)}
        >
            {icon && <FontAwesomeIcon className={`${prefix}__icon`} icon={icon} />}

            <span className={`${prefix}__text`}>{label}</span>
        </button>
    );
}

export default ControlButton;
