import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import {
    ContainerHeader,
    ContainerHeadline,
    ContainerComponent,
    ContainerBody,
    ContainerFooter,
    ContainerFooterSpacer,
} from 'components/Containers';
import ControlButton from './ControlButton';
import { ModalWrapper } from './Modal';
function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
        return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function guidGenerator() {
    const S4 = function() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
}

const fileStats = (url: string): Promise<FileData[]> => {
    return fetch(url).then(checkStatus);
};

function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response.json();
    }
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
}

const uploadFile = (url: string, body: FormData): Promise<FileData> => {
    return fetch(url, { method: 'POST', body }).then(checkStatus);
};

const fileOptions = [
    {
        label: 'Vorhandene Datei verwenden',
        value: 'useExisting',
    },
    /* {
  label: 'Überspringen',
  value: 'cancel',
}, {
  label: 'Umbenennen',
  value: 'rename',
  }, */ {
        label: 'Überschreiben',
        value: 'replace',
    },
];

type OverwriteSelectionProps = {
    items: FileMaping[];
    onChange: (x: FileMaping[]) => void;
};

function OverwriteItem({ item, onChange }) {
    const handleItemChange = (event) => {
        onChange({ ...item, action: event });
    };
    return (
        <tr>
            <td>
                <img
                    src={`/api/files/display?file=${item.info.id}&type=preview-big&display=fill`}
                />
            </td>
            <td>
                {item.info.name} ({formatBytes(item.info.size)})<br />
                {item.info.lastModified}
            </td>
            <td>
                {item.file.name} ({formatBytes(item.file.size)})<br />
                {'undefined'}
            </td>
            <td>
                <Select options={fileOptions} onChange={handleItemChange} value={item.action} />
            </td>
        </tr>
    );
}

function OverwriteSelection({ items, onChange: handleChangeFn }: OverwriteSelectionProps) {
    const handleChange = (item) => {
        const next = [...items];
        const index = next.findIndex((node) => node.id === item.id);
        next[index] = item;
        handleChangeFn(next);
    };
    return (
        <table className="table table--file-update">
            <thead>
                <tr>
                    <th />
                    <th>Original file</th>
                    <th>Uploaded file</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {items.map((item) => (
                    <OverwriteItem key={item.id} item={item} onChange={handleChange} />
                ))}
            </tbody>
        </table>
    );
}

// const handleFileActions = async (actions) => {
//     const active: Array<Promise<File>> = [];
//     for (const cur of actions) {
//         if (cur.action.value === 'useExisting') {
//             const [{ id, lastModified, ...data }] = await fileStats(
//                 `/api/files/exists?filename=${cur.uploading.name}`
//             );
//             active.push(
//                 Promise.resolve({
//                     ...data,
//                     value: id,
//                     lastModified: moment(lastModified.lastModified),
//                 })
//             );
//         } else if (cur.action.value === 'replace') {

//             active.push(
//                 Promise.resolve({
//                     ...data,
//                     value: id,
//                     lastModified: moment(lastModified.lastModified),
//                 })
//             );
//         }
//     }
//     return Promise.all(active);
// };

type FileData = {
    id: string;
    etag: string;
    size: number;
    name: string;
    path: string;
    lastModified: string;
};

type FileAction = {
    label: string;
    value: string;
};

type FileMaping = {
    id: string;
    action: FileAction | undefined;
    existed: boolean;
    info: FileData | undefined;
    file: File;
};

async function executeUpload(filename: string, prefix: string, file: File): Promise<FileData> {
    const form = new FormData();
    form.append('filename', filename);
    form.append('prefix', prefix);
    form.append('file', file);
    return await uploadFile('/api/files/upload', form);
}

const mapFileInfo = (prefix: string) => async (files: FileMaping[]): Promise<FileMaping[]> => {
    const next = files.map(async (item) => {
        const { existed, info: current, ...rest } = item;
        if (existed === false) {
            const cur = await executeUpload(item.file.name, prefix, item.file);
            return { existed, info: cur, ...rest };
        }
        return { existed, info: current, ...rest };
    });
    return Promise.all(next);
};

function ControlUpload({
    label: tLabel,
    icon,
    prefix = '',
    className = '',
    multiple = false,
    onUpload: handleUploadFn,
}) {
    const { t } = useTranslation();
    const inputEl = useRef(null);
    const label = t(tLabel);
    const [uploading, setUploading] = useState(false);
    const [question, setQuestions] = useState<FileMaping[]>([]);
    const handleUpload = ({ target: { files } }) => {
        const fileList = [];
        for (const element of files) {
            fileList.push(element);
        }
        setUploading(true);
        Promise.all(
            fileList.map(
                async (file: File): Promise<FileMaping> => {
                    const fileID = guidGenerator();
                    const current = await fileStats(
                        `/api/files/exists?filename=${prefix}${file.name}`
                    );
                    return {
                        id: fileID,
                        info: current.shift(),
                        file,
                        action: fileOptions[0],
                        existed: current.length !== 0,
                    };
                }
            )
        )
            .then(mapFileInfo(prefix))
            .then((res) => {
                const existed = res.filter((item) => item.existed);
                const notExisted = res.filter((item) => !item.existed);
                setQuestions(existed);
                handleUploadFn(null, notExisted.map(({ info }) => info));
                setUploading(false);
            })
            .catch((err) => {
                console.info(err);
                setUploading(false);
            });
    };
    return (
        <React.Fragment>
            <label className={classnames('button button--upload', className)}>
                <FontAwesomeIcon className="button__icon" icon={icon} />
                <span className="button__text">{label}</span>
                {uploading && (
                    <FontAwesomeIcon className="button__icon" icon={faSpinner} spin={true} />
                )}
                <input
                    className="button__upload"
                    type="file"
                    ref={inputEl}
                    onChange={handleUpload}
                    autoComplete="off"
                    multiple={multiple}
                />
            </label>
            <ModalWrapper isOpen={question.length > 0}>
                {(close) => {
                    const handleQuestionUpdate = (updatedQuestions: FileMaping[]) => {
                        setQuestions(updatedQuestions);
                    };
                    const handleSelect = (e) => {
                        Promise.all(
                            question.map(
                                async (cur: FileMaping): Promise<FileData | undefined> => {
                                    if (!cur) {
                                        return;
                                    }
                                    if (cur.action.value === 'useExisting') {
                                        return cur.info;
                                    } else if (cur.action.value === 'replace') {
                                        return await executeUpload(cur.file.name, prefix, cur.file);
                                    }
                                }
                            )
                        )
                            .then((res) => {
                                handleUploadFn(null, res);
                                setQuestions([]);
                            })
                            .catch((err) => {
                                handleUploadFn(err, null);
                            });
                    };
                    return (
                        <ContainerComponent>
                            <ContainerHeader>
                                <ContainerHeadline>{t('content:file.overwrite')}</ContainerHeadline>
                            </ContainerHeader>
                            <ContainerBody>
                                <OverwriteSelection
                                    items={question}
                                    onChange={handleQuestionUpdate}
                                />
                            </ContainerBody>
                            <ContainerFooter>
                                <ContainerFooterSpacer />
                                <ControlButton
                                    type="button"
                                    label="Übernehmen"
                                    onClick={handleSelect}
                                />
                            </ContainerFooter>
                        </ContainerComponent>
                    );
                }}
            </ModalWrapper>
        </React.Fragment>
    );
}

export default ControlUpload;
