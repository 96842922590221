import { combineReducers } from 'redux';
import { PortalActionTypes } from 'store/actions';
import {
    PortalListAction,
    PortalPaginationMap,
    PortalItems,
    PortalAction,
    PortalPages,
    PortalPagesActionPayload,
    PortalPagesAction,
} from './types';

export const initialPaginationMapState = Object.freeze<PortalPaginationMap>({});
export const initialPortalItems = Object.freeze<PortalItems>({});
export const initialPortalPages = Object.freeze<PortalPages>({});

const pages = (
    state: PortalPaginationMap = initialPaginationMapState,
    action: PortalListAction
) => {
    const { type, payload } = action;
    switch (type) {
        case PortalActionTypes.PORTAL_LIST_REQUEST: {
            const currentPage = Object.assign({}, state[payload.page]);
            const updatedPage = Object.assign({}, currentPage, {
                isFetching: payload.isFetching,
                error: null,
            });
            return Object.assign({}, state, {
                [payload.page]: updatedPage,
            });
        }
        case PortalActionTypes.PORTAL_LIST_FAILURE: {
            const currentPage = Object.assign({}, state[payload.page]);
            const updatedPage = Object.assign({}, currentPage, {
                isFetching: payload.isFetching,
                error: payload.error,
            });
            return Object.assign({}, state, {
                [payload.page]: updatedPage,
            });
        }
        case PortalActionTypes.PORTAL_LIST_SUCCESS: {
            const currentPage = Object.assign({}, state[payload.page]);
            const updatedPage = Object.assign({}, currentPage, {
                isFetching: payload.isFetching,
                error: null,
                ids: (payload.portals || []).map((portal) => portal.id),
            });
            return Object.assign({}, state, {
                [payload.page]: updatedPage,
            });
        }
        default:
            return state;
    }
};

const portalPages = (state: PortalPages = initialPortalPages, action: PortalPagesAction) => {
    const { type, payload } = action;
    switch (type) {
        case PortalActionTypes.PORTAL_PAGES_REQUEST: {
            const currentPages = Object.assign({}, state[payload.page]);
            const updatePages = Object.assign({}, currentPages, {
                isFetching: true,
                error: null,
            });
            return Object.assign({}, state, { [payload.page]: updatePages });
        }
        case PortalActionTypes.PORTAL_PAGES_SUCCESS: {
            const currentPages = Object.assign({}, state[payload.page]);
            const updatePages = Object.assign({}, currentPages, {
                isFetching: false,
                error: null,
                ids: (payload.pages || []).map((node) => node.id),
            });
            return Object.assign({}, state, { [payload.page]: updatePages });
        }
        case PortalActionTypes.PORTAL_PAGES_FAILURE: {
            const currentPages = Object.assign({}, state[payload.page]);
            const updatePages = Object.assign({}, currentPages, {
                isFetching: false,
                error: payload.error,
            });
            return Object.assign({}, state, { [payload.page]: updatePages });
        }
        default:
            return state;
    }
};

const portalItems = (
    state: PortalItems = initialPortalItems,
    action: PortalListAction | PortalAction
) => {
    const { type, payload } = action;
    switch (type) {
        case PortalActionTypes.PORTAL_LIST_SUCCESS: {
            let _portals = {};
            for (const portal of payload.portals || []) {
                _portals = Object.assign({}, _portals, {
                    [portal.id]: {
                        isFetching: false,
                        node: portal,
                    },
                });
            }
            return Object.assign({}, state, _portals);
        }
        case PortalActionTypes.PORTAL_GET_SUCCESS: {
            return Object.assign({}, state, {
                [payload.id]: {
                    isFetching: false,
                    node: payload.portal,
                },
            });
        }
        default:
            return state;
    }
};

const currentPage = (currentPage = 1, action: PortalListAction) => {
    switch (action.type) {
        case PortalActionTypes.PORTAL_LIST_REQUEST:
            return action.payload.page;
    }
    return currentPage;
};

const pagination = combineReducers({
    pages,
    currentPage,
});

export default combineReducers({
    portalPages,
    portals: portalItems,
    pagination,
});
