import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Content } from 'types';
import {
    OptionPanel,
    OptionPanelCell,
    OptionPanelContent,
    OptionPanelHeader,
    OptionPanelRow,
    OptionPanelHeadline,
} from '../../../components/general/Panel';
import Input, { InputGroup, InputLabel } from '../../../components/inputs/InputComponent';
import LinkInput from './LinkInput';

const headerLayouts = [
    {
        label: 'Default',
        value: 'default',
    },
    {
        label: 'H1',
        value: 'h1',
    },
    {
        label: 'H2',
        value: 'h2',
    },
    {
        label: 'H3',
        value: 'h3',
    },
    {
        label: 'H4',
        value: 'h4',
    },
    {
        label: 'H5',
        value: 'h5',
    },
    {
        label: 'Hidden',
        value: 'hidden',
    },
];

const headerPositions = [
    {
        label: 'Default',
        value: 'default',
    },
    {
        label: 'Center',
        value: 'center',
    },
    {
        label: 'Right',
        value: 'right',
    },
    {
        label: 'Left',
        value: 'left',
    },
];

type ContentHeaderProps = {
    content: Content;
    onChange: (x: Content) => void;
};

function ContentHeader({ content, onChange: handleChangeFn }: ContentHeaderProps) {
    const { t } = useTranslation();
    const [header, setHeader] = useState(content.header || '');
    const [date, setDate] = useState(content.date || '');
    const [link, setLink] = useState(content.header_link || '');
    const [subheadline, setSubheadline] = useState(content.subheadline || '');

    const headerPos = headerPositions.find((item) => item.value === content.header_position);
    const headerLay = headerLayouts.find((item) => item.value === content.header_layout);

    const [headerLayout, setHeaderLayout] = useState(headerLay || headerLayouts[0]);
    const [headerPosition, setHeaderPosition] = useState(headerPos || headerPositions[0]);

    const handleHeaderChange = (value: string) => {
        setHeader(value);
        handleChangeFn({
            ...content,
            header: value,
        });
    };

    const handleDateChange = (value: string) => {
        setDate(value);
        handleChangeFn({
            ...content,
            date: value,
        });
    };

    const handleLinkChange = (value: string) => {
        setLink(value);
        handleChangeFn({
            ...content,
            header_link: value,
        });
    };

    const handleSubheadlineChange = (value: string) => {
        setSubheadline(value);
        handleChangeFn({
            ...content,
            subheadline: value,
        });
    };

    const handleTypeSelect = (cur) => {
        setHeaderLayout(cur);
        handleChangeFn({
            ...content,
            header_layout: cur.value,
        });
    };

    const handleHeaderPositionChange = (cur) => {
        setHeaderPosition(cur);
        handleChangeFn({
            ...content,
            header_position: cur.value,
        });
    };

    return (
        <OptionPanel>
            <OptionPanelHeader>
                <OptionPanelHeadline>{t('content:create.headlines.title')}</OptionPanelHeadline>
            </OptionPanelHeader>
            <OptionPanelContent>
                <OptionPanelRow>
                    <OptionPanelCell>
                        <InputGroup>
                            <InputLabel>{t('content:create.headlines.headline.title')}</InputLabel>
                            <Input
                                id="content_header"
                                name="content_header"
                                type="text"
                                required={true}
                                value={header}
                                onChange={handleHeaderChange}
                            />
                        </InputGroup>
                    </OptionPanelCell>
                </OptionPanelRow>
                <OptionPanelRow>
                    <OptionPanelCell>
                        <InputGroup>
                            <InputLabel>{t('content:create.headlines.headline.type')}</InputLabel>
                            <Select
                                options={headerLayouts}
                                value={headerLayout}
                                onChange={handleTypeSelect}
                            />
                        </InputGroup>
                    </OptionPanelCell>
                    <OptionPanelCell>
                        <InputGroup>
                            <InputLabel>
                                {t('content:create.headlines.headline.alignment')}
                            </InputLabel>
                            <Select
                                options={headerPositions}
                                value={headerPosition}
                                onChange={handleHeaderPositionChange}
                            />
                        </InputGroup>
                    </OptionPanelCell>
                    <OptionPanelCell>
                        <InputGroup>
                            <InputLabel>{t('content:create.headlines.headline.date')}</InputLabel>
                            <Input
                                id="content_date"
                                name="content_date"
                                type="text"
                                required={true}
                                value={date}
                                onChange={handleDateChange}
                            />
                        </InputGroup>
                    </OptionPanelCell>
                </OptionPanelRow>
                <OptionPanelRow>
                    <OptionPanelCell>
                        <InputGroup>
                            <InputLabel>{t('content:create.headlines.headline.link')}</InputLabel>
                            <LinkInput
                                id="content_link"
                                name="content_link"
                                type="text"
                                required={true}
                                value={link}
                                onChange={handleLinkChange}
                            />
                        </InputGroup>
                    </OptionPanelCell>
                </OptionPanelRow>
                <OptionPanelRow>
                    <OptionPanelCell>
                        <InputGroup>
                            <InputLabel>
                                {t('content:create.headlines.subheadline.title')}
                            </InputLabel>
                            <Input
                                id="content_subheadline"
                                name="content_subheadline"
                                type="text"
                                required={true}
                                value={subheadline}
                                onChange={handleSubheadlineChange}
                            />
                        </InputGroup>
                    </OptionPanelCell>
                </OptionPanelRow>
            </OptionPanelContent>
        </OptionPanel>
    );
}

export default ContentHeader;
