import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    OptionPanel,
    OptionPanelCell,
    OptionPanelContent,
    OptionPanelHeader,
    OptionPanelRow,
    OptionPanelHeadline,
} from '../../../components/general/Panel';
import ColumnSelection from './ColumnSelection';
import ContentTypeSelection from './ContentTypeSelection';

function ContentPageOptions({ page, column, type, onChange: handleChangeFn }) {
    const { t } = useTranslation();
    const [template, setTemplate] = useState(page.template);
    const [contentType, setContentType] = useState(type);
    const [contentColumn, setContentColumn] = useState(column);
    const handleTypeChange = (event) => {
        handleChangeFn(event.value, contentColumn);
        setContentType(event.value);
    };

    const handleColumnChange = (event) => {
        handleChangeFn(contentType, event.value);
        setContentColumn(event.value);
    };

    useEffect(() => {
        setContentColumn(column);
        setContentType(type);
        setTemplate(page.template);
    }, [column, type, page.template]);

    return (
        <OptionPanel>
            <OptionPanelHeader>
                <OptionPanelHeadline>{t('content:create.element.title')}</OptionPanelHeadline>
            </OptionPanelHeader>
            <OptionPanelContent>
                <OptionPanelRow>
                    <OptionPanelCell>
                        <ContentTypeSelection
                            value={contentType}
                            onChange={handleTypeChange}
                            isSelect={true}
                        />
                    </OptionPanelCell>
                    <OptionPanelCell>
                        <ColumnSelection
                            template={template}
                            value={contentColumn}
                            onChange={handleColumnChange}
                        />
                    </OptionPanelCell>
                </OptionPanelRow>
            </OptionPanelContent>
        </OptionPanel>
    );
}

export default ContentPageOptions;
