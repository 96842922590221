import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCrop, { Crop } from 'react-image-crop';
import Select from 'react-select';

export function CropContainer({ label: tlabel, src, crop: init, onChange: handleChange }) {
    const { t } = useTranslation();

    const formats = [
        {
            value: '16-9',
            label: t('images:format.16-9'),
            crop: {
                aspect: 16 / 9,
            },
        },
        {
            value: '9-16',
            label: t('images:format.9-16'),
            crop: {
                aspect: 9 / 16,
            },
        },
        {
            value: '4-3',
            label: t('images:format.4-3'),
            crop: {
                aspect: 4 / 3,
            },
        },
        {
            value: '3-4',
            label: t('images:format.3-4'),
            crop: {
                aspect: 3 / 4,
            },
        },
        {
            value: '1-1',
            label: t('images:format.1-1'),
            crop: {
                aspect: 1,
            },
        },
        {
            value: 'free',
            label: t('images:format.free'),
            crop: {
                aspect: null,
            },
        },
    ];

    const [crop, setCrop] = useState({
        aspect: null,
        unit: '%',
        width: 100,
        height: 100,
        x: 0,
        y: 0,
    });
    useEffect(() => {
        const { aspect = null, width = 100, height = 100, x = 0, y = 0 } = init;
        setCrop({ aspect: aspect, unit: '%', x, y, width, height });
    }, [init]);
    const handleCrop = (e) => setCrop(e);
    const handleCropResult = (a: Crop, event: Crop) => handleChange(event);
    const handleFormatChange = ({ crop: change }) => {
        const { aspect = null } = change;
        setCrop(Object.assign({}, crop, { aspect }));
    };
    return (
        <div className="image-crop">
            <div className="image-crop__header">
                {tlabel}
                <Select options={formats} onChange={handleFormatChange} />
            </div>
            <div className="image-crop__container">
                <ReactCrop
                    src={src}
                    crop={crop}
                    onChange={handleCrop}
                    onComplete={handleCropResult}
                />
            </div>
        </div>
    );
}
