import React, { useState, useEffect } from 'react';
import { faCogs } from '@fortawesome/pro-solid-svg-icons';
import { Route, Switch, RouteComponentProps, Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { portalSelector } from 'store/portals/selectors';
import PortalSidebarComponent from 'views/portals/components/PortalSidebarComponent';
import { fetchPage } from 'store/pages/actions';
import {
    ContainerComponent,
    ContainerHeader,
    ContainerHeadline,
    ContainerHeaderOptions,
} from 'components/Containers';
import { stripTrailingSlash } from 'utils/path';
import { pageSelector } from 'store/pages/selectors';
import { Loading } from 'components/general/LoadinInfo';
import { fetchPortal, fetchPortalPages } from 'store/portals/actions';
import PageSettingsComponent from './PageSettingsComponent';
import PageContentComponent from './PageContentComponent';

function PageEditComponent({ match, location: { search } }: RouteComponentProps) {
    const {
        params: { pageID },
    } = match;
    const params = new URLSearchParams(search);
    const portalID = params.get('portal');
    const { node: portal } = useSelector(portalSelector(portalID || ''));
    const { isFetching, node: page } = useSelector(pageSelector(pageID || ''));
    const [resolved, setResolved] = useState(false);
    const dispatch = useDispatch();
    const settingsTarget = {
        pathname: `${stripTrailingSlash(match.url)}/settings`,
        search,
    };
    const contentTarget = {
        pathname: `${stripTrailingSlash(match.url)}/`,
        search,
    };
    useEffect(() => {
        Promise.all([
            dispatch(fetchPortal(portalID || '')).then((res) =>
                dispatch(fetchPortalPages(portalID || ''))
            ),
            dispatch(fetchPage(pageID)),
        ])
            .then((res) => {
                setResolved(true);
            })
            .catch((res) => setResolved(true));
    }, [dispatch, pageID, portalID]);
    if (!resolved || isFetching) {
        return <Loading />;
    }
    return (
        <React.Fragment>
            {portal && <PortalSidebarComponent portal={portal} match={match} />}
            <div className="page-content">
                <ContainerComponent>
                    <ContainerHeader>
                        <ContainerHeadline>{page.name}</ContainerHeadline>
                        <ContainerHeaderOptions>
                            <Route path={`${match.path}/settings`}>
                                {({ match }) =>
                                    !match && (
                                        <Link
                                            to={settingsTarget}
                                            title={`${page.name} Einstellungen`}
                                            className="options"
                                        >
                                            <FontAwesomeIcon
                                                icon={faCogs}
                                                className="options__icon"
                                            />
                                            <span className="options__text">Einstellungen</span>
                                        </Link>
                                    )
                                }
                            </Route>
                            <Route path={`${match.path}`} exact={true}>
                                {({ match }) =>
                                    !match && (
                                        <Link
                                            to={contentTarget}
                                            title={page.name}
                                            className="options"
                                        >
                                            <FontAwesomeIcon
                                                icon={faArrowLeft}
                                                className="options__icon"
                                            />
                                            <span className="options__text">{page.name}</span>
                                        </Link>
                                    )
                                }
                            </Route>
                        </ContainerHeaderOptions>
                    </ContainerHeader>
                    <Switch>
                        <Route path={`${match.path}`} exact={true}>
                            {(cur) => cur && <PageContentComponent page={page} portal={portal} />}
                        </Route>
                        <Route path={`${match.path}/settings`} exact={true}>
                            {(cur) => cur && <PageSettingsComponent page={page} portal={portal} />}
                        </Route>
                    </Switch>
                </ContainerComponent>
            </div>
        </React.Fragment>
    );
}

export default PageEditComponent;
