import { combineReducers } from 'redux';
import viewer from './viewer/reducer';
import portals from './portals/reducer';
import pages from './pages/reducer';
import templates from './templates/reducer';
import contents from './contents/reducer';
const createRootReducer = () =>
    combineReducers({
        viewer,
        portals,
        pages,
        templates,
        contents,
    });

export default createRootReducer;
