import React from 'react';
import { Content } from 'store/contents/types';
import { htmlEntities } from '../helpers';

type TextDisplayComponentProps = {
    item: Content;
};

function TextDisplayComponent({ item }: TextDisplayComponentProps) {
    return (
        <React.Fragment>
            {item.header && <h1>{item.header}</h1>}
            {item.bodytext && htmlEntities(item.bodytext)}
        </React.Fragment>
    );
}

export default TextDisplayComponent;
