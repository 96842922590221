import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loading } from 'components/general/LoadinInfo';
import { templateSelector } from 'store/templates/selectors';
import { fetchTemplate } from 'store/templates/actions';
import { ContainerBody } from 'components/Containers';
import PageContentColumnComponent from './views/PageContentColumnComponent';

function PageContentComponent({ page, portal }: RouteComponentProps) {
    const dispatch = useDispatch();
    const { isFetching, node: template } = useSelector(templateSelector(page.template));
    const [resolved, setResolved] = useState(false);

    useEffect(() => {
        dispatch(fetchTemplate(page.template))
            .then((res) => setResolved(true))
            .catch((res) => setResolved(true));
    }, [dispatch, page.template]);

    if (!resolved || isFetching) {
        return <Loading />;
    }
    return (
        <ContainerBody className="columns__container">
            {template &&
                template.columns.map((column) => (
                    <PageContentColumnComponent key={column.column} page={page} column={column} />
                ))}
        </ContainerBody>
    );
}

export default PageContentComponent;
