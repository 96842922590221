import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';
import { ContainerHeader, ContainerHeadline, ContainerHeaderOptions } from 'components/Containers';

import { fetchContent, createContentCrops, createContent } from 'store/contents/actions';
import ContentEditor from './components/ContentEditor';

function ContentCreateComponent({ page, match, location, history }) {
    const {
        params: { contentID },
    } = match;
    const { search } = location;
    const params = new URLSearchParams(search);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isBlocking, setBlocking] = useState(false);
    const [state, setState] = useState({
        column: params.get('column') || '',
        type: params.get('type') || '',
        page: page.id,
        after: params.get('after') || '',
    });
    const [items, setItems] = useState({});

    const handleContentChange = (data) => {
        const next = {
            ...state,
            ...data,
        };
        setState(next);
        setBlocking(true);
    };

    const handleBack = (e) => {
        const inPortal = params.get('portal');
        const inPage = params.get('page');
        history.push(`/pages/${inPage}${inPortal ? `?portal=${inPortal}` : ''}`);
    };

    const handleSave = () => {
        const {
            id,
            after = '',
            position,
            column,
            page,
            type,
            created_at,
            updated_at,
            status,
            ...rest
        } = state;
        const values = [];
        for (const key in rest) {
            if (Object.prototype.hasOwnProperty.call(rest, key)) {
                values.push({
                    field: key,
                    value: rest[key],
                });
            }
        }
        const update = { after, column, page, type, values };
        const { images = [] } = items;
        const createImages = images.filter((item) => item.create && !item.delete);
        dispatch(createContent(update))
            .then((res) => res.payload.id)
            .then((id) => createContentCrops(id, createImages).then(() => id))
            .then((res) => {
                setBlocking(false);
                return res;
            })
            .then((res) => dispatch(fetchContent(res)).then(() => res))
            .then((res) => {
                history.push({
                    pathname: `/contents/${res}`,
                    search: params.toString(),
                });
            })
            .catch((err) => {
                console.info(err);
            });
    };

    const handleItemAdd = (type, data) => {
        const result = Object.assign({ images: [] }, items);
        if (type === 'image') {
            for (const element of data) {
                result.images.push({
                    ...element,
                    create: true,
                    update: false,
                    delete: false,
                });
            }
        }
        console.info(result);
        setItems(result);
    };
    const handleItemChange = (type, id, data) => {
        const result = Object.assign({ images: [] }, items);
        if (type === 'image') {
            const fileIndex = result.images.findIndex((item) => item.id === id);
            result.images[fileIndex] = Object.assign({}, result.images[fileIndex], data, {
                update: true,
            });
        }
        setItems(result);
    };
    const handleItemDelete = (type, id) => {
        const result = Object.assign({ images: [] }, items);
        if (type === 'image') {
            const fileIndex = result.images.findIndex((item) => item.id === id);
            result.images[fileIndex] = Object.assign({}, result.images[fileIndex], {
                delete: true,
            });
        }
        setItems(result);
    };

    return (
        <React.Fragment>
            <Prompt when={isBlocking} message={() => 'Sie haben nicht gespeicherte Änderungen!'} />
            {page && (
                <ContainerHeader>
                    <ContainerHeadline>
                        {t('content:create.title', { title: page.name })}
                    </ContainerHeadline>
                    <ContainerHeaderOptions>
                        <button
                            onClick={handleBack}
                            title={t('content:create.back.button')}
                            className="options button button--link"
                        >
                            <FontAwesomeIcon icon={faArrowLeft} className="options__icon" />
                            <span className="options__text">{t('content:create.save.button')}</span>
                        </button>
                        <button
                            onClick={handleSave}
                            title={t('content:create.save.button')}
                            className="options button button--link"
                        >
                            <FontAwesomeIcon icon={faSave} className="options__icon" />
                            <span className="options__text">{t('content:create.save.button')}</span>
                        </button>
                    </ContainerHeaderOptions>
                </ContainerHeader>
            )}
            <ContentEditor
                page={page}
                content={state}
                items={items}
                onChange={handleContentChange}
                onItemAdd={handleItemAdd}
                onItemChange={handleItemChange}
                onItemDelete={handleItemDelete}
            />
        </React.Fragment>
    );
}

export default ContentCreateComponent;
