import { AppState } from 'store/types';
import { PagePaginationPage, PageItem } from './types';

export const pageListSelector = (page: number) => (state: AppState): PagePaginationPage =>
    Object.assign({ isFetching: true, ids: [] }, state.pages.pagination.pages[page]);

export const pageSelector = (page: string) => (state: AppState): PageItem => {
    if (!page) {
        return { isFetching: false, node: null };
    }
    return Object.assign({ isFetching: true }, state.pages.pages[page]);
};

type PageOption = { value: string; label: string };

export const pagesSelector = (page: number) => (state: AppState): PageOption[] => {
    if (!state.pages.pagination) {
        return [];
    }
    if (!state.pages.pagination.pages) {
        return [];
    }
    if (!state.pages.pagination.pages[page]) {
        return [];
    }
    return Object.values(state.pages.pagination.pages[page].ids).map((id) => {
        const { node } = state.pages.pages[id];
        return {
            value: node.id,
            label: node.name,
        };
    });
};
