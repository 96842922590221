import { PageInfo } from 'types/global.types';
import { PageActionTypes } from 'store/actions';
import { PageListAction, Page, PageAction, AsyncPageListAction, AsyncPageAction } from './types';

function requestPageList(page: number): PageListAction {
    return {
        type: PageActionTypes.PAGE_LIST_REQUEST,
        payload: {
            isFetching: true,
            page,
            error: null,
        },
    };
}

function receivePageList(page: number, pages: Page[], pageInfo: PageInfo): PageListAction {
    return {
        type: PageActionTypes.PAGE_LIST_SUCCESS,
        payload: {
            isFetching: false,
            page,
            error: null,
            pages,
        },
        pageInfo,
    };
}

function errorPageList(page: number, message: string): PageListAction {
    return {
        type: PageActionTypes.PAGE_LIST_FAILURE,
        payload: {
            isFetching: false,
            page,
            error: message,
        },
    };
}

export function getPageListInfo(page: number) {
    return (dispatch, getState) => {
        const cur = getState();
        console.info(page, cur.pages.pagination.pages[page]);
        return cur.pages.pagination.pages[page];
    };
}

export function fetchPageList(
    page: number,
    cursor: string,
    first: number = 50
): AsyncPageListAction {
    const query = new URLSearchParams();
    query.append('portal', '');
    query.append('parent', '');
    query.append('cursor', cursor);
    query.append('limit', String(first));
    return async (dispatch) => {
        await dispatch(requestPageList(page));
        const res = await fetch(`/api/pages?${query}`);
        if (res.status >= 200 && res.status <= 300) {
            const { edges, pageInfo } = await res.json();
            return dispatch(receivePageList(page, edges.map(({ node }) => node), pageInfo));
        }
        throw dispatch(errorPageList(page, 'Invalid Query'));
    };
}

function requestPage(id: string): PageAction {
    return {
        type: PageActionTypes.PAGE_GET_REQUEST,
        payload: {
            id,
            isFetching: true,
            error: null,
        },
    };
}

function receivePage(id: string, page: Page): PageAction {
    return {
        type: PageActionTypes.PAGE_GET_SUCCESS,
        payload: {
            id,
            isFetching: false,
            error: null,
            page,
        },
    };
}

function errorPage(id: string, message: string): PageAction {
    return {
        type: PageActionTypes.PAGE_GET_FAILURE,
        payload: {
            id,
            isFetching: false,
            error: message,
        },
    };
}
export function fetchPage(cursor: string): AsyncPageAction {
    return async (dispatch) => {
        await dispatch(requestPage(cursor));
        const res = await fetch(`/api/pages/${cursor}`);
        if (res.status >= 200 && res.status <= 300) {
            const page = await res.json();
            return dispatch(receivePage(cursor, page));
        }
        throw dispatch(errorPage(cursor, 'Invalid Query'));
    };
}

const headers = {
    'Content-Type': 'application/json',
};

export function requestPageUpdate(id: string): PageAction {
    return {
        type: PageActionTypes.PAGE_UPDATE_REQUEST,
        payload: {
            id,
            isFetching: true,
            error: null,
        },
    };
}

export function errorPageUpdate(id: string, message: string): PageAction {
    return {
        type: PageActionTypes.PAGE_UPDATE_FAILURE,
        payload: {
            id,
            isFetching: false,
            error: message,
        },
    };
}

export function updatePageStatus(cursor: string, status: boolean): AsyncPageAction {
    return async (dispatch) => {
        await dispatch(requestPageUpdate(cursor));
        const res = await fetch(`/api/pages/${cursor}`, {
            method: 'POST',
            mode: 'same-origin',
            headers,
            body: JSON.stringify({ status: !status }),
        });
        if (res.status >= 200 && res.status <= 300) {
            const portal = await res.json();
            return dispatch(receivePage(cursor, portal));
        }
        throw dispatch(errorPageUpdate(cursor, 'Invalid Query'));
    };
}

function removePage(id: string): PageAction {
    return {
        type: PageActionTypes.PAGE_DELETE_FAILURE,
        payload: {
            id,
            isFetching: false,
            error: null,
        },
    };
}

export function deletePage(cursor: string): AsyncPageAction {
    return async (dispatch) => {
        await dispatch(requestPageUpdate(cursor));
        const res = await fetch(`/api/pages/${cursor}`, {
            method: 'DELETE',
            mode: 'same-origin',
            headers,
        });
        if (res.status >= 200 && res.status <= 300) {
            return dispatch(removePage(cursor));
        }
        throw dispatch(errorPageUpdate(cursor, 'Invalid Query'));
    };
}

export function createPage(create: Page): AsyncPageAction {
    return async (dispatch) => {
        const res = await fetch('/api/pages', {
            method: 'POST',
            mode: 'same-origin',
            headers,
            body: JSON.stringify(create),
        });
        if (res.status >= 200 && res.status <= 300) {
            const portal = await res.json();
            return dispatch(receivePage(portal.id, portal));
        }
        throw new Error('Invalid Query');
    };
}

export function updatePage(cursor: string, update: Page): AsyncPageAction {
    return async (dispatch) => {
        await dispatch(requestPageUpdate(cursor));
        const res = await fetch(`/api/pages/${cursor}`, {
            method: 'POST',
            mode: 'same-origin',
            headers,
            body: JSON.stringify(update),
        });
        if (res.status >= 200 && res.status <= 300) {
            const page = await res.json();
            return dispatch(receivePage(cursor, page));
        }
        throw dispatch(errorPageUpdate(cursor, 'Invalid Query'));
    };
}
