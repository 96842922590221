import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const LoadingText = styled.span``;

export function Loading() {
    const { t } = useTranslation();
    return <LoadingText>{t('graphql:loading')}</LoadingText>;
}
