import React from 'react';
import { useSelector } from 'react-redux';
import { contentSelecor } from 'store/contents/selectors';
import { Loading } from 'components/general/LoadinInfo';
import TextDisplayComponent from './components/TextDisplayComponent';
import { ContentComponentProps } from './helpers';

function TextComponent(props: ContentComponentProps) {
    const { item } = props;
    return (
        <React.Fragment>
            <div className="content-element__content">
                <TextDisplayComponent item={item} />
            </div>
        </React.Fragment>
    );
}

export default TextComponent;
