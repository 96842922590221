import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { portalSelector } from 'store/portals/selectors';
import { fetchPortal } from 'store/portals/actions';
import { ModalContainer } from '../../components/controls/Modal';
import PageCreateBodyComponent from './PageCreateBodyComponent';
import PageCreateHeaderComponent from './PageCreateHeaderComponent';
import PageCreateComponent from './PageCreateComponent';

function PageCreateModalComponent({ history, location, ...props }) {
    const { search } = location;
    const params = new URLSearchParams(search);
    const portalID = params.get('portal') || '';
    const [isOpen, toggleOpen] = useState(true);
    const { isFetching, node: portal } = useSelector(portalSelector(portalID));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchPortal(portalID));
    }, [dispatch, portalID]);
    const handleCloseRequest = () => {
        toggleOpen(!isOpen);
        history.goBack();
        // if (page) {
        //
        // } else {

        // }
    };

    const handleCreateRequest = (page) => {
        toggleOpen(!isOpen);
        history.push(`/pages/${page.id}${portal ? `?portal=${portal.id}` : ''}`);
    };

    return (
        <ModalContainer isOpen={isOpen} onRequestClose={handleCloseRequest}>
            <PageCreateComponent
                portal={portal}
                className="modal-content"
                onCreate={handleCreateRequest}
            />
        </ModalContainer>
    );
}

export default PageCreateModalComponent;
