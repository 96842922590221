import { faPen, faPlus, faEye, faEyeSlash, faTrash } from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ControlLink from 'components/controls/ControlLink';
import ControlButton from 'components/controls/ControlButton';
import {
    ListHeader,
    ListContainer,
    ListHeadline,
    ListBody,
    ListItem,
    ListItemTitleField,
    ListItemControls,
} from 'components/lists/ListContainer';
import { Loading } from 'components/general/LoadinInfo';

import { portalPageSelector, portalSelector } from 'store/portals/selectors';
import { fetchPortalList, updatePortalStatus, deletePortal } from 'store/portals/actions';
import { Portal } from 'store/portals/types';

const labels = {
    active: 'portals:item.disable',
    inactive: 'portals:item.enable',
};

const icons = {
    active: faEye,
    inactive: faEyeSlash,
};

type PortalFunction = () => void;

type PortalItemProps = {
    id: string;
    children: (
        node: Portal | null,
        toggleStatus: PortalFunction,
        handleDelete: PortalFunction
    ) => JSX.Element | null;
};

function PortalItem({ id, children }: PortalItemProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isFetching, node } = useSelector(portalSelector(id));
    const handleStatus = () => {
        if (!node) {
            return;
        }
        dispatch(updatePortalStatus(id, node.status));
    };
    const handleDelete = () => {
        dispatch(deletePortal(id));
    };
    if (isFetching) {
        return (
            <ListItem>
                <Loading />
            </ListItem>
        );
    }
    if (!node) {
        return (
            <ListItem>
                <ListItemTitleField>{t('graphql:error')}</ListItemTitleField>
            </ListItem>
        );
    }
    return (
        <ListItem>
            <ListItemTitleField>{node.name}</ListItemTitleField>
            {children(node, handleStatus, handleDelete)}
        </ListItem>
    );
}

function PortalsOverviewComponent({ match }: RouteComponentProps) {
    const { t } = useTranslation();
    const [valid, setValid] = useState(true);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(200);
    const [after, setAfter] = useState('');
    const { isFetching, ids } = useSelector(portalPageSelector(page));
    const dispatch = useDispatch();

    useEffect(() => {
        function fetchCurrentPage() {
            dispatch(fetchPortalList(page || 1, after, limit));
            setValid((c) => true);
        }
        fetchCurrentPage();
    }, [after, dispatch, limit, page, valid]);

    return (
        <ListContainer>
            <ListHeader>
                <ListHeadline>{t('portals:overview.header')}</ListHeadline>
            </ListHeader>
            <ListBody className="overview_list list">
                {ids &&
                    ids.map((item) => (
                        <PortalItem key={item} id={item}>
                            {(node, handleStatus, handleDelete) => {
                                if (!node) {
                                    return null;
                                }
                                return (
                                    <ListItemControls className="button-group">
                                        <ControlLink
                                            to={`${match.url}/${node.id}`}
                                            icon={faPen}
                                            label="portals:item.edit"
                                        />
                                        <ControlButton
                                            type="button"
                                            icon={node.status ? icons.active : icons.inactive}
                                            label={node.status ? labels.active : labels.inactive}
                                            onClick={handleStatus}
                                        />
                                        {/* <ControlButton
                                            type="button"
                                            icon={faTrash}
                                            label="portals:item.delete"
                                            onClick={handleDelete}
                                        /> */}
                                    </ListItemControls>
                                );
                            }}
                        </PortalItem>
                    ))}
                {isFetching && (
                    <ListItem>
                        <Loading />
                    </ListItem>
                )}
            </ListBody>
            <footer className="overview__footer">
                <ControlLink
                    className="link-create"
                    to={`${match.url}/create`}
                    label="portals:item.create"
                    prefix="link"
                    icon={faPlus}
                    modal={true}
                />
            </footer>
        </ListContainer>
    );
}

export default PortalsOverviewComponent;
