import classnames from 'classnames';
import React, { useState } from 'react';
import { InputGroup, InputLabel, InputCheckbox } from './InputComponent';

function FormCheckbox({ id, name, label, checked, onChange: handleChangeFn, ...props }) {
    if (!handleChangeFn) {
        handleChangeFn = () => true;
    }
    const {
        type = 'text',
        className = '',
        required = false,
        autoFocus = false,
        autoComplete = 'off',
    } = props;
    return (
        <InputGroup>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <InputCheckbox
                id={id}
                name={name}
                type={type}
                placeholder={label}
                required={required}
                autoFocus={autoFocus}
                autoComplete={autoComplete}
                checked={checked}
                onChange={handleChangeFn}
            />
        </InputGroup>
    );
    // const {
    //     id,
    //     required = false,
    //     autoComplete = 'off',
    //     defaultText = '',
    //     defaultChecked = false,
    // } = props;
    // const [value, setValue] = useState(defaultText);
    // const classNames = classnames('form-control__input', {
    //     'form-control__input--has-value': value.length !== 0,
    // });
    // const updateValue = (event) => {
    //     handleChange(event.target.value);
    //     setValue(event.target.value);
    // };
    // return (
    //     <div className="form-group form-check">
    //         <input
    //             id={id}
    //             className={classNames}
    //             type={type}
    //             name={name}
    //             onChange={updateValue}
    //             required={required}
    //             autoComplete={autoComplete}
    //             defaultChecked={defaultChecked}
    //         />
    //         <label className="form-check-label" htmlFor={id}>
    //             {label}
    //         </label>
    //     </div>
    // );
}

export default FormCheckbox;
