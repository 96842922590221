import { Content } from 'store/contents/types';

export function htmlEntities(str) {
    const cur = String(str)
        .replace(/<\/?[^>]+(>|$)/g, '')
        .replace(/(&nbsp;)/g, ' ');
    if (cur.length < 512) {
        return cur;
    }
    return cur.substr(0, cur.indexOf(' ', 512)) + '...';
}

export type ContentComponentProps = {
    item: Content;
};
