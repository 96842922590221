import { faCog, faGlobe, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { match } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import React from 'react';

import { Page } from 'store/pages/types';
import { Portal } from 'store/portals/types';
import ControlLink from 'components/controls/ControlLink';
import { portalPagesSelector } from 'store/portals/selectors';
import { Loading } from 'components/general/LoadinInfo';

function TreeListItem({ t, current, match, pages }: PortalPageTreeItemProps) {
    return (
        <li className="page-tree____list-item">
            <ControlLink
                prefix="link"
                className="link-edit"
                to={`/pages/${current.id}`}
                search={`portal=${current.portal}`}
                label={current.name}
                translate={false}
            />
            <PortalPageTreeItem t={t} current={current} match={match} pages={pages} />
        </li>
    );
}

type PortalPageTreeItemProps = {
    t: i18next.TFunction;
    current: Page;
    pages: Page[];
    match: any;
};

function PortalPageTreeItem({ t, current, match, pages }: PortalPageTreeItemProps) {
    const currentPages = pages.filter((page) => current.id === page.parent);
    return (
        <ul className="page-tree__list">
            {currentPages &&
                currentPages.map((item) => (
                    <TreeListItem key={item.id} t={t} current={item} match={match} pages={pages} />
                ))}
        </ul>
    );
}

type PortalSidebarComponentProps = {
    match: match<any>;
    portal: Portal;
    prefix?: string;
};

function PortalSidebarComponent({ portal, match, prefix = 'portal' }: PortalSidebarComponentProps) {
    const { t } = useTranslation();
    const { isFetching, pages } = useSelector(portalPagesSelector(portal.id));
    const { root_page: root } = portal;
    if (isFetching) {
        return <Loading />;
    }
    return (
        <div className={`${prefix}-sidebar`}>
            <div className={`${prefix}-sidebar__header`}>
                <h1 className={`${prefix}-sidebar__header-headline`}>{portal.name}</h1>
            </div>
            <div className={`${prefix}-sidebar__controls`}>
                <ControlLink
                    prefix="link"
                    className="link-settings"
                    to={`/portals/${portal.id}/settings`}
                    icon={faCog}
                    label="portal:settings.title"
                />
            </div>
            {root && (
                <div className={`${prefix}-sidebar__content page-tree`}>
                    <div className="page-tree__item page-tree__item--root">
                        <ControlLink
                            prefix="link"
                            className="link-edit"
                            to={`/pages/${root.id}`}
                            search={`portal=${portal.id}`}
                            icon={faGlobe}
                            label={root.name}
                        />
                    </div>
                    <PortalPageTreeItem t={t} current={root} match={match} pages={pages} />
                    <div className="page-tree__item page-tree__item--footer">
                        <ControlLink
                            prefix="link"
                            className="link-create"
                            to="/pages/create"
                            search={`portal=${portal.id}`}
                            icon={faPlus}
                            label="pages:item.create"
                            modal={true}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default PortalSidebarComponent;
