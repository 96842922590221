import { PortalActionTypes } from 'store/actions';
import { PageInfo } from 'store/types';
import { Page } from 'store/pages/types';
import {
    PortalListAction,
    Portal,
    PortalAction,
    AsyncPortalPagesAction,
    PortalPagesAction,
} from './types';

function requestPortalList(page: number): PortalListAction {
    return {
        type: PortalActionTypes.PORTAL_LIST_REQUEST,
        payload: {
            isFetching: true,
            page,
            error: null,
        },
    };
}

function receivePortalList(page: number, portals: Portal[], pageInfo: PageInfo): PortalListAction {
    return {
        type: PortalActionTypes.PORTAL_LIST_SUCCESS,
        payload: {
            isFetching: false,
            page,
            error: null,
            portals,
        },
        pageInfo,
    };
}

function errorPortalList(page: number, message: string): PortalListAction {
    return {
        type: PortalActionTypes.PORTAL_LIST_FAILURE,
        payload: {
            isFetching: false,
            page,
            error: message,
        },
    };
}

type AsyncPortalListAction = (a: any) => Promise<PortalListAction>;

export function fetchPortalList(
    page: number,
    cursor: string,
    first: number = 50
): AsyncPortalListAction {
    const query = new URLSearchParams();
    query.append('cursor', cursor);
    query.append('limit', String(first));
    return async (dispatch) => {
        await dispatch(requestPortalList(page));
        const res = await fetch(`/api/portals?${query}`);
        if (res.status >= 200 && res.status <= 300) {
            const { edges, pageInfo } = await res.json();
            return dispatch(receivePortalList(page, edges.map(({ node }) => node), pageInfo));
        }
        throw dispatch(errorPortalList(page, 'Invalid Query'));
    };
}

function requestPortal(id: string): PortalAction {
    return {
        type: PortalActionTypes.PORTAL_GET_REQUEST,
        payload: {
            id,
            isFetching: true,
            error: null,
        },
    };
}

function receivePortal(id: string, portal: Portal): PortalAction {
    return {
        type: PortalActionTypes.PORTAL_GET_SUCCESS,
        payload: {
            id,
            isFetching: false,
            error: null,
            portal,
        },
    };
}

function removePortal(id: string): PortalAction {
    return {
        type: PortalActionTypes.PORTAL_DELETE_SUCCESS,
        payload: {
            id,
            isFetching: false,
            error: null,
        },
    };
}

function errorPortal(id: string, message: string): PortalAction {
    return {
        type: PortalActionTypes.PORTAL_GET_FAILURE,
        payload: {
            id,
            isFetching: false,
            error: message,
        },
    };
}

type AsyncPortalAction = (a: any) => Promise<PortalAction>;

export function fetchPortal(cursor: string): AsyncPortalAction {
    return async (dispatch) => {
        if (cursor === '') {
            throw dispatch(errorPortal(cursor, 'Invalid Query'));
        }
        await dispatch(requestPortal(cursor));
        const res = await fetch(`/api/portals/${cursor}`);
        if (res.status >= 200 && res.status <= 300) {
            const portal = await res.json();
            return dispatch(receivePortal(cursor, portal));
        }
        throw dispatch(errorPortal(cursor, 'Invalid Query'));
    };
}

const headers = {
    'Content-Type': 'application/json',
};

export function requestPortalUpdate(id: string): PortalAction {
    return {
        type: PortalActionTypes.PORTAL_UPDATE_REQUEST,
        payload: {
            id,
            isFetching: true,
            error: null,
        },
    };
}

export function errorPortalUpdate(id: string, message: string): PortalAction {
    return {
        type: PortalActionTypes.PORTAL_UPDATE_FAILURE,
        payload: {
            id,
            isFetching: false,
            error: message,
        },
    };
}

export function updatePortalStatus(cursor: string, status: boolean): AsyncPortalAction {
    return async (dispatch) => {
        await dispatch(requestPortalUpdate(cursor));
        const res = await fetch(`/api/portals/${cursor}`, {
            method: 'POST',
            mode: 'same-origin',
            headers,
            body: JSON.stringify({ status: !status }),
        });
        if (res.status >= 200 && res.status <= 300) {
            const portal = await res.json();
            return dispatch(receivePortal(cursor, portal));
        }
        throw dispatch(errorPortalUpdate(cursor, 'Invalid Query'));
    };
}

export function updatePortal(cursor: string, update: Portal): AsyncPortalAction {
    return async (dispatch) => {
        await dispatch(requestPortalUpdate(cursor));
        const res = await fetch(`/api/portals/${cursor}`, {
            method: 'POST',
            mode: 'same-origin',
            headers,
            body: JSON.stringify(update),
        });
        if (res.status >= 200 && res.status <= 300) {
            const portal = await res.json();
            return dispatch(receivePortal(cursor, portal));
        }
        throw dispatch(errorPortalUpdate(cursor, 'Invalid Query'));
    };
}

export function deletePortal(cursor: string): AsyncPortalAction {
    return async (dispatch) => {
        await dispatch(requestPortalUpdate(cursor));
        const res = await fetch(`/api/portals/${cursor}`, {
            method: 'DELETE',
            mode: 'same-origin',
            headers,
        });
        if (res.status >= 200 && res.status <= 300) {
            const portal = await res.json();
            return dispatch(removePortal(cursor));
        }
        throw dispatch(errorPortalUpdate(cursor, 'Invalid Query'));
    };
}

export function createPortal(create: Portal): AsyncPortalAction {
    return async (dispatch) => {
        const res = await fetch('/api/portals', {
            method: 'POST',
            mode: 'same-origin',
            headers,
            body: JSON.stringify(create),
        });
        if (res.status >= 200 && res.status <= 300) {
            const portal = await res.json();
            return dispatch(receivePortal(portal.id, portal));
        }
        throw new Error('Invalid Query');
    };
}

function requestPortalPages(page: string): PortalPagesAction {
    return {
        type: PortalActionTypes.PORTAL_PAGES_REQUEST,
        payload: {
            isFetching: true,
            page,
            error: null,
        },
    };
}

function receivePortalPages(page: string, pages: Page[], pageInfo: PageInfo): PortalPagesAction {
    return {
        type: PortalActionTypes.PORTAL_PAGES_SUCCESS,
        payload: {
            isFetching: false,
            page,
            error: null,
            pages,
        },
        pageInfo,
    };
}

function errorPortalPages(page: string, message: string): PortalPagesAction {
    return {
        type: PortalActionTypes.PORTAL_PAGES_FAILURE,
        payload: {
            isFetching: false,
            page,
            error: message,
        },
    };
}

export function fetchPortalPages(cursor: string): AsyncPortalPagesAction {
    return async (dispatch) => {
        if (cursor === '') {
            throw dispatch(errorPortalPages(cursor, 'Invalid Query'));
        }
        await dispatch(requestPortalPages(cursor));
        const res = await fetch(`/api/portals/${cursor}/pages`);
        if (res.status >= 200 && res.status <= 300) {
            const { edges, pageInfo } = await res.json();
            return dispatch(receivePortalPages(cursor, edges.map(({ node }) => node), pageInfo));
        }
        throw dispatch(errorPortalPages(cursor, 'Invalid Query'));
    };
}
