import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import Modal from 'react-modal';
import { configureStore } from '../shared/store';

import App from '../shared/App';
import IntlProvider from '../shared/i18n/IntlProvider';
import createHistory from '../shared/store/history';
const history = createHistory();

const store =
    window.store ||
    configureStore({
        initialState: window.__PRELOADED_STATE__,
    });

const root = document.getElementById('app');
if (!root) {
    throw new Error('Invalid App endpoint');
}
Modal.setAppElement(root);

hydrate(
    <Provider store={store}>
        <Router history={history}>
            <IntlProvider>
                <App />
            </IntlProvider>
        </Router>
    </Provider>,
    root // eslint-disable-line no-undef
);

if (process.env.NODE_ENV === 'development') {
    if (module.hot) {
        module.hot.accept();
    }

    if (!window.store) {
        window.store = store;
    }
}
