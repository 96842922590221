import React from 'react';
import { Link, Route } from 'react-router-dom';

function NavLink({ to, label, ...props }) {
    const { exact: activeOnlyWhenExact, children } = props;
    return (
        <Route path={to} exact={activeOnlyWhenExact}>
            {({ match }) => (
                <li className={match ? 'nav__item active' : 'nav__item'}>
                    <Link className="nav__link" to={to}>
                        {label}
                    </Link>
                    {children}
                </li>
            )}
        </Route>
    );
}

export default NavLink;
