import React from 'react';

interface StateT {
    error: any;
    info: any;
    hasError: boolean;
}
interface PropT {
    children: any;
}

export default class ErrorBoundary extends React.Component<PropT, StateT> {
    state = {
        error: null,
        info: null,
        hasError: false,
    };

    componentDidCatch(error, info) {
        this.setState({
            error,
            info,
            hasError: true,
        });
    }

    render() {
        const { children } = this.props;
        const { hasError } = this.state;
        if (hasError) {
            // You can render any custom fallback UI
            return (
                <div className="error-container">
                    <h3>Oops, something went wrong!</h3>
                    <p>
                        Try to refresh this page or load <a href="/">Home</a>
                    </p>
                </div>
            );
        }
        return children;
    }
}
