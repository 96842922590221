import React from 'react';
import { useSelector } from 'react-redux';
import { Loading } from 'components/general/LoadinInfo';
import { contentSelecor } from 'store/contents/selectors';
import TextDisplayComponent from './components/TextDisplayComponent';
import { ContentComponentProps } from './helpers';

function ImageTextComponent(props: ContentComponentProps) {
    const { item } = props;
    return (
        <React.Fragment>
            <div className="content-element__content">
                <TextDisplayComponent item={item} />
            </div>
            <div className="content-element__content">
                {(item.crops || []).map((crop) => (
                    <img
                        key={crop.id}
                        src={`/api/files/display?file=${crop.value}&type=preview-big`}
                    />
                ))}
            </div>
        </React.Fragment>
    );
}

export default ImageTextComponent;
