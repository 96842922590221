import { ContentActionTypes } from 'store/actions';
import {
    AsyncContentColumnsAction,
    ContentColumnsAction,
    Content,
    ContentAction,
    AsyncContentAction,
} from './types';

function requestContent(id: string): ContentAction {
    return {
        type: ContentActionTypes.CONTENT_GET_REQUEST,
        payload: {
            id,
            isFetching: true,
            error: null,
        },
    };
}

function receiveContent(id: string, content: Content): ContentAction {
    return {
        type: ContentActionTypes.CONTENT_GET_SUCCESS,
        payload: {
            isFetching: false,
            id,
            error: null,
            content,
        },
    };
}

function errorContent(id: string, message: string): ContentAction {
    return {
        type: ContentActionTypes.CONTENT_GET_FAILURE,
        payload: {
            id,
            isFetching: false,
            error: message,
        },
    };
}

export function fetchContent(cursor: string): AsyncContentAction {
    return async (dispatch) => {
        await dispatch(requestContent(cursor));
        const res = await fetch(`/api/contents/${cursor}`);
        if (res.status >= 200 && res.status <= 300) {
            const template = await res.json();
            return dispatch(receiveContent(cursor, template));
        }
        throw dispatch(errorContent(cursor, 'Invalid Query'));
    };
}

function requestContentsColumn(page: string, column: string): ContentColumnsAction {
    return {
        type: ContentActionTypes.CONTENT_COLUMN_REQUEST,
        payload: {
            isFetching: true,
            error: null,
            page: `${page}-${column}`,
        },
    };
}

function receiveContentsColumn(
    page: string,
    column: string,
    contents: Content[]
): ContentColumnsAction {
    return {
        type: ContentActionTypes.CONTENT_COLUMN_SUCCESS,
        payload: {
            isFetching: false,
            page: `${page}-${column}`,
            error: null,
            contents,
        },
    };
}

function errorContentsColumn(page: string, column: string, message: string): ContentColumnsAction {
    return {
        type: ContentActionTypes.CONTENT_COLUMN_FAILURE,
        payload: {
            isFetching: false,
            page: `${page}-${column}`,
            error: message,
        },
    };
}

export function fetchContentsColumn(page: string, column: string): AsyncContentColumnsAction {
    return async (dispatch) => {
        if (page === '' || column === '') {
            throw dispatch(errorContentsColumn(page, column, 'Invalid Query'));
        }
        await dispatch(requestContentsColumn(page, column));
        const res = await fetch(`/api/pages/${page}/contents?column=${column}`);
        if (res.status >= 200 && res.status <= 300) {
            const contents = await res.json();
            return dispatch(receiveContentsColumn(page, column, contents));
        }
        throw dispatch(errorContentsColumn(page, column, 'Invalid Query'));
    };
}

const headers = {
    'Content-Type': 'application/json',
};

function requestContentUpdate(id: string): ContentAction {
    return {
        type: ContentActionTypes.CONTENT_UPDATE_REQUEST,
        payload: {
            id,
            isFetching: true,
            error: null,
        },
    };
}

function receiveContentUpdate(id: string, content: Content): ContentAction {
    return {
        type: ContentActionTypes.CONTENT_UPDATE_SUCCESS,
        payload: {
            isFetching: false,
            id,
            error: null,
            content,
        },
    };
}

function errorContentUpdate(id: string, message: string): ContentAction {
    return {
        type: ContentActionTypes.CONTENT_UPDATE_FAILURE,
        payload: {
            id,
            isFetching: false,
            error: message,
        },
    };
}

export function updateContentPosition(
    cursor: string,
    column: string,
    after: string
): AsyncContentAction {
    return async (dispatch) => {
        await dispatch(requestContentUpdate(cursor));
        const res = await fetch(`/api/contents/${cursor}`, {
            method: 'POST',
            mode: 'same-origin',
            headers,
            body: JSON.stringify({ column, after }),
        });
        if (res.status >= 200 && res.status <= 300) {
            const template = await res.json();
            return dispatch(receiveContent(cursor, template));
        }
        throw dispatch(errorContentUpdate(cursor, 'Invalid Query'));
    };
}

export function updateContentStatus(cursor: string, status: boolean) {
    return async (dispatch) => {
        await dispatch(requestContent(cursor));
        const res = await fetch(`/api/contents/${cursor}`, {
            method: 'POST',
            mode: 'same-origin',
            headers,
            body: JSON.stringify({ status: !status }),
        });
        if (res.status >= 200 && res.status <= 300) {
            const portal = await res.json();
            return dispatch(receiveContent(cursor, portal));
        }
        throw dispatch(errorContent(cursor, 'Invalid Query'));
    };
}

async function createCrops(current) {
    const mapping = {};
    for (const key in current) {
        if (Object.prototype.hasOwnProperty.call(current, key)) {
            const item = current[key];
            mapping[key] = await fetch('/api/crops', {
                method: 'POST',
                mode: 'same-origin',
                headers,
                body: JSON.stringify(item),
            })
                .then((res) => {
                    if (res.status >= 200 && res.status <= 300) {
                        return res.json();
                    }
                    throw new Error('Keine ahnung...');
                })
                .then(({ id }) => id);
        }
    }
    return Promise.all(Object.values(mapping)).then(() => mapping);
}

async function updateCrops(current) {
    const mapping = {};
    for (const key in current) {
        if (Object.prototype.hasOwnProperty.call(current, key)) {
            const item = current[key];
            mapping[key] = await fetch(`/api/crops/${item.id}`, {
                method: 'POST',
                mode: 'same-origin',
                headers,
                body: JSON.stringify(item),
            })
                .then((res) => {
                    if (res.status >= 200 && res.status <= 300) {
                        return res.json();
                    }
                    throw new Error('Keine ahnung...');
                })
                .then(({ id }) => id);
        }
    }
    return Promise.all(Object.values(mapping)).then(() => mapping);
}

export async function createContentCrops(cursor: string, current: any[]) {
    return Promise.all(
        current.map(async (cropItem, i, a) => {
            const { crops, ...data } = cropItem;
            const mapping = await createCrops(crops);
            const res = await fetch(`/api/contents/${cursor}/crops`, {
                method: 'POST',
                mode: 'same-origin',
                headers,
                body: JSON.stringify({ ...data, crops: mapping }),
            });
            if (res.status >= 200 && res.status <= 300) {
                return await res.json();
            }
        })
    );
}

export async function deleteContentCrops(cursor: string, current: any[]) {
    return Promise.all(
        current.map(async (cropItem) => {
            const res = await fetch(`/api/contents/${cursor}/crops/${cropItem.id}`, {
                method: 'DELETE',
                mode: 'same-origin',
                headers,
            });
            if (res.status >= 200 && res.status <= 300) {
                return await res.json();
            }
        })
    );
}

export async function updateContentCrops(cursor: string, current: any[]) {
    return Promise.all(
        current.map(async (cropItem) => {
            const { crops, ...data } = cropItem;
            const mapping = await updateCrops(crops);
            const res = await fetch(`/api/contents/${cursor}/crops/${cropItem.id}`, {
                method: 'POST',
                mode: 'same-origin',
                headers,
                body: JSON.stringify({ ...data, crops: mapping }),
            });
            if (res.status >= 200 && res.status <= 300) {
                return await res.json();
            }
        })
    );
}

export function updateContent(cursor: string, content: Content): AsyncContentAction {
    return async (dispatch) => {
        await dispatch(requestContentUpdate(cursor));
        const res = await fetch(`/api/contents/${cursor}`, {
            method: 'POST',
            mode: 'same-origin',
            headers,
            body: JSON.stringify(content),
        });
        if (res.status >= 200 && res.status <= 300) {
            const template = await res.json();
            return dispatch(receiveContent(cursor, template));
        }
        throw dispatch(errorContentUpdate(cursor, 'Invalid Query'));
    };
}

function removeContent(id: string): ContentAction {
    return {
        type: ContentActionTypes.CONTENT_DELETE_SUCCESS,
        payload: {
            id,
            isFetching: false,
            error: null,
        },
    };
}

export function deleteContent(cursor: string): AsyncContentAction {
    return async (dispatch) => {
        await dispatch(requestContentUpdate(cursor));
        const res = await fetch(`/api/contents/${cursor}`, {
            method: 'DELETE',
            mode: 'same-origin',
            headers,
        });
        if (res.status >= 200 && res.status <= 300) {
            return dispatch(removeContent(cursor));
        }
        throw dispatch(errorContentUpdate(cursor, 'Invalid Query'));
    };
}

export function createContent(create: Content): AsyncContentAction {
    return async (dispatch) => {
        const res = await fetch('/api/contents', {
            method: 'POST',
            mode: 'same-origin',
            headers,
            body: JSON.stringify(create),
        });
        if (res.status >= 200 && res.status <= 300) {
            const content = await res.json();
            return dispatch(receiveContent(content.id, content));
        }
        throw new Error('Invalid Query');
    };
}
