import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { InputGroup, InputLabel } from '../../../components/inputs/InputComponent';
import { contentElementTypes, typeCategories } from '../global/ContentElements';

function renderCategorieButton(item, onClick) {
    return (
        <button
            key={item.value}
            className="button button-link"
            type="button"
            onClick={onClick(item.value)}
        >
            {item.label}
        </button>
    );
}

function FilterCategore({ items, type, children }) {
    return items.filter((item) => item.categories.includes(type)).map((item) => children(item));
}

function ContentTypeSelection({
    value = {},
    onChange: handleChangeFn,
    isSelect = false,
    className = '',
}) {
    const { t } = useTranslation();
    const [show, setShow] = useState(typeCategories[0].value || '');
    const translatedValues = contentElementTypes.map((node) => ({
        ...node,
        label: t(node.label),
    }));

    const selectOptions = typeCategories.map((item) => ({
        ...item,
        label: t(item.label),
        options: translatedValues.filter((node) => node.categories.includes(item.value)),
    }));
    const handleCategorieFilter = (type) => () => {
        setShow(type);
    };
    const handleTypeSelect = (item) => () => {
        handleChangeFn(item);
    };
    if (isSelect) {
        return (
            <InputGroup>
                <InputLabel>{t('content:create.type.title')}</InputLabel>
                <Select
                    value={translatedValues.find((item) => item.value === value)}
                    options={selectOptions}
                    onChange={handleChangeFn}
                />
            </InputGroup>
        );
    }
    return (
        <div className={classNames(className, 'content-type')}>
            <div className="content-type__nav">
                {typeCategories.map((item) => renderCategorieButton(item, handleCategorieFilter))}
            </div>
            <div className="content-type__body">
                <FilterCategore items={contentElementTypes} type={show}>
                    {(item) => {
                        const itemClassNames = classNames('content-type__item', {
                            'content-type__item--active': item.value === value,
                        });
                        const handleItemSelect = handleTypeSelect(item);
                        return (
                            <button
                                key={item.value}
                                type="button"
                                className={itemClassNames}
                                onClick={handleItemSelect}
                            >
                                <div className="content-type__item-image" />
                                <div className="content-type__item-info">
                                    <span>{t(item.label)}</span>
                                </div>
                            </button>
                        );
                    }}
                </FilterCategore>
            </div>
        </div>
    );
}

export default ContentTypeSelection;
