import { combineReducers } from 'redux';
import { TemplateActionTypes } from 'store/actions';
import { TemplateListAction, TemplatePaginationMap, TemplateItems, TemplateAction } from './types';

export const initialPaginationMapState = Object.freeze<TemplatePaginationMap>({});
export const initialTemplateItems = Object.freeze<TemplateItems>({});

const pages = (
    state: TemplatePaginationMap = initialPaginationMapState,
    action: TemplateListAction
) => {
    const { type, payload } = action;
    switch (type) {
        case TemplateActionTypes.LIST_REQUEST: {
            const currentPage = Object.assign({}, state[payload.page]);
            const updatedPage = Object.assign({}, currentPage, {
                isFetching: payload.isFetching,
                error: null,
            });
            return Object.assign({}, state, {
                [payload.page]: updatedPage,
            });
        }
        case TemplateActionTypes.LIST_FAILURE: {
            const currentPage = Object.assign({}, state[payload.page]);
            const updatedPage = Object.assign({}, currentPage, {
                isFetching: payload.isFetching,
                error: payload.error,
            });
            return Object.assign({}, state, {
                [payload.page]: updatedPage,
            });
        }
        case TemplateActionTypes.LIST_SUCCESS: {
            const currentPage = Object.assign({}, state[payload.page]);
            const updatedPage = Object.assign({}, currentPage, {
                isFetching: payload.isFetching,
                error: null,
                ids: (payload.templates || []).map((template) => template.id),
            });
            return Object.assign({}, state, {
                [payload.page]: updatedPage,
            });
        }
        default:
            return state;
    }
};

const templateItems = (
    state: TemplateItems = initialTemplateItems,
    action: TemplateListAction | TemplateAction
) => {
    const { type, payload } = action;
    switch (type) {
        case TemplateActionTypes.LIST_SUCCESS: {
            let _templates = {};
            for (const template of payload.templates || []) {
                _templates = Object.assign({}, _templates, {
                    [template.id]: {
                        isFetching: false,
                        node: template,
                    },
                });
            }
            return Object.assign({}, state, _templates);
        }
        case TemplateActionTypes.GET_TEMPLATE_SUCCESS: {
            return Object.assign({}, state, {
                [payload.id]: {
                    isFetching: false,
                    node: payload.template,
                },
            });
        }
        default:
            return state;
    }
};

const currentPage = (currentPage = 1, action: TemplateListAction) => {
    switch (action.type) {
        case TemplateActionTypes.LIST_REQUEST:
            return action.payload.page;
    }
    return currentPage;
};

const pagination = combineReducers({
    pages,
    currentPage,
});

export default combineReducers({
    templates: templateItems,
    pagination,
});
