import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { loginUser, fetchUser } from 'store/viewer/actions';
import { viewerSelector } from 'store/viewer/selectors';
import { WithRoute } from 'types/global.types';

import LoginFormular from 'components/users/LoginFormular';

function LoginComponent({ location }: WithRoute) {
    const { from } = location.state || { from: { pathname: '/' } };
    const { t } = useTranslation();
    const [user, setUsername] = useState('');
    const { isFetching, viewer } = useSelector(viewerSelector);
    const dispatch = useDispatch();
    const handleLogin = (nextUsername: string, password: string) => {
        if (nextUsername && password) {
            dispatch(loginUser(nextUsername, password)).catch((err) => {
                console.info(err);
            });
            setUsername(nextUsername);
        }
    };
    return (
        <div className="app-content content--signin">
            <div className="form form--signin">
                {!isFetching && viewer && <Redirect to={from} />}
                <LoginFormular
                    labels={{
                        title: t('signin:title'),
                        username: t('signin:username'),
                        password: t('signin:password'),
                        submitAction: t('signin:action'),
                    }}
                    initalUsername={user}
                    onSubmit={handleLogin}
                    method="POST"
                    action="/api/auth/login"
                />
            </div>
        </div>
    );
}

export default LoginComponent;
