import { ViewerActionTypes } from 'store/actions';
import { LoginAction, ViewerState, Viewer } from './types';

export const initialState = Object.freeze<ViewerState>({
    isFetching: false,
    viewer: null,
    error: null,
});

function updateViewer(
    state: ViewerState,
    isFetching: boolean,
    error: string | null,
    viewer?: Viewer | null
) {
    const nextViewer = viewer ? viewer : state.viewer;
    return Object.assign({}, state, {
        isFetching,
        error,
        viewer: viewer === null ? null : nextViewer,
    });
}

export default (state: ViewerState = initialState, action: LoginAction): ViewerState => {
    const { type, payload } = action;

    switch (type) {
        case ViewerActionTypes.LOGIN_REQUEST:
            return updateViewer(state, payload.isFetching, null, null);
        case ViewerActionTypes.LOGIN_FAILURE:
            return updateViewer(state, payload.isFetching, payload.error, null);
        case ViewerActionTypes.LOGIN_SUCCESS:
            return updateViewer(state, payload.isFetching, null, payload.viewer);
        case ViewerActionTypes.LOGOUT_REQUEST:
            return updateViewer(state, payload.isFetching, null);
        case ViewerActionTypes.LOGOUT_FAILURE:
            return updateViewer(state, payload.isFetching, payload.error, null);
        case ViewerActionTypes.LOGOUT_SUCCESS:
            return updateViewer(state, payload.isFetching, null, null);
        case ViewerActionTypes.VIEWER_REQUEST:
            return updateViewer(state, payload.isFetching, null);
        case ViewerActionTypes.VIEWER_FAILURE:
            return updateViewer(state, payload.isFetching, payload.error, null);
        case ViewerActionTypes.VIEWER_SUCCESS:
            return updateViewer(state, payload.isFetching, null, payload.viewer);
        default:
            return state;
    }
};
