import { AppState } from 'store/types';
import { Content, ContentItem, ContentPosElement } from './types';

const posSort = (a, i) => a.position - i.position;

export const pageContentSelector = (page: string, column: string) => (
    state: AppState
): { isFetching: boolean; contents: ContentPosElement[] } => {
    if (page === '' || column === '') {
        return { isFetching: false, contents: [] };
    }
    if (!state.contents.columns[`${page}-${column}`]) {
        return { isFetching: false, contents: [] };
    }
    const current = Object.assign(
        { isFetching: false, ids: [] },
        state.contents.columns[`${page}-${column}`]
    );
    return Object.assign(
        { isFetching: false, contents: [] },
        { isFetching: current.isFetching, contents: current.ids.sort(posSort) || [] }
    );
};

export const contentSelecor = (content: string) => (state: AppState): ContentItem =>
    Object.assign({ isFetching: true }, state.contents.contents[content]);
