import {
    faPen,
    faPlus,
    faEye,
    faEyeSlash,
    faTrash,
    faArrowLeft,
    faArrowRight,
} from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ControlLink from 'components/controls/ControlLink';
import ControlButton from 'components/controls/ControlButton';
import {
    ListHeader,
    ListContainer,
    ListHeadline,
    ListBody,
    ListItem,
    ListItemControls,
    ListControls,
} from 'components/lists/ListContainer';
import { Loading } from 'components/general/LoadinInfo';
import { fetchPageList, getPageListInfo } from 'store/pages/actions';
import { pageListSelector } from 'store/pages/selectors';
import { PageAction } from 'store/pages/types';
import PageItem from './components/PageItem';

const labels = {
    active: 'pages:item.disable',
    inactive: 'pages:item.enable',
};

const icons = {
    active: faEye,
    inactive: faEyeSlash,
};

type PageFunction = () => Promise<PageAction>;

function PagesOverviewComponent({ match, history, location }: RouteComponentProps) {
    const { search } = location;
    const params = new URLSearchParams(search);
    const { t } = useTranslation();
    const [valid, setValid] = useState(true);
    const curPage = Number(params.get('page') || '') || 1;
    const curLimit = Number(params.get('limit') || '') || 50;
    const curAfter = params.get('after') || '';
    const [page, setPage] = useState(curPage);
    const [limit, setLimit] = useState(curLimit);
    const [after, setAfter] = useState(curAfter);
    const { isFetching, ids = [] } = useSelector(pageListSelector(page));
    const dispatch = useDispatch();

    const handlePageChange = (next) => (e) => {
        const res = dispatch(getPageListInfo(next - 1));
        const nextID = res ? res.ids[ids.length - 1] : '';
        dispatch(fetchPageList(next, nextID, limit)).then((nex) => {
            console.info(nex);
            setPage(next);
            setAfter(nextID);
        });
    };

    useEffect(() => {
        function fetchCurrentPage() {
            dispatch(fetchPageList(curPage, curAfter, curLimit));
            setValid((c) => true);
        }
        fetchCurrentPage();
    }, [curAfter, dispatch, limit, curPage, curLimit]);

    const handleItemDelete = (handleDelete: PageFunction) => (e) => {
        handleDelete()
            .then((res) => {
                console.info(res);
                setValid((c) => false);
            })
            .catch((err) => {
                console.info(error);
            });
    };

    return (
        <ListContainer>
            <ListHeader>
                <ListHeadline>{t('pages:overview.header')}</ListHeadline>
                <ListControls>
                    {page > 1 && (
                        <ControlButton
                            type="button"
                            className="button--link"
                            label="pages:overview.prevpage.button"
                            onClick={handlePageChange(page - 1)}
                            icon={faArrowLeft}
                        />
                    )}
                    {ids.length >= Number(limit) - 1 && (
                        <ControlButton
                            type="button"
                            className="button--link"
                            label="pages:overview.nextpage.button"
                            onClick={handlePageChange(page + 1)}
                            icon={faArrowRight}
                        />
                    )}
                </ListControls>
            </ListHeader>
            <ListBody>
                {!isFetching &&
                    ids &&
                    ids.map((item) => (
                        <PageItem key={item} id={item}>
                            {(node, handleStatus, handleDelete) => {
                                if (!node) {
                                    return null;
                                }
                                return (
                                    <ListItemControls className="button-group">
                                        <ControlLink
                                            to={`${match.url}/${node.id}`}
                                            icon={faPen}
                                            label="pages:item.edit"
                                        />
                                        <ControlButton
                                            type="button"
                                            icon={node.status ? icons.active : icons.inactive}
                                            label={node.status ? labels.active : labels.inactive}
                                            onClick={handleStatus}
                                        />
                                        <ControlButton
                                            type="button"
                                            icon={faTrash}
                                            label="pages:item.delete"
                                            onClick={handleItemDelete(handleDelete)}
                                        />
                                    </ListItemControls>
                                );
                            }}
                        </PageItem>
                    ))}
                {isFetching && (
                    <ListItem>
                        <Loading />
                    </ListItem>
                )}
            </ListBody>

            <footer className="overview__footer">
                <ControlLink
                    className="link-create"
                    to={`${match.url}/create`}
                    label="pages:item.create"
                    prefix="link"
                    icon={faPlus}
                    modal={true}
                />
            </footer>
            <footer className="overview__footer">
                <div className="button-group">
                    {page > 1 && (
                        <ControlButton
                            type="button"
                            className="button--link"
                            label="pages:overview.prevpage.button"
                            onClick={handlePageChange(page - 1)}
                            icon={faArrowLeft}
                        />
                    )}
                    {ids.length >= Number(limit) - 1 && (
                        <ControlButton
                            type="button"
                            className="button--link"
                            label="pages:overview.nextpage.button"
                            onClick={handlePageChange(page + 1)}
                            icon={faArrowRight}
                        />
                    )}
                </div>
            </footer>
        </ListContainer>
    );
}

export default PagesOverviewComponent;
