import { faSave } from '@fortawesome/pro-solid-svg-icons';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { updatePage } from 'store/pages/actions';
import { Portal } from 'store/portals/types';

import { ContainerBody } from 'components/Containers';
import {
    OptionPanel,
    OptionPanelHeader,
    OptionPanelContent,
    OptionPanelFooter,
} from 'components/general/Panel';
import FormInputField from 'components/inputs/FormInputField';
import FormTextArea from 'components/inputs/FormTextArea';
import ControlButton from 'components/controls/ControlButton';

import { Page } from 'store/pages/types';
import FormCheckbox from 'components/inputs/FormCheckbox';
import TemplateSelect from './components/TemplateSelect';
import ContentPageSelect from './components/ContentPageSelect';
import PageEditPositionComponent from './components/PageEditPositionComponent';

type PageSettingsComponentProps = {
    page: Page;
    portal: Portal | null;
};

function PageSettingsComponent({ page: current, portal }: PageSettingsComponentProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [page, setPage] = useState({
        name: '',
        url: '',
        kennung: '',
        template: null,
        is_root: false,
        content_page: '',
        status: false,
        ...current,
    });
    useEffect(() => {
        if (current) {
            setPage(current);
        }
    }, [dispatch, current]);

    const handleStatusChange = (value: string) => {
        setPage({
            ...page,
            status: !page.status,
        });
    };
    const handleNameChange = (value: string) => {
        setPage({
            ...page,
            name: value,
        });
    };

    const handleKennungChange = (value: string) => {
        setPage({
            ...page,
            kennung: value,
        });
    };

    const handleUrlChange = (value: string) => {
        setPage({
            ...page,
            url: value,
        });
    };

    const handleDescriptionChange = (value: string) => {
        setPage({
            ...page,
            description: value,
        });
    };
    const handleKeywordsChange = (value: string) => {
        setPage({
            ...page,
            keywords: value,
        });
    };
    const handleTemplateChange = ({ value }) => {
        setPage({
            ...page,
            template: value,
        });
    };

    const handlePositionChange = (parentPage, afterPage) => {
        setPage({
            ...page,
            parent: parentPage,
            after: afterPage,
        });
    };

    const handleContentPageChange = (value) => {
        setPage({
            ...page,
            content_page: value,
        });
    };

    const handleUpdate = (e) => {
        e.preventDefault();
        dispatch(updatePage(current.id, page))
            .then((res) => console.info(res))
            .catch((err) => console.info(err));
        // .then((res) => onCreate(res.payload.page))
    };
    return (
        <ContainerBody>
            <form onSubmit={handleUpdate}>
                <OptionPanel>
                    <OptionPanelHeader>
                        <h1>{t('page:settings.title')}</h1>
                    </OptionPanelHeader>
                    <OptionPanelContent>
                        <FormInputField
                            type="text"
                            name="page_name"
                            id="inputPageSettingName"
                            label={t('page:settings.name')}
                            required={true}
                            autoFocus={true}
                            value={page.name}
                            onChange={handleNameChange}
                        />
                        {!page.is_root && portal && (
                            <FormCheckbox
                                type="text"
                                name="page_status"
                                id="inputPageSettingName"
                                label={t('page:settings.status')}
                                required={true}
                                checked={page.status}
                                onChange={handleStatusChange}
                            />
                        )}
                        {portal && (
                            <FormInputField
                                type="text"
                                name="page_url"
                                id="inputPageSettingUrl"
                                label={t('page:settings.url')}
                                required={true}
                                value={page.url}
                                onChange={handleUrlChange}
                            />
                        )}
                        <FormInputField
                            type="text"
                            name="page_kennung"
                            id="inputPageSettingKennung"
                            label={t('page:settings.kennung')}
                            required={true}
                            value={page.kennung}
                            onChange={handleKennungChange}
                        />
                        <TemplateSelect
                            id="inputPageSettingTemplate"
                            name="template"
                            label={t('page:settings.template')}
                            value={page.template}
                            onChange={handleTemplateChange}
                        />
                        {!page.is_root && portal && (
                            <ContentPageSelect
                                id="inputContentPage"
                                label={t('page:settings.content_page.title')}
                                portal={portal}
                                value={page.content_page}
                                onChange={handleContentPageChange}
                            />
                        )}
                        <FormTextArea
                            id="inputPageSettingDescription"
                            name="description"
                            label={t('page:settings.description')}
                            value={page.description}
                            onChange={handleDescriptionChange}
                        />
                        <FormTextArea
                            id="inputPageSettingKeywords"
                            name="keywords"
                            label={t('page:settings.keywords')}
                            value={page.keywords}
                            onChange={handleKeywordsChange}
                        />
                        {!page.is_root && portal && (
                            <PageEditPositionComponent
                                id="inputPagePosition"
                                label={t('page:settings.position.title')}
                                portal={portal}
                                value={page}
                                onChange={handlePositionChange}
                            />
                        )}
                    </OptionPanelContent>
                    <OptionPanelFooter>
                        <ControlButton
                            type="submit"
                            label="page:settings.save"
                            icon={faSave}
                            className="button-create"
                        />
                    </OptionPanelFooter>
                </OptionPanel>
            </form>
        </ContainerBody>
    );
}

export default PageSettingsComponent;
