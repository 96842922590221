import { faSave } from '@fortawesome/pro-solid-svg-icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { updatePortal } from 'store/portals/actions';
import { Portal } from 'store/portals/types';
import {
    ContainerHeader,
    ContainerHeadline,
    ContainerComponent,
    ContainerBody,
} from 'components/Containers';
import ControlButton from '../../components/controls/ControlButton';
import FormInputField from '../../components/inputs/FormInputField';
import FormTextArea from '../../components/inputs/FormTextArea';

import {
    OptionPanel,
    OptionPanelContent,
    OptionPanelFooter,
    OptionPanelHeader,
} from '../../components/general/Panel';

function PortalSettingsComponent({ portal: node }: { portal: Portal }) {
    const [portal, setPortal] = useState({
        domain: '',
        name: '',
        portal: '',
        description: '',
        keywords: '',
        ...node,
    });

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handlePortalDomainChange = (value: string) => setPortal({ ...portal, domain: value });
    const handlePortalNameChange = (value: string) => setPortal({ ...portal, name: value });
    const handleDescriptionChange = (value: string) => setPortal({ ...portal, description: value });
    const handleKeywordsChange = (value: string) => setPortal({ ...portal, keywords: value });

    const handleUpdate = (e) => {
        e.preventDefault();
        dispatch(updatePortal(node.id, portal));
        // console.info(portal);
    };

    return (
        <ContainerComponent>
            <ContainerHeader>
                <ContainerHeadline>{portal.name}</ContainerHeadline>
            </ContainerHeader>
            <ContainerBody>
                <form className="portal-settings portal-settings--info" onSubmit={handleUpdate}>
                    <OptionPanel>
                        <OptionPanelHeader>
                            <h2>{t('portal:settings.title')}</h2>
                        </OptionPanelHeader>
                        <OptionPanelContent>
                            <FormInputField
                                type="text"
                                name="portal_domain"
                                id="inputPortalSettingName"
                                label={t('portal:settings.domain')}
                                required={true}
                                autoFocus={true}
                                value={portal.domain}
                                onChange={handlePortalDomainChange}
                            />
                            <FormInputField
                                type="text"
                                name="portal_name"
                                id="inputPortalSettingName"
                                label={t('portal:settings.name')}
                                required={true}
                                autoFocus={true}
                                value={portal.name}
                                onChange={handlePortalNameChange}
                            />
                            <FormTextArea
                                id="inputPortalSettingDescription"
                                name="description"
                                label={t('portal:settings.description')}
                                value={portal.description}
                                onChange={handleDescriptionChange}
                            />
                            <FormTextArea
                                id="inputPortalSettingKeywords"
                                name="keywords"
                                label={t('portal:settings.keywords')}
                                value={portal.keywords}
                                onChange={handleKeywordsChange}
                            />
                        </OptionPanelContent>
                        <OptionPanelFooter>
                            <ControlButton
                                type="submit"
                                label="portal:settings.save"
                                icon={faSave}
                                className="button-create"
                            />
                        </OptionPanelFooter>
                    </OptionPanel>
                </form>
            </ContainerBody>
        </ContainerComponent>
    );
}

export default PortalSettingsComponent;
