import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { faPlus, faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { pageListSelector } from 'store/pages/selectors';
import { fetchPageList, getPageListInfo } from 'store/pages/actions';
import { PageAction, Page } from 'store/pages/types';
import {
    ListContainer,
    ListHeader,
    ListHeadline,
    ListBody,
    ListItemControls,
    ListItem,
    ListControls,
} from 'components/lists/ListContainer';
import ControlButton from 'components/controls/ControlButton';
import { Loading } from 'components/general/LoadinInfo';
import PageItem from './PageItem';

type PageFunction = () => Promise<PageAction>;

type PageItemProps = {
    id: string;
    children: (
        node: Page | null,
        toggleStatus: PageFunction,
        handleDelete: PageFunction
    ) => JSX.Element | null;
};

function PageSelectComponent({ onChange: handleChangeFn }) {
    const { t } = useTranslation();
    const [valid, setValid] = useState(true);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);
    const [after, setAfter] = useState('');
    const { isFetching, ids = [] } = useSelector(pageListSelector(page));
    const dispatch = useDispatch();

    const handlePageChange = (next) => (e) => {
        const res = dispatch(getPageListInfo(next - 1));
        const nextID = res ? res.ids[ids.length - 1] : '';
        dispatch(fetchPageList(next, nextID, limit)).then((nex) => {
            setPage(next);
            setAfter(nextID);
        });
    };

    useEffect(() => {
        function fetchCurrentPage() {
            dispatch(fetchPageList(1, '', limit));
            setValid((c) => true);
        }
        fetchCurrentPage();
    }, [after, dispatch, limit, page, valid]);

    const handleItemSelect = (page) => (e) => {
        e.preventDefault();
        handleChangeFn(page);
    };

    return (
        <ListContainer>
            <ListHeader>
                <ListHeadline>{t('pages:overview.header')}</ListHeadline>
                <ListControls>
                    {page > 1 && (
                        <ControlButton
                            type="button"
                            className="button--link"
                            label="pages:overview.prevpage.button"
                            onClick={handlePageChange(page - 1)}
                            icon={faArrowLeft}
                        />
                    )}
                    {ids.length >= Number(limit) - 1 && (
                        <ControlButton
                            type="button"
                            className="button--link"
                            label="pages:overview.nextpage.button"
                            onClick={handlePageChange(page + 1)}
                            icon={faArrowRight}
                        />
                    )}
                </ListControls>
            </ListHeader>
            <ListBody>
                {isFetching && (
                    <ListItem>
                        <Loading />
                    </ListItem>
                )}
                {!isFetching &&
                    ids &&
                    ids.map((item) => (
                        <PageItem key={item} id={item}>
                            {(node) => {
                                if (!node) {
                                    return null;
                                }
                                return (
                                    <ListItemControls className="button-group">
                                        <ControlButton
                                            type="button"
                                            icon={faPlus}
                                            label="pages:item.select"
                                            onClick={handleItemSelect(node)}
                                        />
                                    </ListItemControls>
                                );
                            }}
                        </PageItem>
                    ))}
            </ListBody>
        </ListContainer>
    );
}

export default PageSelectComponent;
