import React, { useState, useEffect } from 'react';
export function ItemSorter({ items: initalItems = [], children, onChange: handleChange }) {
    const [state, setState] = useState({ items: initalItems });
    useEffect(() => {
        setState({ items: initalItems });
    }, [initalItems]);
    const handleMove = (from, to) => () => {
        const { items } = state;
        if (from < 0 || from >= items.length) {
            return;
        }
        if (to < 0 || to >= items.length) {
            return;
        }
        const current = items[from];
        for (let i = from; i < to; i = i + 1) {
            items[i] = { ...items[i + 1], after: i <= 0 ? '' : items[i - 1].id };
        }
        items[to] = current;
        for (let i = 0; i < items.length; i++) {
            items[i] = { ...items[i], after: i <= 0 ? '' : items[i - 1].id };
        }
        handleChange(items);
        setState({ items });
    };
    return (
        <React.Fragment>
            {state.items.map((item, index, arr) =>
                children(
                    item,
                    arr.length - 1 > index ? handleMove(index, index + 1) : null,
                    index > 0 ? handleMove(index - 1, index) : null
                )
            )}
        </React.Fragment>
    );
}

export default ItemSorter;
