import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import { History } from 'history';
import React, { useState } from 'react';
import Modal from 'react-modal';
import ControlButton from './ControlButton';

interface ModalComponentT {
    history: History;
    isOpen?: boolean;
    header?: (props: any) => JSX.Element;
    render?: (props: any) => JSX.Element;
    footer?: (props: any) => JSX.Element;
    onRequestClose?: () => void;
}

interface ModalState {
    isOpen: boolean;
}

type ModalCloseCall = () => void;
type ModalChildCall = (close: ModalCloseCall, state: ModalState) => JSX.Element | null;

interface ModalWrapperT {
    isOpen: boolean | undefined;
    children: ModalChildCall;
    onClose?: () => void | undefined;
}

export function ModalWrapper({ isOpen = true, onClose, children }: ModalWrapperT) {
    const [state, setState] = useState({ isOpen });
    const handleRequestClose = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (onClose) {
            onClose();
        }
        setState({ isOpen: false });
    };
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleRequestClose}
            className={{
                base: 'modal',
                afterOpen: 'modal--open',
                beforeClose: 'modal--closing',
            }}
            overlayClassName={{
                base: 'modal-overlay',
                afterOpen: 'modal-overlay--open',
                beforeClose: 'modal-overlay--closing',
            }}
            bodyOpenClassName="modal-body--open"
        >
            {children(handleRequestClose, state)}
        </Modal>
    );
}

export function ModalComponent({
    isOpen = true,
    history,
    header,
    render,
    footer,
    ...props
}: ModalComponentT) {
    const handleRequestClose = (e) => {
        e.preventDefault();
        const {
            onRequestClose = () => {
                return;
            },
        } = props;
        onRequestClose();
    };
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleRequestClose}
            className={{
                base: 'modal',
                afterOpen: 'modal--open',
                beforeClose: 'modal--closing',
            }}
            overlayClassName={{
                base: 'modal-overlay',
                afterOpen: 'modal-overlay--open',
                beforeClose: 'modal-overlay--closing',
            }}
            bodyOpenClassName="modal-body--open"
        >
            {header &&
                header({
                    history,
                    className: 'modal-header',
                    onRequestClose: handleRequestClose,
                    ...props,
                })}
            {render &&
                render({
                    history,
                    className: 'modal-content',
                    onRequestClose: handleRequestClose,
                    ...props,
                })}
            {footer &&
                footer({
                    history,
                    className: 'modal-footer',
                    onRequestClose: handleRequestClose,
                    ...props,
                })}
        </Modal>
    );
}

export function inModal(ContentComponent, options = { withOutHeader: false }) {
    return ({ history, ...props }) => {
        const [modalOpen, setModalOpen] = useState(true);
        const requestClose = () => {
            setModalOpen(false);
            history.goBack();
        };
        const requestCloseButton = (e) => {
            e.preventDefault();
            requestClose();
        };
        return (
            <Modal
                isOpen={modalOpen}
                onRequestClose={requestClose}
                className={{
                    base: 'modal',
                    afterOpen: 'modal--open',
                    beforeClose: 'modal--closing',
                }}
                overlayClassName={{
                    base: 'modal-overlay',
                    afterOpen: 'modal-overlay--open',
                    beforeClose: 'modal-overlay--closing',
                }}
                bodyOpenClassName="modal-body--open"
            >
                {!options.withOutHeader && (
                    <header className="modal-header">
                        <ControlButton
                            type="button"
                            label="modals:close"
                            icon={faTimes}
                            className="button-create"
                            onClick={requestCloseButton}
                        />
                    </header>
                )}
                <ContentComponent className="modal-content" history={history} {...props} />
            </Modal>
        );
    };
}

export function ModalContainer({ children, isOpen = true, onRequestClose: handleRequestClose }) {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleRequestClose}
            className={{
                base: 'modal',
                afterOpen: 'modal--open',
                beforeClose: 'modal--closing',
            }}
            overlayClassName={{
                base: 'modal-overlay',
                afterOpen: 'modal-overlay--open',
                beforeClose: 'modal-overlay--closing',
            }}
            bodyOpenClassName="modal-body--open"
        >
            {children}
        </Modal>
    );
}
