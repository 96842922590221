import React from 'react';
import Input, { InputGroup, InputLabel } from './InputComponent';

function FormInputField({ id, name, label, value, onChange: handleChangeFn, ...props }) {
    if (!handleChangeFn) {
        handleChangeFn = () => true;
    }
    const {
        type = 'text',
        className = '',
        required = false,
        autoFocus = false,
        autoComplete = 'off',
    } = props;
    return (
        <InputGroup>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <Input
                id={id}
                name={name}
                type={type}
                placeholder={label}
                required={required}
                autoFocus={autoFocus}
                autoComplete={autoComplete}
                value={value}
                onChange={handleChangeFn}
            />
        </InputGroup>
    );
}

export default FormInputField;
