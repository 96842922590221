import classnames from 'classnames';
import React from 'react';
import { InputGroup, InputLabel, InputTextarea } from './InputComponent';

function FormTextArea({ id, name, label, value, onChange: handleChangeFn, ...props }) {
    const { className = '', required = false, autoFocus = false, autoComplete = 'off' } = props;
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        handleChangeFn(e.target.value);
    };
    return (
        <InputGroup>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <InputTextarea
                id={id}
                name={name}
                placeholder={label}
                required={required}
                autoFocus={autoFocus}
                autoComplete={autoComplete}
                value={value}
                onChange={handleChange}
            />
        </InputGroup>
    );
}

export default FormTextArea;
