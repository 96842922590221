import styled from '@emotion/styled';
import React from 'react';
import { InputProps, OptionInputProps } from 'types';

export const InputLabel = styled.label`
    display: inline-block;
    margin-bottom: 0.5rem;
`;

const basicStyle = `
    background-clip: padding-box;
    background-color: rgb(255, 255, 255);
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    color: #495057;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    font-size: 1rem;
    justify-content: space-between;
    line-height: 1.5;
    min-height: 38px;
    outline: currentcolor none 0px !important;
    padding: .375rem .75rem;
    position: relative;
    transition: all 100ms ease 0s;
    width: 100%;
`;

export const InputElement = styled.input(basicStyle);

const styleNested = ({ nested }) =>
    nested &&
    `
  ${InputElement},
  ${InputLabel} {
    padding: var(--input-padding-y) var(--input-padding-x);
  }
  ${InputElement}::placeholder {
    color: transparent;
  }
  ${InputElement}:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y) / 3);
  }
  ${InputElement}:not(:placeholder-shown) ~ ${InputLabel} {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: .8rem;
    color: currentColor;
  }
  ${InputLabel} {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    line-height: 1.5;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: all 0.1s ease-in-out;
    user-select: none;
  }
`;

export const InputWrapper = styled.div`
    position: relative;
    box-sizing: border-box;
    ${styleNested}
`;

const CheckboxWrapper = styled.div`
    position: relative;
    display: block;
    padding-left: 1.25rem;
`;
const CheckboxElement = styled.input`
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
`;
const CheckboxLabel = styled.label`
    display: inline-block;
    margin-bottom: 0;
`;

export const InputGroupWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const InputGroupActions = styled.div``;

export const InputGroup = styled.div``;
export const InputTextarea = styled.textarea`
    ${basicStyle}
    resize: vertical;
`;

function InputOptionElement({
    type = 'checkbox',
    label,
    checked,
    onChange: handleChangeFn,
}: OptionInputProps) {
    const handleChange = (e) => {
        e.stopPropagation();
        handleChangeFn();
    };
    return (
        <CheckboxWrapper>
            <CheckboxElement
                autoComplete="off"
                type={type}
                checked={checked}
                onChange={handleChange}
            />
            <CheckboxLabel>{label}</CheckboxLabel>
        </CheckboxWrapper>
    );
}

export const InputCheckbox = ({
    id,
    name,
    label,
    checked,
    onChange: handleChangeFn,
}: OptionInputProps) => (
    <InputOptionElement
        type="checkbox"
        id={id}
        name={name}
        label={label}
        checked={checked}
        onChange={handleChangeFn}
    />
);

export const InputRadio = ({
    id,
    name,
    label,
    checked,
    onChange: handleChangeFn,
}: OptionInputProps) => (
    <InputOptionElement
        type="radio"
        id={id}
        name={name}
        label={label}
        checked={checked}
        onChange={handleChangeFn}
    />
);

export function Input({ required = false, onChange: handleChangeFn, ...props }: InputProps) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        handleChangeFn(e.target.value);
    };
    return (
        <InputWrapper>
            <InputElement {...props} autoComplete="off" onChange={handleChange} />
        </InputWrapper>
    );
}

type InputComponentProps = InputProps & {
    id: string;
    name: string;
    label: string;
    autoFocus?: boolean;
    required?: boolean;
    onChange: (x: string) => void;
};

export function InputUsername({
    name,
    id,
    label,
    required = false,
    autoFocus = false,
    onChange: handleChangeFn,
}: InputComponentProps) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        handleChangeFn(e.target.value);
    };
    return (
        <InputWrapper nested={true}>
            <InputElement
                id={id}
                type="text"
                name={name}
                required={required}
                autoFocus={autoFocus}
                placeholder={label}
                autoComplete="off"
                onChange={handleChange}
            />
            <InputLabel htmlFor={id}>{label}</InputLabel>
        </InputWrapper>
    );
}

export function InputPassword({
    name,
    id,
    label,
    required = false,
    autoFocus = false,
    onChange: handleChangeFn,
}: InputComponentProps) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        handleChangeFn(e.target.value);
    };
    return (
        <InputWrapper nested={true}>
            <InputElement
                id={id}
                type="password"
                name={name}
                required={required}
                autoFocus={autoFocus}
                placeholder={label}
                autoComplete="off"
                onChange={handleChange}
            />
            <InputLabel htmlFor={id}>{label}</InputLabel>
        </InputWrapper>
    );
}

export default Input;
