import { PageInfo } from 'types/global.types';
import { TemplateActionTypes } from 'store/actions';
import { TemplateListAction, Template, TemplateAction } from './types';

function requestTemplateList(page: number): TemplateListAction {
    return {
        type: TemplateActionTypes.LIST_REQUEST,
        payload: {
            isFetching: true,
            page,
            error: null,
        },
    };
}

function receiveTemplateList(
    page: number,
    templates: Template[],
    pageInfo: PageInfo
): TemplateListAction {
    return {
        type: TemplateActionTypes.LIST_SUCCESS,
        payload: {
            isFetching: false,
            page,
            error: null,
            templates,
        },
        pageInfo,
    };
}

function errorTemplateList(page: number, message: string): TemplateListAction {
    return {
        type: TemplateActionTypes.LIST_FAILURE,
        payload: {
            isFetching: false,
            page,
            error: message,
        },
    };
}

type AsyncTemplateListAction = (a: any) => Promise<TemplateListAction>;

export function fetchTemplatePage(
    page: number,
    cursor: string,
    first: number = 50
): AsyncTemplateListAction {
    const query = new URLSearchParams();
    query.append('cursor', cursor);
    query.append('limit', String(first));
    return async (dispatch) => {
        await dispatch(requestTemplateList(page));
        const res = await fetch(`/api/templates?${query}`);
        if (res.status >= 200 && res.status <= 300) {
            const { edges, pageInfo } = await res.json();
            return dispatch(receiveTemplateList(page, edges.map(({ node }) => node), pageInfo));
        }
        throw dispatch(errorTemplateList(page, 'Invalid Query'));
    };
}

function requestTemplate(id: string): TemplateAction {
    return {
        type: TemplateActionTypes.GET_TEMPLATE_REQUEST,
        payload: {
            id,
            isFetching: true,
            error: null,
        },
    };
}

function receiveTemplate(id: string, template: Template): TemplateAction {
    return {
        type: TemplateActionTypes.GET_TEMPLATE_SUCCESS,
        payload: {
            id,
            isFetching: false,
            error: null,
            template,
        },
    };
}

function removeTemplate(id: string): TemplateAction {
    return {
        type: TemplateActionTypes.DELETE_TEMPLATE_SUCCESS,
        payload: {
            id,
            isFetching: false,
            error: null,
        },
    };
}

function errorTemplate(id: string, message: string): TemplateAction {
    return {
        type: TemplateActionTypes.GET_TEMPLATE_FAILURE,
        payload: {
            id,
            isFetching: false,
            error: message,
        },
    };
}

type AsyncTemplateAction = (a: any) => Promise<TemplateAction>;

export function fetchTemplate(cursor: string): AsyncTemplateAction {
    return async (dispatch) => {
        if (cursor === '') {
            throw dispatch(errorTemplate(cursor, 'Invalid Query'));
        }
        await dispatch(requestTemplate(cursor));
        const res = await fetch(`/api/templates/${cursor}`);
        if (res.status >= 200 && res.status <= 300) {
            const template = await res.json();
            return dispatch(receiveTemplate(cursor, template));
        }
        throw dispatch(errorTemplate(cursor, 'Invalid Query'));
    };
}

const headers = {
    'Content-Type': 'application/json',
};

export function requestTemplateUpdate(id: string): TemplateAction {
    return {
        type: TemplateActionTypes.UPDATE_TEMPLATE_REQUEST,
        payload: {
            id,
            isFetching: true,
            error: null,
        },
    };
}

export function errorTemplateUpdate(id: string, message: string): TemplateAction {
    return {
        type: TemplateActionTypes.UPDATE_TEMPLATE_FAILURE,
        payload: {
            id,
            isFetching: false,
            error: message,
        },
    };
}

export function updateTemplateStatus(cursor: string, status: boolean): AsyncTemplateAction {
    return async (dispatch) => {
        await dispatch(requestTemplateUpdate(cursor));
        const res = await fetch(`/api/templates/${cursor}`, {
            method: 'POST',
            mode: 'same-origin',
            headers,
            body: JSON.stringify({ status: !status }),
        });
        if (res.status >= 200 && res.status <= 300) {
            const template = await res.json();
            return dispatch(receiveTemplate(cursor, template));
        }
        throw dispatch(errorTemplateUpdate(cursor, 'Invalid Query'));
    };
}

export function deleteTemplate(cursor: string): AsyncTemplateAction {
    return async (dispatch) => {
        await dispatch(requestTemplateUpdate(cursor));
        const res = await fetch(`/api/templates/${cursor}`, {
            method: 'DELETE',
            mode: 'same-origin',
            headers,
        });
        if (res.status >= 200 && res.status <= 300) {
            const template = await res.json();
            return dispatch(removeTemplate(cursor));
        }
        throw dispatch(errorTemplateUpdate(cursor, 'Invalid Query'));
    };
}

export function createTemplate(create: Template): AsyncTemplateAction {
    return async (dispatch) => {
        const res = await fetch('/api/templates', {
            method: 'POST',
            mode: 'same-origin',
            headers,
            body: JSON.stringify(create),
        });
        if (res.status >= 200 && res.status <= 300) {
            const template = await res.json();
            return dispatch(receiveTemplate(template.id, template));
        }
        throw new Error('Invalid Query');
    };
}
