import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function ControlLink({
    to: pathname,
    label: tLabel,
    prefix = 'button',
    className = '',
    translate = true,
    ...props
}) {
    const { icon, modal = false, search = '' } = props;
    const { t } = useTranslation();
    const label = translate ? t(tLabel) : tLabel;
    const link = {
        pathname,
        search,
        state: { modal },
    };
    return (
        <Link to={link} className={classnames(prefix, className)} title={label}>
            {icon && <FontAwesomeIcon className={`${prefix}__icon`} icon={icon} />}
            <span className={`${prefix}__text`}>{label}</span>
        </Link>
    );
}

export default ControlLink;
