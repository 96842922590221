import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { PageAction, Page } from 'store/pages/types';
import { pageSelector } from 'store/pages/selectors';
import { fetchPage, updatePageStatus, deletePage } from 'store/pages/actions';
import { ListItem, ListItemTitleField } from 'components/lists/ListContainer';
import { Loading } from 'components/general/LoadinInfo';

type PageFunction = (x: any) => Promise<PageAction>;

type PageItemProps = {
    id: string;
    children: (
        node: Page | null,
        toggleStatus: PageFunction,
        handleDelete: PageFunction
    ) => JSX.Element | null;
};

function PageItem({ id, children }: PageItemProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isFetching, node } = useSelector(pageSelector(id));

    // useEffect(() => {
    //     function fetchCurrentPage() {
    //         dispatch(fetchPage(id));
    //     }
    //     fetchCurrentPage();
    // }, [dispatch, id]);
    const handleStatus = () => {
        return dispatch(updatePageStatus(id, node.status));
    };
    const handleDelete = () => {
        return dispatch(deletePage(id));
    };
    if (isFetching) {
        return (
            <ListItem>
                <Loading />
            </ListItem>
        );
    }
    if (!node) {
        return (
            <ListItem>
                <ListItemTitleField>{t('graphql:error')}</ListItemTitleField>
            </ListItem>
        );
    }
    return (
        <ListItem>
            <ListItemTitleField>{node.name}</ListItemTitleField>
            {children(node, handleStatus, handleDelete)}
        </ListItem>
    );
}

export default PageItem;
