import { faPen, faTrash, faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { contentSelecor } from 'store/contents/selectors';
import { Loading } from 'components/general/LoadinInfo';
import { fetchContent, fetchContentsColumn, updateContentStatus } from 'store/contents/actions';
import ControlLink from 'components/controls/ControlLink';
import ControlButton from 'components/controls/ControlButton';
import { Content } from 'store/contents/types';
import ImageComponent from './ImageComponent';
import TextComponent from './TextComponent';
import ImageTextComponent from './ImageTextComponent';
import EmbeddedComponent from './EmbeddedComponent';
import { ContentComponentProps } from './helpers';

type ContentDisplayElementMap = {
    [x: string]: (props: ContentComponentProps) => JSX.Element | null;
};

const types: ContentDisplayElementMap = {
    images: ImageComponent,
    text: TextComponent,
    'text-images': ImageTextComponent,
    embedded: EmbeddedComponent,
};

type ContentElementProps = {
    item: string;
    page: string;
    portal: string;
    children: any;
    renderPositions?: (x: any) => JSX.Element | null;
    onDelete: () => void;
};

function ContentElement({
    item,
    page,
    portal,
    children,
    renderPositions = () => null,
    onDelete,
}: ContentElementProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isFetching, node } = useSelector(contentSelecor(item));
    const [resolved, setResolved] = useState(false);

    useEffect(() => {
        dispatch(fetchContent(item))
            .then((res) => setResolved(true))
            .catch((err) => setResolved(true));
    }, [item, dispatch]);
    const handleDelete = (cur: Content) => () => {
        onDelete(cur);
    };
    const handleStatus = (cur: Content) => () => {
        setResolved(false);
        dispatch(updateContentStatus(item, cur.status))
            .then(dispatch(fetchContentsColumn(cur.page, cur.column)))
            .then((res) => setResolved(true))
            .catch((err) => setResolved(true));
    };
    if (isFetching) {
        return <Loading />;
    }
    if (!resolved) {
        return <Loading />;
    }
    if (!node) {
        return null;
    }
    const params = new URLSearchParams();
    params.append('page', page);
    params.append('column', node.column);
    if (portal) {
        params.append('portal', portal);
    }
    if (!types[node.type]) {
        return <span>Error</span>;
    }
    const DisplayElement = types[node.type];
    return (
        <div className="content-element">
            <div className="content-element__header">
                <span className="content-element__header-title">
                    {t(`content:element.type.${node.type}`)}
                </span>
                <div className="button-group">
                    {renderPositions(item)}
                    <ControlLink
                        to={`/contents/${node.id}`}
                        search={params.toString()}
                        type="button"
                        prefix="button"
                        className="button-edit"
                        icon={faPen}
                        label="pages:content.edit"
                    />
                    <ControlButton
                        type="button"
                        prefix="button"
                        className="button-status"
                        icon={node.status ? faEyeSlash : faEye}
                        label="pages:content.status"
                        onClick={handleStatus(node)}
                    />
                    <ControlButton
                        type="button"
                        prefix="button"
                        className="button-delete"
                        icon={faTrash}
                        label="pages:content.delete"
                        onClick={handleDelete(node)}
                    />
                </div>
            </div>
            <DisplayElement item={node} />
            {children(node)}
        </div>
    );
}

export default ContentElement;
