import React from 'react';

import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

const FileContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid #cecece;
    margin-top: 1rem;
`;

const FileHeader = styled.div`
    display: flex;
    flex-direction: row;
    background-color: #ebebeb;
`;

const FileInfoContainer = styled.div`
    & + & {
        margin-top: 1rem;
    }
`;

const FileInfoBody = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid #cecece;
    background-color: #f5f5f5;
`;
const FileInfoHeader = styled.span``;
const FileCropContainer = styled.div``;

const FileCropHeader = styled.span``;
const FileCropBody = styled.div`
    border: 1px solid #707070;
    background-color: #ffffff;
`;

const FileHeaderInfoHeadline = styled.span``;
const FileHeaderInfo = styled.div`
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    flex-grow: 1;
    align-items: center;
    justify-items: stretch;
    margin-left: 1rem;
`;

const FileHeaderControls = styled.div`
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    border-radius: 0.25rem;
    border: none;
    .button {
        position: relative;
        flex: 0 1 auto;
        box-shadow: none;
        &:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        &:not(:first-of-type) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    .button__text {
        display: none;
    }
`;
const FileBody = styled.div`
    padding: 1rem;
`;

function FileItem({ file, children }) {
    const { t } = useTranslation();
    return (
        <FileContainer>
            <FileHeader>
                <FileHeaderInfo>
                    <FileHeaderInfoHeadline>{file.header || file.filename}</FileHeaderInfoHeadline>
                </FileHeaderInfo>
                <FileHeaderControls>{children(file)}</FileHeaderControls>
            </FileHeader>
            <FileBody>
                <FileInfoContainer>
                    <FileInfoHeader>{t('images:item.crops.header.title')}</FileInfoHeader>
                    <FileInfoBody>
                        <FileCropContainer>
                            <FileCropHeader>{t('images:item.crops.default.title')}</FileCropHeader>
                            <FileCropBody>
                                <img
                                    className="file-image__img"
                                    src={`/api/files/display?file=${file.value}&type=preview-big`}
                                />
                            </FileCropBody>
                        </FileCropContainer>
                    </FileInfoBody>
                </FileInfoContainer>
                <FileInfoContainer>
                    <FileInfoHeader>{t('images:item.text.title')}</FileInfoHeader>
                    <FileInfoBody dangerouslySetInnerHTML={{ __html: file.text }} />
                </FileInfoContainer>
                <FileInfoContainer>
                    <FileInfoHeader>{t('images:item.link.title')}</FileInfoHeader>
                    <FileInfoBody>{file.link}</FileInfoBody>
                </FileInfoContainer>
            </FileBody>
        </FileContainer>
    );
}

export default FileItem;
