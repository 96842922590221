import React, { useEffect } from 'react';
import i18next from 'i18next';
import { withRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';

import deDE from './locales/de_DE.json';
import enUS from './locales/en_US.json';

i18next.init({
    fallbackLng: 'de_DE',
    fallbackNS: ['translation'],
    resources: {
        de_DE: deDE,
        en_US: enUS,
    },
    parseMissingKeyHandler: (missing) => {
        if (process.env.NODE_ENV === 'development') {
            console.warn('MISSING TRANSLATION:', missing);
        }
        return missing;
    },
});

i18next.languages = ['de_DE', 'en_US'];

type Props = {
    children: React.ReactNode;
    locale: 'en_US' | 'de_DE';
};

const I18N = ({ children, locale }: Props) => {
    useEffect(() => {
        i18next.changeLanguage(locale);
    }, [locale]);

    return <I18nextProvider i18n={i18next}>{children}</I18nextProvider>;
};

export default withRouter<any>(React.memo(I18N));
