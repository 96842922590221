/* tslint:disable:no-var-requires */
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormEditorText from 'components/inputs/FormEditorText';
import {
    OptionPanel,
    OptionPanelCell,
    OptionPanelContent,
    OptionPanelHeader,
    OptionPanelHeadline,
    OptionPanelRow,
} from '../../../components/general/Panel';

function ContentText({ className = '', content, onChange: handleChangeFn }) {
    const { t } = useTranslation();
    const [editorState, setEditorState] = useState('');
    useEffect(() => {
        const { bodytext } = content;
        if (!bodytext) {
            return;
        }
        setEditorState(bodytext);
    }, [content]);

    const handleChange = (value) => {
        setEditorState(value);
        handleChangeFn({ bodytext: value });
    };
    return (
        <OptionPanel>
            <OptionPanelHeader>
                <OptionPanelHeadline>{t('content:create.bodytext.rich.title')}</OptionPanelHeadline>
            </OptionPanelHeader>
            <OptionPanelContent>
                <OptionPanelRow>
                    <OptionPanelCell>
                        <FormEditorText value={editorState} onChange={handleChange} />
                    </OptionPanelCell>
                </OptionPanelRow>
            </OptionPanelContent>
        </OptionPanel>
    );
}

export default ContentText;
