import { faArrowDown, faArrowUp, faLevelDown } from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import styled from '@emotion/styled';

import { InputGroup, InputLabel, InputWrapper } from 'components/inputs/InputComponent';
import { Page } from 'store/pages/types';
import { Portal } from 'store/portals/types';
import { portalPagesSelector } from 'store/portals/selectors';
import ControlButton from 'components/controls/ControlButton';

export type PageValue = {
    id?: string;
    name: string;
    portal: string;
    parent: string;
    after: string;
    nextParent: string;
    nextAfter: string;
};

const TreeBranch = styled.div``;
const TreeLeave = styled.div``;
const TreeActions = styled.div``;
const TreeLeaveText = styled.div``;

type PageWithChildren = Page & {
    isFirstChild: boolean;
    isAfter: boolean;
    children: PageWithChildren[];
};

type TreeActionProps = {
    item: Page;
    value: PageValue;
    onChange: (x: any) => void;
};

function TreeActionContainer({ item, value, onChange }: TreeActionProps) {
    if (!value) {
        return null;
    }
    const handleChange = (options) => () => {
        onChange(options);
    };
    const samePortal = item.portal === value.portal;
    const sameParent = samePortal && item.parent === value.nextParent;
    const isChild = samePortal && item.id === value.parent;
    const isAfter = sameParent && item.id === value.nextAfter;
    const isBefore = sameParent && item.after === value.nextAfter;
    return (
        <TreeActions>
            {!isChild && (
                <ControlButton
                    type="button"
                    label="page:settings.position.inside"
                    icon={faLevelDown}
                    onClick={handleChange({
                        portal: item.portal,
                        parent: item.id,
                        after: '',
                    })}
                />
            )}
            {!isBefore && (
                <ControlButton
                    type="button"
                    label="page:settings.position.before"
                    icon={faArrowUp}
                    onClick={handleChange({
                        portal: item.portal,
                        parent: item.parent,
                        after: item.after || '',
                    })}
                />
            )}
            {!isAfter && (
                <ControlButton
                    type="button"
                    label="page:settings.position.after"
                    icon={faArrowDown}
                    onClick={handleChange({
                        portal: item.portal,
                        parent: item.parent,
                        after: item.id || '',
                    })}
                />
            )}
        </TreeActions>
    );
}

type TreeObjectProps = {
    t: i18next.TFunction;
    item: PageWithChildren;
    value: PageValue;
    allowAction?: boolean;
    onChange: (x: any) => void;
};

const TreeItem = ({ t, item: node, value, allowAction = true, onChange }: TreeObjectProps) => {
    // const currentPages = pages.filter((i) => i.parent === node.id);
    const { children: currentPages } = node;
    return (
        <React.Fragment>
            <TreeLeave className="tree-branch__leave">
                <TreeLeaveText className="tree-branch__item-text">
                    <span>{node.name}</span>
                </TreeLeaveText>
                {allowAction && (
                    <TreeActionContainer item={node} value={value} onChange={onChange} />
                )}
            </TreeLeave>
            {((currentPages || []).length > 0 || node.isFirstChild) && (
                <TreeBranch className="tree-branch">
                    {node.isFirstChild && (
                        <TreeLeave className="tree-branch__leave tree-branch__leave--to-update">
                            <TreeLeaveText className="tree-branch__item-text">
                                <span>{value.name || t('page:create.placeholder')}</span>
                            </TreeLeaveText>
                        </TreeLeave>
                    )}
                    {currentPages.map((p) => (
                        <TreeObject key={p.id} t={t} item={p} value={value} onChange={onChange} />
                    ))}
                </TreeBranch>
            )}
            {node.isAfter && (
                <TreeLeave className="tree-branch__leave tree-branch__leave--to-update">
                    <TreeLeaveText className="tree-branch__item-text">
                        <span>{value.name || t('page:create.placeholder')}</span>
                    </TreeLeaveText>
                </TreeLeave>
            )}
        </React.Fragment>
    );
};

const TreeObject = React.memo(TreeItem);

type PagePositionProps = {
    id: string;
    label: string;
    portal: Portal;
    value: PageValue;
    onChange: (parent: string, after: string) => void;
};

function GeneratePageTreeItem(
    item: Page,
    pages: PageWithChildren[],
    value: PageValue
): PageWithChildren {
    const isFirstChild = item.id === value.nextParent && value.nextAfter === '';
    const isAfter = item.parent === value.nextParent && item.id === value.nextAfter;
    const currentPages = pages.filter((i) => i.parent === item.id);
    return {
        ...item,
        isAfter,
        isFirstChild,
        children: currentPages.map((prev, cur) => GeneratePageTreeItem(prev, pages, value)),
    };
}

function PageEditPositionComponent(props: PagePositionProps) {
    const { id, portal, label, value, onChange: handleChangeFn } = props;
    const { root_page: page } = portal;
    const { t } = useTranslation();
    const { isFetching, pages: pageInput } = useSelector(portalPagesSelector(page.portal));

    const [currentValue, setValue] = useState({
        ...value,
        nextAfter: value.after || '',
        nextParent: value.parent || '',
    });
    const [pageList, _] = useState(
        pageInput
            .filter((i) => i.id !== value.id)
            .map<PageWithChildren>((item) =>
                Object.assign({}, item, {
                    after: item.after || '',
                    parent: item.parent || '',
                    isFirstChild: false,
                    isAfter: false,
                    children: [],
                })
            )
    );

    const [pages, setPageTree] = useState<PageWithChildren>(page);

    useEffect(() => {
        setPageTree(GeneratePageTreeItem(page, pageList, currentValue));
    }, [currentValue, currentValue.nextAfter, currentValue.nextParent, page, pageList]);

    useEffect(() => {
        const { name } = value;
        setValue((c) => ({ ...c, name }));
    }, [value, value.name]);

    function handlePositionChange(options) {
        const cleanPages = pageInput.map<PageWithChildren>((item) => ({
            ...item,
            isFirstChild: false,
            isAfter: false,
            children: [],
        }));
        const nextValue = { ...currentValue, nextAfter: options.after, nextParent: options.parent };
        setPageTree(GeneratePageTreeItem(page, cleanPages, nextValue));
        setValue(nextValue);
        handleChangeFn(nextValue.nextParent, nextValue.nextAfter);
    }

    return (
        <InputGroup>
            <InputLabel className="form-label" htmlFor={id}>
                {label}
            </InputLabel>
            <InputWrapper id={id}>
                {!isFetching && (
                    <TreeBranch className="tree-branch">
                        <TreeObject
                            t={t}
                            item={pages}
                            value={currentValue}
                            onChange={handlePositionChange}
                            allowAction={false}
                        />
                    </TreeBranch>
                )}
            </InputWrapper>
        </InputGroup>
    );
}

export default PageEditPositionComponent;
