import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    OptionPanel,
    OptionPanelCell,
    OptionPanelContent,
    OptionPanelHeader,
    OptionPanelRow,
    OptionPanelHeadline,
} from '../../../components/general/Panel';
import Input, {
    InputGroup,
    InputLabel,
    InputTextarea,
} from '../../../components/inputs/InputComponent';

function ContentEmbedded({ className = '', content, onChange: handleChangeFn }) {
    const { t } = useTranslation();
    const [bodyState, setBodyState] = useState('');
    const [headerState, setHeaderState] = useState('');
    useEffect(() => {
        const { bodytext, header } = content;
        if (bodytext) {
            setBodyState(bodytext);
        }
        if (header) {
            setHeaderState(header);
        }
    }, [content]);

    const handleChangeHeader = (value) => {
        setHeaderState(value);
        handleChangeFn({
            header: value,
            bodytext: bodyState,
        });
    };
    const handleChangeText = ({ target: { value } }) => {
        setBodyState(value);
        handleChangeFn({
            header: headerState,
            bodytext: value,
        });
    };
    return (
        <OptionPanel>
            <OptionPanelHeader>
                <OptionPanelHeadline>
                    {t('content:create.bodytext.embedded.title')}
                </OptionPanelHeadline>
            </OptionPanelHeader>
            <OptionPanelContent>
                <OptionPanelRow>
                    <OptionPanelCell>
                        <InputGroup>
                            <InputLabel>{t('content:create.headlines.headline.hidden')}</InputLabel>
                            <Input
                                id="header"
                                name="header"
                                type="text"
                                required={true}
                                autoComplete="off"
                                value={headerState}
                                onChange={handleChangeHeader}
                            />
                        </InputGroup>
                    </OptionPanelCell>
                </OptionPanelRow>
                <OptionPanelRow>
                    <OptionPanelCell>
                        <InputGroup>
                            <InputLabel>{t('content:create.bodytext.embedded.title')}</InputLabel>
                            <InputTextarea
                                id="bodytext"
                                name="bodytext"
                                required={true}
                                autoComplete="off"
                                value={bodyState}
                                onChange={handleChangeText}
                            />
                        </InputGroup>
                    </OptionPanelCell>
                </OptionPanelRow>
            </OptionPanelContent>
        </OptionPanel>
    );
}

export default ContentEmbedded;
