import React, { useEffect, useState } from 'react';
import { Route, Switch, withRouter, RouteComponentProps } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { portalSelector } from 'store/portals/selectors';
import { fetchPortal, fetchPortalPages } from 'store/portals/actions';
import { Loading } from 'components/general/LoadinInfo';
import PortalSettingsComponent from './PortalSettingsComponent';
import PortalSidebarComponent from './components/PortalSidebarComponent';
import PortalIndexComponent from './PortalIndexComponent';

function PortalEditComponent({ match }: RouteComponentProps) {
    const {
        params: { portalID },
    } = match;
    const { isFetching, node: portal } = useSelector(portalSelector(portalID));
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [resolved, setResolved] = useState(false);
    useEffect(() => {
        dispatch(fetchPortal(portalID))
            .then((res) => dispatch(fetchPortalPages(portalID)))
            .then((res) => {
                console.info(res);
                setResolved(true);
            })
            .catch((err) => {
                console.info(err);
                setResolved(true);
            });
    }, [dispatch, portalID]);
    if (!resolved) {
        return <Loading />;
    }
    return (
        <React.Fragment>
            {portal && <PortalSidebarComponent portal={portal} match={match} />}
            <div className="portal-content">
                <Switch>
                    <Route path={`${match.path}/`} exact={true}>
                        {(cur) => cur && portal && <PortalIndexComponent portal={portal} />}
                    </Route>
                    <Route path={`${match.path}/settings`}>
                        {(cur) => cur && portal && <PortalSettingsComponent portal={portal} />}
                    </Route>
                </Switch>
            </div>
        </React.Fragment>
    );
}

export default PortalEditComponent;
