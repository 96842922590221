import { AppState } from 'store/types';
import { TemplatePaginationPage, Template, TemplateItem } from './types';

export const templatePageSelector = (page: number) => (state: AppState): TemplatePaginationPage => {
    return Object.assign({ isFetching: false, ids: [] }, state.templates.pagination.pages[page]);
};

export const templateSelector = (template: string) => (state: AppState): TemplateItem => {
    if (template === '') {
        return { isFetching: false, error: null, node: null };
    }
    return Object.assign({ isFetching: false }, state.templates.templates[template]);
};

export const templatesSelector = (state: AppState): TemplateItem => {
    return Object.values(state.templates.templates).map(({ node }) => ({
        value: node.id,
        label: node.name,
    }));
};
