import React, { useEffect, useState } from 'react';
import { Route, Switch, withRouter, RouteComponentProps } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import { fetchPage } from 'store/pages/actions';
import { fetchPortal, fetchPortalPages } from 'store/portals/actions';
import { portalSelector } from 'store/portals/selectors';
import { pageSelector } from 'store/pages/selectors';
import { ContainerComponent } from 'components/Containers';
import { Loading } from 'components/general/LoadinInfo';
import { fetchTemplate } from 'store/templates/actions';
import PortalSidebarComponent from './portals/components/PortalSidebarComponent';
import ContentCreateComponent from './contents/ContentCreateComponent';
import ContentEditComponent from './contents/ContentEditComponent';

function ContentsComponent({ match, location, history }: RouteComponentProps) {
    const { search } = location;
    const params = new URLSearchParams(search);
    const portalID = params.get('portal') || '';
    const pageID = params.get('page') || '';
    const dispatch = useDispatch();
    const { node: portal } = useSelector(portalSelector(portalID || ''));
    const { isFetching, node: page } = useSelector(pageSelector(pageID || ''));
    const [resolved, setResolved] = useState(false);
    useEffect(() => {
        Promise.all([
            dispatch(fetchPortal(portalID || '')).then((res) =>
                dispatch(fetchPortalPages(portalID || ''))
            ),
            dispatch(fetchPage(pageID)),
        ])
            .then(([_, { payload: { page: { template } } }]) => dispatch(fetchTemplate(template)))
            .then((res) => setResolved(true))
            .catch((res) => setResolved(true));
    }, [dispatch, pageID, portalID]);
    if (!resolved || isFetching) {
        return <Loading />;
    }
    return (
        <section className="app-content app-content--contents">
            {portal && <PortalSidebarComponent portal={portal} match={match} />}
            <ContainerComponent className="page-content">
                <Switch>
                    <Route path={`${match.path}/create`}>
                        {(cur) => cur && <ContentCreateComponent page={page} {...cur} />}
                    </Route>
                    <Route path={`${match.path}/:contentID`}>
                        {(cur) => cur && <ContentEditComponent page={page} {...cur} />}
                    </Route>
                    {/*  */}
                </Switch>
            </ContainerComponent>
        </section>
    );
}

export default withRouter(ContentsComponent);
