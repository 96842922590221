import React from 'react';
import TextDisplayComponent from './components/TextDisplayComponent';
import { ContentComponentProps } from './helpers';

function EmbeddedComponent(props: ContentComponentProps) {
    const { item } = props;
    return (
        <React.Fragment>
            <div className="content-element__content">
                <TextDisplayComponent item={item} />
            </div>
        </React.Fragment>
    );
}

export default EmbeddedComponent;
