import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { faSave } from '@fortawesome/pro-regular-svg-icons';
import { ModalWrapper } from 'components/controls/Modal';
import {
    ContainerTabContainer,
    ContentTabButton,
    ContainerBody,
    ContainerComponent,
    ContainerFooter,
    ContainerFooterSpacer,
} from 'components/Containers';
import {
    OptionPanel,
    OptionPanelContent,
    OptionPanelRow,
    OptionPanelCell,
} from 'components/general/Panel';
import Input, { InputLabel, InputGroup } from 'components/inputs/InputComponent';
import ControlButton from 'components/controls/ControlButton';
import FormEditorText from 'components/inputs/FormEditorText';
import { CropContainer } from './CropModal';

function ContentImageModal({ item, types, isOpen, onClose, onUpdate }) {
    const { t } = useTranslation();
    const [state, setState] = useState({
        id: item.id,
        file: item.value,
        view: 'info',
        header: item.header || '',
        text: item.text || '',
        link: item.link || '',
        alternate: item.alternate || '',
        crops: item.crops,
    });

    const handleFileChange = () => {
        onUpdate(state);
        onClose();
    };
    const handleTabChange = (type) => () => {
        setState({
            ...state,
            view: type,
        });
    };
    const handleHeaderChange = (value) => {
        setState({
            ...state,
            header: value,
        });
    };
    const handleAlternateChange = (value) => {
        setState({
            ...state,
            alternate: value,
        });
    };
    const handleLinkChange = (value) => {
        setState({
            ...state,
            link: value,
        });
    };
    const handleTextChange = (value) => {
        setState({
            ...state,
            text: value,
        });
    };
    const handleCropChange = (type) => (crops) => {
        const current = state.crops;
        current[type] = Object.assign({}, current[type], crops);
        setState({
            ...state,
            crops: current,
        });
    };
    return (
        <ModalWrapper isOpen={isOpen} onClose={onClose}>
            {(close) => (
                <ContainerComponent>
                    <ContainerTabContainer>
                        <ContentTabButton
                            active={state.view === 'info'}
                            onClick={handleTabChange('info')}
                        >
                            {t('files:images.edit.tab.info.title')}
                        </ContentTabButton>
                        <ContentTabButton
                            active={state.view === 'crop'}
                            onClick={handleTabChange('crop')}
                        >
                            {t('files:images.edit.tab.crop.title')}
                        </ContentTabButton>
                    </ContainerTabContainer>
                    {state.view === 'crop' &&
                        types.map(
                            (type) =>
                                state.crops[type.value] && (
                                    <CropContainer
                                        key={type.value}
                                        label={t(type.label)}
                                        src={`/api/files/display?file=${state.crops[type.value].file}&type=source`}
                                        crop={state.crops[type.value]}
                                        onChange={handleCropChange(type.value)}
                                    />
                                )
                        )}
                    {state.view === 'info' && (
                        <ContainerBody>
                            <OptionPanel>
                                <OptionPanelContent>
                                    <OptionPanelRow>
                                        <OptionPanelCell>
                                            <InputGroup>
                                                <InputLabel>
                                                    {t('files:images.edit.headline.title')}
                                                </InputLabel>
                                                <Input
                                                    id="content_header"
                                                    name="content_header"
                                                    type="text"
                                                    required={true}
                                                    value={state.header}
                                                    onChange={handleHeaderChange}
                                                />
                                            </InputGroup>
                                        </OptionPanelCell>
                                    </OptionPanelRow>
                                    <OptionPanelRow>
                                        <OptionPanelCell>
                                            <InputGroup>
                                                <InputLabel>
                                                    {t('files:images.edit.link.title')}
                                                </InputLabel>
                                                <Input
                                                    id="content_link"
                                                    name="content_link"
                                                    type="text"
                                                    required={true}
                                                    value={state.link}
                                                    onChange={handleLinkChange}
                                                />
                                            </InputGroup>
                                        </OptionPanelCell>
                                    </OptionPanelRow>
                                    <OptionPanelRow>
                                        <OptionPanelCell>
                                            <InputGroup>
                                                <InputLabel>
                                                    {t('files:images.edit.alternate.title')}
                                                </InputLabel>
                                                <Input
                                                    id="content_alternate"
                                                    name="content_alternate"
                                                    type="text"
                                                    required={true}
                                                    value={state.alternate}
                                                    onChange={handleAlternateChange}
                                                />
                                            </InputGroup>
                                        </OptionPanelCell>
                                    </OptionPanelRow>
                                    <OptionPanelRow>
                                        <OptionPanelCell>
                                            <InputGroup>
                                                <InputLabel>
                                                    {t('files:images.edit.text.title')}
                                                </InputLabel>
                                                <FormEditorText
                                                    value={state.text}
                                                    onChange={handleTextChange}
                                                />
                                            </InputGroup>
                                        </OptionPanelCell>
                                    </OptionPanelRow>
                                </OptionPanelContent>
                            </OptionPanel>
                        </ContainerBody>
                    )}
                    <ContainerFooter>
                        <ContainerFooterSpacer />
                        <ControlButton
                            type="submit"
                            label="page:create.save"
                            icon={faSave}
                            className="button-create"
                            onClick={handleFileChange}
                        />
                    </ContainerFooter>
                </ContainerComponent>
            )}
        </ModalWrapper>
    );
}

export default ContentImageModal;
