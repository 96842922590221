import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { portalSelector } from 'store/portals/selectors';
import { fetchPortal, fetchPortalPages } from 'store/portals/actions';
import { Loading } from 'components/general/LoadinInfo';
import PageCreateComponent from './PageCreateComponent';

function PageCreatePageComponent({ history, location, ...props }) {
    const { search } = location;
    const params = new URLSearchParams(search);
    const portalID = params.get('portal') || '';
    const dispatch = useDispatch();
    const { isFetching, node: portal } = useSelector(portalSelector(portalID));
    const [isOpen, toggleOpen] = useState(true);
    const [sessionResolved, setSessionResolved] = useState(!!portal);
    useEffect(() => {
        function preparePages() {
            dispatch(fetchPortal(portalID))
                .then(() => dispatch(fetchPortalPages(portalID)))
                .then((res) => setSessionResolved(true))
                .catch((err) => setSessionResolved(true));
        }
        preparePages();
    }, [dispatch, portalID]);

    const handleCloseRequest = () => {
        toggleOpen(!isOpen);
        history.goBack();
    };

    const handleCreateRequest = (page) => {
        toggleOpen(!isOpen);
        history.push(`/pages/${page.id}${portal ? `?portal=${portal.id}` : ''}`);
    };
    if (!sessionResolved) {
        return <Loading />;
    }
    return (
        <PageCreateComponent
            portal={portal}
            className="modal-content"
            onCreate={handleCreateRequest}
        />
    );
}

export default PageCreatePageComponent;
