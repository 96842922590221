import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, RouteComponentProps } from 'react-router-dom';

const errorPage = (code, t) => {
    const errorCode = code.substr(0, 3);
    const errorFamily = code.substr(0, 2);
    const errorObj = {
        statusCode: errorCode,
        content: t('errors:default'),
    };
    switch (errorFamily) {
        case '40':
            if (errorCode === '403') {
                errorObj.content = t('errors:403');
            } else {
                errorObj.content = t('errors:400');
            }
            break;
        case '50':
            errorObj.content = t('errors:500');
            break;
        default:
            break;
    }
    return errorObj;
};

type ErrorPageProps = {
    error: string;
};

export function ErrorPage({ error }: ErrorPageProps) {
    const { t } = useTranslation();
    const { statusCode, content } = errorPage(error, t);

    return (
        <div className="app-content content--cover">
            <div className="cover-content">
                <h1>
                    {' '}
                    We got a problem <small>Error {statusCode}</small>
                </h1>
                <p className="lead">{content}</p>
                <p>
                    <Link href="/" to="/">
                        {t('global:home')}
                    </Link>
                </p>
            </div>
        </div>
    );
}

export function RoutedErrorPage({
    match: {
        params: { error = '404' },
    },
}: RouteComponentProps) {
    return <ErrorPage error={error} />;
}
