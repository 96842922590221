export const typeCategories = [
    {
        value: 'basic',
        label: 'Standart',
    },
    {
        value: 'overviews',
        label: 'Übersichten',
    },
];

export const contentElementTypes = [
    {
        value: 'images',
        label: 'content:element.type.images', // 'Bild',
        categories: ['basic'],
    },
    {
        value: 'text',
        label: 'content:element.type.text', // 'Text',
        categories: ['basic'],
    },
    {
        value: 'text-images',
        label: 'content:element.type.text-images', // 'Text mit Bild',
        categories: ['basic'],
    },
    {
        value: 'embedded',
        label: 'content:element.type.embedded', // 'Plain HTML',
        categories: ['basic'],
    },
];
