import {
    faUpload,
    faPen,
    faTrash,
    faArrowUp,
    faArrowDown,
} from '@fortawesome/pro-regular-svg-icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import {
    OptionPanel,
    OptionPanelHeader,
    OptionPanelHeadline,
    OptionPanelContent,
    OptionPanelRow,
    OptionPanelCell,
} from 'components/general/Panel';
import ControlUpload from 'components/controls/ControlUpload';
import ControlButton from 'components/controls/ControlButton';
import { InputGroup, InputLabel } from 'components/inputs/InputComponent';
import ItemSorter from 'components/ItemSorter';
import FileItem from './FileItem';
import ContentImageModal from './ContentImageModal';

function guidGenerator() {
    const S4 = function() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
}

const types = [
    { value: 'default', label: 'files:images.crop.type.default' },
    { value: 'large', label: 'files:images.crop.type.large' },
    { value: 'medium', label: 'files:images.crop.type.medium' },
    { value: 'small', label: 'files:images.crop.type.small' },
];

const columns = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
];

const defaultCrop = {
    default: {
        unit: '%',
        x: 0,
        y: 0,
        width: 100,
        height: 100,
    },
};

type ContentImageProps = {
    items: any[];
    orientationValue: string;
    columnValue: string;
    onImageAdd: (data: any) => void;
    onImageDelete: (data: any) => void;
    onImageChange: (id: string, data: any) => void;
    onImagePositionChange: (data: any) => void;
    onImageColumnChange: (data: any) => void;
};

function ContentImage({
    items,
    orientationValue,
    columnValue,
    onImageAdd: handleImageAdd,
    onImageDelete: handleImageDelete,
    onImageChange: handleImageChange,
    onImagePositionChange: handleImagePositionChange,
    onImageColumnChange: handleImageColumnChange,
}: ContentImageProps) {
    const { t } = useTranslation();

    const positions = [
        { value: 'above_center', label: t('files:images.position.above_center') },
        { value: 'above_right', label: t('files:images.position.above_right') },
        { value: 'above_left', label: t('files:images.position.above_left') },
        { value: 'below_center', label: t('files:images.position.below_center') },
        { value: 'below_right', label: t('files:images.position.below_right') },
        { value: 'below_left', label: t('files:images.position.below_left') },
        { value: 'intext_right', label: t('files:images.position.intext_right') },
        { value: 'intext_left', label: t('files:images.position.intext_left') },
        { value: 'wrapper_right', label: t('files:images.position.wrapper_right') },
        { value: 'wrapper_left', label: t('files:images.position.wrapper_left') },
    ];

    const imageorient = orientationValue || positions[0].value;
    const imagecols = columnValue || columns[0].value;

    const [position, setPosition] = useState(positions.find((item) => item.value === imageorient));
    const [column, setColumn] = useState(columns.find((item) => item.value === imagecols));
    const [cropModal, setCropModal] = useState(false);
    const [current, setCurrent] = useState(null);

    const handleImageUpload = (err, uploadedFiles) => {
        if (err) {
            return;
        }
        if (uploadedFiles.length <= 0) {
            return;
        }
        const currentState = [...items];
        let after = '';
        if (currentState.length > 0) {
            after = currentState[currentState.length - 1].id;
        }
        const data = [
            ...uploadedFiles.map((file) => ({
                id: guidGenerator(),
                value: file.id,
                filename: file.filename,
                after,
                crops: types.reduce(
                    (prev, cur) => ({
                        ...prev,
                        [cur.value]: Object.assign({}, defaultCrop.default, { file: file.id }),
                    }),
                    defaultCrop
                ),
            })),
        ];
        handleImageAdd(data);
    };
    const handleFileChange = ({ id, crops, ...rest }) => {
        const currentState = [...items];
        const fileIndex = currentState.findIndex((item) => item.id === id);
        const current = currentState[fileIndex];
        handleImageChange(id, { ...current, ...rest, crops });
    };
    const handleEditOpen = (file) => () => {
        setCurrent(file);
        setCropModal(true);
    };
    const handleFileEditClose = () => {
        setCropModal(false);
    };
    const handleDelete = ({ id }) => () => {
        handleImageDelete(id);
    };
    const handlePositionSelect = (res) => {
        setPosition(res);
        handleImagePositionChange(res.value);
    };
    const handleColumnSelect = (res) => {
        setColumn(res);
        handleImageColumnChange(res.value);
    };
    const handleImageMove = (data) => {
        for (const element of data) {
            handleImageChange(element.id, element);
        }
    };
    return (
        <OptionPanel>
            <OptionPanelHeader>
                <OptionPanelHeadline>{t('content:create.image.title')}</OptionPanelHeadline>
            </OptionPanelHeader>
            <OptionPanelContent>
                <OptionPanelRow>
                    <OptionPanelCell>
                        <InputGroup>
                            <InputLabel>{t('content:create.image.position.title')}</InputLabel>
                            <Select
                                options={positions}
                                value={position}
                                onChange={handlePositionSelect}
                            />
                        </InputGroup>
                    </OptionPanelCell>
                    <OptionPanelCell>
                        <InputGroup>
                            <InputLabel>{t('content:create.image.column.title')}</InputLabel>
                            <Select
                                options={columns}
                                value={column}
                                onChange={handleColumnSelect}
                            />
                        </InputGroup>
                    </OptionPanelCell>
                </OptionPanelRow>
            </OptionPanelContent>
            <OptionPanelContent>
                <OptionPanelRow>
                    <OptionPanelCell>
                        <ControlUpload
                            label="content:form.upload.image.button"
                            icon={faUpload}
                            multiple={true}
                            onUpload={handleImageUpload}
                        />
                    </OptionPanelCell>
                </OptionPanelRow>
            </OptionPanelContent>
            <OptionPanelContent>
                <ItemSorter items={items} onChange={handleImageMove}>
                    {(file, down, up) => {
                        if (file.delete) {
                            return null;
                        }
                        return (
                            <FileItem key={file.id} file={file}>
                                {(node) => (
                                    <React.Fragment>
                                        {up && (
                                            <ControlButton
                                                type="button"
                                                label="files:item.move.up.button"
                                                icon={faArrowUp}
                                                onClick={up}
                                            />
                                        )}
                                        {down && (
                                            <ControlButton
                                                type="button"
                                                label="files:item.move.down.button"
                                                icon={faArrowDown}
                                                onClick={down}
                                            />
                                        )}
                                        <ControlButton
                                            type="button"
                                            label="files:item.edit.button"
                                            icon={faPen}
                                            onClick={handleEditOpen(node)}
                                        />
                                        {handleDelete && (
                                            <ControlButton
                                                type="button"
                                                label="files:item.delete.button"
                                                icon={faTrash}
                                                onClick={handleDelete(node)}
                                            />
                                        )}
                                    </React.Fragment>
                                )}
                            </FileItem>
                        );
                    }}
                </ItemSorter>
            </OptionPanelContent>
            {current && (
                <ContentImageModal
                    key={current.id}
                    isOpen={cropModal}
                    item={current}
                    types={types}
                    onClose={handleFileEditClose}
                    onUpdate={handleFileChange}
                />
            )}
        </OptionPanel>
    );
}

export default ContentImage;
