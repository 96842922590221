import React from 'react';
import Input, { InputGroup, InputWrapper, InputElement } from 'components/inputs/InputComponent';
import { InputProps } from 'types';

function LinkInput({ required = false, onChange: handleChangeFn, ...props }: InputProps) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        handleChangeFn(e.target.value);
    };
    return (
        <InputGroup>
            <InputWrapper>
                <InputElement {...props} autoComplete="off" onChange={handleChange} />
            </InputWrapper>
        </InputGroup>
    );
}

export default LinkInput;
