import classnames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { createPage } from 'store/pages/actions';
import FormInputField from 'components/inputs/FormInputField';
import FormTextArea from 'components/inputs/FormTextArea';
import ControlButton from 'components/controls/ControlButton';
import { Portal } from 'store/portals/types';
import { Page } from 'store/pages/types';
import {
    ContainerHeader,
    ContainerHeadline,
    ContainerComponent,
    ContainerBody,
    ContainerFooter,
    ContainerFooterSpacer,
} from 'components/Containers';
import TemplateSelect from './components/TemplateSelect';
import PageCreatePositionComponent from './components/PageCreatePositionComponent';
type PageCreateComponentProps = {
    portal?: Portal | null;
    onCreate: (page: Page) => void;
    onCancel: () => void;
};
function PageCreateComponent({
    portal = null,
    onCreate = () => {},
    onCancel = () => {},
}: PageCreateComponentProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const current = {
        portal: portal ? portal.id : '',
        parent: portal ? portal.root_page.id : '',
        after: '',
        name: '',
        template: '',
        description: '',
        keywords: '',
        nextAfter: '',
        nextParent: '',
    };

    const [page, setPage] = useState({
        ...current,
        nextAfter: current.after,
        nextParent: current.parent,
    });

    const [position, setPosition] = useState({
        name: current.name,
        portal: current.portal,
        parent: current.parent,
        after: current.after,
        nextAfter: current.after,
        nextParent: current.parent,
    });

    const handleNameChange = (value: string) => {
        setPosition({ ...position, name: value });
        setPage({ ...page, name: value });
    };
    const handleDescriptionChange = (value: string) => {
        setPage({ ...page, description: value });
    };
    const handleKeywordsChange = (value: string) => {
        setPage({ ...page, keywords: value });
    };
    const handleTemplateChange = ({ value }) => {
        setPage({ ...page, template: value });
    };

    const handlePositionChange = (parentPage: string, afterPage: string) => {
        setPage({ ...page, nextParent: parentPage, nextAfter: afterPage });
    };
    const handleCreate = (e) => {
        e.preventDefault();
        const update = { ...page, parent: page.nextParent, after: page.nextAfter };
        dispatch(createPage(update))
            .then((res) => onCreate(res.payload.page))
            .catch((err) => console.info(err));
    };
    return (
        <form onSubmit={handleCreate}>
            <ContainerComponent>
                <ContainerHeader>
                    <ContainerHeadline>{t('page:create.title')}</ContainerHeadline>
                </ContainerHeader>
                <ContainerBody>
                    <FormInputField
                        type="text"
                        name="page_name"
                        id="inputPageSettingName"
                        label={t('page:settings.name')}
                        required={true}
                        autoFocus={true}
                        value={page.name}
                        onChange={handleNameChange}
                    />
                    <TemplateSelect
                        id="inputPageSettingTemplate"
                        name="template"
                        label={t('page:settings.template')}
                        value={page.template}
                        onChange={handleTemplateChange}
                    />
                    <FormTextArea
                        id="inputPageSettingDescription"
                        name="description"
                        label={t('page:settings.description')}
                        value={page.description}
                        onChange={handleDescriptionChange}
                    />
                    <FormTextArea
                        id="inputPageSettingKeywords"
                        name="keywords"
                        label={t('page:settings.keywords')}
                        value={page.keywords}
                        onChange={handleKeywordsChange}
                    />
                    {portal && (
                        <PageCreatePositionComponent
                            id="inputPagePosition"
                            label={t('page:settings.position.position')}
                            portal={portal}
                            value={position}
                            onChange={handlePositionChange}
                        />
                    )}
                </ContainerBody>
                <ContainerFooter>
                    <ContainerFooterSpacer />
                    <ControlButton
                        type="submit"
                        label="page:create.save"
                        icon={faPlus}
                        className="button-create"
                    />
                </ContainerFooter>
            </ContainerComponent>
        </form>
    );
}

export default PageCreateComponent;
