import React from 'react';
import styled from '@emotion/styled';

export const ContainerHeadline = styled.h1`
    font-size: 18px;
`;

export const ContainerHeader = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.25rem 0.5rem;
    border-bottom: 1px solid #707070;
    ${ContainerHeadline} {
        margin-bottom: 0;
    }
`;

export const ContainerHeaderOptions = styled.div``;

export const ContainerItemControls = styled.div`
    display: flex;
    flex-shrink: 0;
    align-self: stretch;
    align-items: center;
    .button__text {
        display: none;
    }
`;
export const ContainerItemText = styled.span``;
export const ContainerItemField = styled.div`
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    align-items: center;
`;

export function ContainerItemTitleField({ children }) {
    return (
        <ContainerItemField>
            <ContainerItemText>{children}</ContainerItemText>
        </ContainerItemField>
    );
}

export const ContainerItem = styled.li`
    display: flex;
    padding: 0.3rem 0.5rem;
    color: #4c4c4c;
`;

export const ContainerBody = styled.div`
    margin: 0;
    padding: 0;
    list-style: none;
    ${ContainerHeader} + & {
        margin-top: 1rem;
    }
`;

export const ContainerFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.25rem 0.5rem;
`;

export const ContainerFooterSpacer = styled.div``;

export const ContainerInfo = styled.div`
    background-color: #ebf3fb;
    padding: 1rem;
    ${ContainerHeader} + & {
        margin-top: 1rem;
    }
`;

export const ContainerComponent = styled.section`
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    .page-content & {
    }
`;

export const ContainerTabContainer = styled.div`
    background-color: #ebebeb;
`;

export const ContentTabButton = styled.button`
    display: inline-block;
    font-weight: 400;
    color: #848484;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    ${(props) =>
        props.active &&
        `
    background-color: #A3A3A3;
    color: #FFF;
`}
`;
