import { AppState } from 'store/types';
import { PortalPaginationPage, Portal, PortalItem } from './types';

export const portalPageSelector = (page: number) => (state: AppState): PortalPaginationPage => {
    return Object.assign({ isFetching: false, ids: [] }, state.portals.pagination.pages[page]);
};

export const portalSelector = (portal: string) => (state: AppState): PortalItem => {
    if (portal === '') {
        return { isFetching: false, error: null, node: null };
    }
    return Object.assign({ isFetching: false }, state.portals.portals[portal]);
};

export const portalPagesSelector = (portal: string) => (state: AppState) => {
    const { isFetching, ids } = state.portals.portalPages[portal];
    return { isFetching, pages: ids.map((item) => state.pages.pages[item].node) };
};
