import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import { InputPassword, InputUsername, InputWrapper } from '../inputs/InputComponent';

const defaultLabels = {
    title: 'Login',
    username: 'Username',
    password: 'Password',
    submitAction: 'Login',
};

const LoginTitle = styled.h2`
    text-align: center;
`;

const LoginHeader = styled.div``;

const LoginWrapper = styled.form``;

const LoginContainer = styled.div`
  ${InputWrapper} + ${InputWrapper} {
    margin-top: 1rem;
  }
`;

const LoginFooter = styled.div`
    margin-top: 1rem;
`;

const LoginSubmitButton = styled.button``;

function LoginFormular({
    onSubmit: handleSubmitFn,
    initalUsername = '',
    initalPassword = '',
    ...props
}) {
    const { labels = defaultLabels } = props;
    const currentLabels = Object.assign({}, defaultLabels, labels);
    const [username, setUsername] = useState(initalUsername);
    const [password, setPassword] = useState(initalPassword);

    useEffect(() => {
        setUsername(initalUsername);
        setPassword(initalPassword);
    }, [initalPassword, initalUsername]);

    const handleUsernameChange = (value) => setUsername(value);
    const handlePasswordChange = (value) => setPassword(value);

    const handleSubmit = (e) => {
        e.preventDefault();
        handleSubmitFn(username, password);
    };

    return (
        <LoginWrapper onSubmit={handleSubmit} {...props}>
            <LoginHeader>
                <LoginTitle>{currentLabels.title}</LoginTitle>
            </LoginHeader>
            <LoginContainer>
                <InputUsername
                    name="username"
                    id="inputUser"
                    label={currentLabels.username}
                    required={true}
                    autoFocus={true}
                    value={username}
                    onChange={handleUsernameChange}
                />
                <InputPassword
                    name="password"
                    id="inputPassword"
                    label={currentLabels.password}
                    required={true}
                    value={password}
                    onChange={handlePasswordChange}
                />
            </LoginContainer>
            <LoginFooter>
                <LoginSubmitButton type="submit" className="button button-success">
                    {currentLabels.submitAction}
                </LoginSubmitButton>
            </LoginFooter>
        </LoginWrapper>
    );
}

export default LoginFormular;
