export const PageActionTypes = {
    PAGE_LIST_REQUEST: 'PAGES/PAGE_LIST_REQUEST',
    PAGE_LIST_SUCCESS: 'PAGES/PAGE_LIST_SUCCESS',
    PAGE_LIST_FAILURE: 'PAGES/PAGE_LIST_FAILURE',

    PAGE_GET_REQUEST: 'PAGES/PAGE_GET_REQUEST',
    PAGE_GET_SUCCESS: 'PAGES/PAGE_GET_SUCCESS',
    PAGE_GET_FAILURE: 'PAGES/PAGE_GET_FAILURE',

    PAGE_UPDATE_REQUEST: 'PAGES/PAGE_UPDATE_REQUEST',
    PAGE_UPDATE_SUCCESS: 'PAGES/PAGE_UPDATE_SUCCESS',
    PAGE_UPDATE_FAILURE: 'PAGES/PAGE_UPDATE_FAILURE',

    PAGE_CREATE_SUCCESS: 'PAGES/PAGE_CREATE_SUCCESS',
    PAGE_DELETE_FAILURE: 'PAGES/PAGE_DELETE_FAILURE',
};

export const PortalActionTypes = {
    PORTAL_LIST_REQUEST: 'PORTALS/PORTAL_LIST_REQUEST',
    PORTAL_LIST_SUCCESS: 'PORTALS/PORTAL_LIST_SUCCESS',
    PORTAL_LIST_FAILURE: 'PORTALS/PORTAL_LIST_FAILURE',

    PORTAL_GET_REQUEST: 'PORTALS/PORTAL_GET_REQUEST',
    PORTAL_GET_SUCCESS: 'PORTALS/PORTAL_GET_SUCCESS',
    PORTAL_GET_FAILURE: 'PORTALS/PORTAL_GET_FAILURE',

    PORTAL_PAGES_REQUEST: 'PORTALS/PORTAL_PAGES_REQUEST',
    PORTAL_PAGES_SUCCESS: 'PORTALS/PORTAL_PAGES_SUCCESS',
    PORTAL_PAGES_FAILURE: 'PORTALS/PORTAL_PAGES_FAILURE',

    PORTAL_UPDATE_REQUEST: 'PORTALS/PORTAL_UPDATE_REQUEST',
    PORTAL_UPDATE_SUCCESS: 'PORTALS/PORTAL_UPDATE_SUCCESS',
    PORTAL_UPDATE_FAILURE: 'PORTALS/PORTAL_UPDATE_FAILURE',

    PORTAL_CREATE_SUCCESS: 'PORTALS/PORTAL_CREATE_SUCCESS',
    PORTAL_DELETE_SUCCESS: 'PORTALS/PORTAL_DELETE_SUCCESS',
};

export const TemplateActionTypes = {
    LIST_REQUEST: 'TEMPLATES/LIST_REQUEST',
    LIST_SUCCESS: 'TEMPLATES/LIST_SUCCESS',
    LIST_FAILURE: 'TEMPLATES/LIST_FAILURE',

    GET_TEMPLATE_REQUEST: 'TEMPLATES/GET_TEMPLATE_REQUEST',
    GET_TEMPLATE_SUCCESS: 'TEMPLATES/GET_TEMPLATE_SUCCESS',
    GET_TEMPLATE_FAILURE: 'TEMPLATES/GET_TEMPLATE_FAILURE',

    UPDATE_TEMPLATE_REQUEST: 'TEMPLATES/UPDATE_TEMPLATE_REQUEST',
    UPDATE_TEMPLATE_SUCCESS: 'TEMPLATES/UPDATE_TEMPLATE_SUCCESS',
    UPDATE_TEMPLATE_FAILURE: 'TEMPLATES/UPDATE_TEMPLATE_FAILURE',

    CREATE_TEMPLATE_SUCCESS: 'TEMPLATES/CREATE_TEMPLATE_SUCCESS',
    DELETE_TEMPLATE_SUCCESS: 'TEMPLATES/DELETE_TEMPLATE_SUCCESS',
};

export const ViewerActionTypes = {
    VIEWER_REQUEST: 'USERS/VIEWER_REQUEST',
    VIEWER_SUCCESS: 'USERS/VIEWER_SUCCESS',
    VIEWER_FAILURE: 'USERS/VIEWER_FAILURE',

    LOGIN_REQUEST: 'USERS/LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS/LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS/LOGIN_FAILURE',

    LOGOUT_REQUEST: 'USERS/LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'USERS/LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'USERS/LOGOUT_FAILURE',
};

export const ContentActionTypes = {
    CONTENT_COLUMN_REQUEST: 'CONTENTS/CONTENT_COLUMN_REQUEST',
    CONTENT_COLUMN_SUCCESS: 'CONTENTS/CONTENT_COLUMN_SUCCESS',
    CONTENT_COLUMN_FAILURE: 'CONTENTS/CONTENT_COLUMN_FAILURE',

    CONTENT_GET_REQUEST: 'CONTENTS/CONTENT_GET_REQUEST',
    CONTENT_GET_SUCCESS: 'CONTENTS/CONTENT_GET_SUCCESS',
    CONTENT_GET_FAILURE: 'CONTENTS/CONTENT_GET_FAILURE',

    CONTENT_UPDATE_REQUEST: 'CONTENTS/CONTENT_UPDATE_REQUEST',
    CONTENT_UPDATE_SUCCESS: 'CONTENTS/CONTENT_UPDATE_SUCCESS',
    CONTENT_UPDATE_FAILURE: 'CONTENTS/CONTENT_UPDATE_FAILURE',

    CONTENT_CREATE_SUCCESS: 'CONTENTS/CONTENT_CREATE_SUCCESS',
    CONTENT_DELETE_SUCCESS: 'CONTENTS/CONTENT_DELETE_SUCCESS',
};
