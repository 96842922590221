import React from 'react';
import styled from '@emotion/styled';

let ReactQuill = () => null;
if (!__SERVER__) {
    ReactQuill = require('react-quill');
}

const modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'list-style': ['pfeile', false] }],
        [{ 'block-style': ['blue', 'red', 'orange', 'green', false] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],

        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ['link', 'clean'],
    ],
};

const InputEditor = styled.textarea`
    background-color: #ffffff;
    .ql-list-style .ql-picker-label:before {
        content: 'List Styling';
        margin-right: 1rem;
    }
    .ql-list-style .ql-picker-item[data-value='pfeile']:before {
        content: 'Pfeile';
    }
    .ql-list-style .ql-picker-item:not([data-value]):before {
        content: 'Normal';
    }
    .ql-block-style .ql-picker-label:before {
        content: 'Block Styling';
        margin-right: 1rem;
    }
    .ql-block-style .ql-picker-item[data-value='blue']:before {
        content: 'Blau';
    }
    .ql-block-style .ql-picker-item[data-value='red']:before {
        content: 'Rot';
    }
    .ql-block-style .ql-picker-item[data-value='orange']:before {
        content: 'Orange';
    }
    .ql-block-style .ql-picker-item[data-value='green']:before {
        content: 'Grün';
    }
    .ql-block-style .ql-picker-item:not([data-value]):before {
        content: 'Normal';
    }
    .ql-block-style-blue {
        padding: 1em;
        background-color: #ecf2ff;
    }
    .ql-block-style-orange {
        padding: 1em;
        background-color: #f8daaa;
    }
    .ql-block-style-red {
        padding: 1em;
        background-color: #ffe8e8;
    }
    .ql-block-style-green {
        padding: 1em;
        background-color: #d9f2d6;
    }
    p {
        margin-bottom: 1em;
    }
`;
const QuillEditor = InputEditor.withComponent(ReactQuill);

function FormEditorText({ value, onChange: handleChangeFn }) {
    if (__SERVER__) {
        return <InputEditor />;
    }
    if (__BROWSER__) {
        const Quill = require('quill');
        const BackgroundClass = Quill.import('attributors/class/background');
        const ColorClass = Quill.import('attributors/class/color');
        const SizeStyle = Quill.import('attributors/style/size');
        const Link = Quill.import('formats/link');
        class CustomLink extends Link {
            static create(value) {
                const node = super.create(value);
                value = this.sanitize(value);
                node.setAttribute('href', value);
                let hasTarget = false;
                if (value.startsWith('https://') || value.startsWith('http://')) {
                    hasTarget = true;
                    node.setAttribute('rel', 'noopener noreferrer');
                    node.className = 'link--external';
                }
                if (value.endsWith('.pdf')) {
                    node.className = 'link--download';
                }
                if (hasTarget === false) {
                    node.removeAttribute('target');
                }
                return node;
            }
        }
        CustomLink.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', 'page'];
        const Parchment = Quill.import('parchment');
        const ListStyleClass = new Parchment.Attributor.Class('list-style', 'ql-list-style', {
            scope: Parchment.Scope.BLOCK,
        });
        Quill.register(ListStyleClass, true);
        const BlockStyleClass = new Parchment.Attributor.Class('block-style', 'ql-block-style', {
            scope: Parchment.Scope.BLOCK,
        });
        Quill.register(BlockStyleClass, true);
        Quill.register(BackgroundClass, true);
        Quill.register(ColorClass, true);
        Quill.register(SizeStyle, true);
        Quill.register(CustomLink);
    }
    return (
        <div className="text-editor">
            {/* <CustomToolbar /> */}
            <QuillEditor
                id="richtext-editor"
                onChange={handleChangeFn}
                modules={modules}
                theme="snow"
                value={value}
            />
        </div>
    );
}

export default FormEditorText;
