import React, { useState } from 'react';
import { ModalContainer } from 'components/controls/Modal';
import PortalCreateComponent from './components/PortalCreateComponent';

function PortalCreateModalComponent({ history }) {
    const [isOpen, toggleOpen] = useState(true);
    const handleCloseRequest = () => {
        toggleOpen(!isOpen);
        history.goBack();
    };
    const handeAfterCreate = () => {};
    return (
        <ModalContainer isOpen={isOpen} onRequestClose={handleCloseRequest}>
            <PortalCreateComponent
                className="modal-content"
                history={history}
                onCloseRequest={handeAfterCreate}
            />
        </ModalContainer>
    );
}

export default PortalCreateModalComponent;
