import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { faSignOut, faUser } from '@fortawesome/pro-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ControlButton from '../controls/ControlButton';
type ProfileComponentViewer = {
    username: string;
};
type ProfileComponentProps = {
    viewer: ProfileComponentViewer;
    onChange: () => void;
};

function ProfileComponent({ viewer, onChange: handleChangeFn }: ProfileComponentProps) {
    const { t } = useTranslation();
    const handleLogout = (e) => {
        e.preventDefault();
        handleChangeFn();
    };
    if (!viewer) {
        return null;
    }
    return (
        <form className="app-user profile-card" method="POST" onSubmit={handleLogout}>
            <div className="profile-card__wrapper">
                <div className="profile-card__user-fields">
                    <span className="profile-card__title">{viewer.username}</span>
                </div>
                <div className="profile-card__actions">
                    <ControlButton
                        className="button--logout"
                        type="submit"
                        label="user:logout"
                        icon={faSignOut}
                    />
                </div>
            </div>

            <div className="profile-card__picture">
                <FontAwesomeIcon className="profile-card__picture-icon" icon={faUser} />
            </div>
        </form>
    );
}

export default ProfileComponent;
