import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// import Select from 'react-select/async';
import Select from 'react-select';
import { templatesSelector } from 'store/templates/selectors';
import { InputGroup, InputLabel } from 'components/inputs/InputComponent';
import { fetchTemplatePage } from 'store/templates/actions';
import { Loading } from 'components/general/LoadinInfo';

function TemplateSelect({ id, name, label, value: initalValue, onChange: handleChangeFn }) {
    const { t } = useTranslation();
    const [value, setValue] = useState(initalValue);
    const [isLoaded, setLoaded] = useState(false);
    const templates = useSelector(templatesSelector);

    const dispatch = useDispatch();
    useEffect(() => {
        function preparePages() {
            dispatch(fetchTemplatePage(1, '', 50))
                .then(() => setLoaded(true))
                .catch((err) => setLoaded(true));
        }
        preparePages();
    }, [dispatch]);
    useEffect(() => {
        setValue(templates.find((item) => item.value === initalValue));
    }, [templates, initalValue]);
    const handleChange = (next) => {
        setValue(next);
        handleChangeFn(next);
    };
    return (
        <InputGroup>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            {!isLoaded && <Loading />}
            {isLoaded && (
                <Select
                    id={id}
                    name={name}
                    placeholder={label}
                    required={false}
                    autoFocus={false}
                    autoComplete="off"
                    value={value}
                    options={templates}
                    onChange={handleChange}
                />
            )}
        </InputGroup>
    );
}

export default TemplateSelect;
