import { combineReducers } from 'redux';
import { ContentActionTypes } from 'store/actions';
import { ContentColumns, ContentColumnsAction, ContentMap, ContentAction } from './types';

export const initialContentColumnsState = Object.freeze<ContentColumns>({});
export const initialContentMapState = Object.freeze<ContentMap>({});

const columns = (
    state: ContentColumns = initialContentColumnsState,
    action: ContentColumnsAction
) => {
    const { type, payload } = action;
    switch (type) {
        case ContentActionTypes.CONTENT_COLUMN_REQUEST: {
            const currrentPage = Object.assign({}, state[payload.page]);
            const updatePage = Object.assign({}, currrentPage, {
                isFetching: true,
                error: null,
            });
            return Object.assign({}, state, {
                [payload.page]: updatePage,
            });
        }
        case ContentActionTypes.CONTENT_COLUMN_FAILURE: {
            const currrentPage = Object.assign({}, state[payload.page]);
            const updatePage = Object.assign({}, currrentPage, {
                isFetching: false,
                error: payload.error,
            });
            return Object.assign({}, state, {
                [payload.page]: updatePage,
            });
        }
        case ContentActionTypes.CONTENT_COLUMN_SUCCESS: {
            const currrentPage = Object.assign({}, state[payload.page]);
            const updatePage = Object.assign({}, currrentPage, {
                isFetching: false,
                error: null,
                ids: (payload.contents || []).map((item) => ({
                    id: item.id,
                    after: item.after,
                    page: item.page,
                    column: item.column,
                })),
            });
            return Object.assign({}, state, {
                [payload.page]: updatePage,
            });
        }
        default:
            return state;
    }
};

const contents = (
    state: ContentMap = initialContentMapState,
    action: ContentAction | ContentColumnsAction
) => {
    const { type, payload } = action;
    switch (type) {
        case ContentActionTypes.CONTENT_GET_REQUEST: {
            return Object.assign({}, state, {
                [payload.id]: {
                    isFetching: true,
                    error: null,
                },
            });
        }
        case ContentActionTypes.CONTENT_GET_FAILURE: {
            return Object.assign({}, state, {
                [payload.id]: {
                    isFetching: false,
                    error: payload.error,
                    node: null,
                },
            });
        }
        case ContentActionTypes.CONTENT_UPDATE_SUCCESS:
        case ContentActionTypes.CONTENT_GET_SUCCESS: {
            return Object.assign({}, state, {
                [payload.id]: {
                    isFetching: false,
                    node: payload.content,
                    error: null,
                },
            });
        }

        case ContentActionTypes.CONTENT_COLUMN_SUCCESS: {
            let _contents = {};
            for (const content of payload.contents || []) {
                const current = state[content.id] ? state[content.id].node : {};
                _contents = Object.assign({}, _contents, {
                    [content.id]: {
                        isFetching: false,
                        node: Object.assign({}, current, content),
                    },
                });
            }
            return Object.assign({}, state, _contents);
        }
        default:
            return state;
    }
};

export default combineReducers({
    contents,
    columns,
});
