import React, { createContext, useContext, useState } from 'react';

const TabContext = createContext({
    tab: '',
    handleChange: () => {},
});

export function TabContainer({ children, init = '' }) {
    const [state, setState] = useState(init);
    const contextValue = {
        tab: state,
        handleChange: (tab: string) => setState(tab),
    };
    return <TabContext.Provider value={contextValue}>{children}</TabContext.Provider>;
}

export function TabControls({ children }) {
    const c = useContext(TabContext);
    return <React.Fragment>{children(c.tab, c.handleChange)}</React.Fragment>;
}

export default TabContainer;
