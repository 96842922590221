import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Content } from 'types';
import { InputGroup, InputLabel } from 'components/inputs/InputComponent';
import {
    OptionPanel,
    OptionPanelCell,
    OptionPanelContent,
    OptionPanelHeader,
    OptionPanelRow,
    OptionPanelHeadline,
} from '../../../components/general/Panel';

const columns = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
];

const componentLayouts = [
    {
        label: 'Default',
        value: 'default',
    },
    {
        label: 'H1',
        value: 'h1',
    },
    {
        label: 'H2',
        value: 'h2',
    },
    {
        label: 'H3',
        value: 'h3',
    },
    {
        label: 'H4',
        value: 'h4',
    },
    {
        label: 'H5',
        value: 'h5',
    },
    {
        label: 'Hidden',
        value: 'hidden',
    },
];

const componentStyles = [
    {
        label: 'Default',
        value: 'default',
    },
    {
        label: 'Einleitung',
        value: 'introduction',
    },
    {
        label: 'Blauer Kasten',
        value: 'bluebox',
    },
    {
        label: 'Roter Kaster',
        value: 'redbox',
    },
    {
        label: 'Orange Kasten',
        value: 'orangebox',
    },
    {
        label: 'Grün Kasten',
        value: 'greenbox',
    },
];

type ContentApperenceProps = {
    content: Content;
    onChange: (x: Content) => void;
};

function ContentApperence({ content, onChange: handleChangeFn }: ContentApperenceProps) {
    const { t } = useTranslation();

    const [state, setState] = useState({
        content_cols: columns.find((item) => item.value === content.content_cols) || columns[0],
        component_layout:
            componentLayouts.find((item) => item.value === content.component_layout) ||
            componentLayouts[0],
        component_style:
            componentStyles.find((item) => item.value === content.component_style) ||
            componentStyles[0],
    });
    useEffect(() => {
        setState((s) => ({
            content_cols: columns.find((item) => item.value === content.content_cols) || columns[0],
            component_layout:
                componentLayouts.find((item) => item.value === content.component_layout) ||
                componentLayouts[0],
            component_style:
                componentStyles.find((item) => item.value === content.component_style) ||
                componentStyles[0],
        }));
    }, [content]);

    const updateChange = (update) => {
        const next = Object.assign({}, state, update);
        handleChangeFn({
            content_cols: next.content_cols.value,
            component_layout: next.component_layout.value,
            component_style: next.component_style.value,
        });
        setState(next);
    };

    const handleColumnSelect = (cur) => {
        updateChange({
            content_cols: cur,
        });
    };

    const handleLayoutSelect = (cur) => {
        updateChange({
            component_layout: cur,
        });
    };

    const handleStylingSelect = (cur) => {
        updateChange({
            component_style: cur,
        });
    };

    return (
        <React.Fragment>
            <OptionPanel>
                <OptionPanelHeader>
                    <OptionPanelHeadline>
                        {t('content:create.apperence.section.layout.title')}
                    </OptionPanelHeadline>
                </OptionPanelHeader>
                <OptionPanelContent>
                    <OptionPanelRow>
                        <OptionPanelCell>
                            <InputGroup>
                                <InputLabel>
                                    {t('content:create.apperence.layout.title')}
                                </InputLabel>
                                <Select
                                    options={componentLayouts}
                                    value={state.component_layout}
                                    onChange={handleLayoutSelect}
                                />
                            </InputGroup>
                        </OptionPanelCell>
                        <OptionPanelCell>
                            <InputGroup>
                                <InputLabel>
                                    {t('content:create.apperence.styling.title')}
                                </InputLabel>
                                <Select
                                    options={componentStyles}
                                    value={state.component_style}
                                    onChange={handleStylingSelect}
                                />
                            </InputGroup>
                        </OptionPanelCell>
                    </OptionPanelRow>
                </OptionPanelContent>
            </OptionPanel>
            <OptionPanel>
                <OptionPanelContent>
                    <OptionPanelRow>
                        <OptionPanelCell>
                            <InputGroup>
                                <InputLabel>{t('content:create.content.column.title')}</InputLabel>
                                <Select
                                    options={columns}
                                    value={state.content_cols}
                                    onChange={handleColumnSelect}
                                />
                            </InputGroup>
                        </OptionPanelCell>
                    </OptionPanelRow>
                </OptionPanelContent>
            </OptionPanel>
        </React.Fragment>
    );
}

export default ContentApperence;
