import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContainerBody, ContainerTabContainer, ContentTabButton } from 'components/Containers';

import { EditorContainer } from 'components/EditorContainer';
import TabContainer, { TabControls } from 'components/Tabs';
import ContentPageOptions from './ContentPageOptions';
import ContentImage from './ContentImage';
import ContentHeader from './ContentHeader';
import ContentText from './ContentText';
import ContentApperence from './ContentApperence';
import ContentEmbedded from './ContentEmbedded';

const typeTabs = {
    images: ['general', 'headline', 'image', 'apperence'],
    text: ['general', 'text', 'apperence'],
    'text-images': ['general', 'text', 'image', 'apperence'],
    embedded: ['general', 'embedded'],
};

const handleTypeChange = (state, handleChange) => (type, column) => {
    handleChange({
        ...state,
        type,
        column,
    });
};

const handleHeaderChange = (state, handleChange) => (data) => {
    handleChange({
        ...state,
        ...data,
    });
};

const handleTextChange = (state, handleChange) => (data) => {
    handleChange({
        ...state,
        ...data,
    });
};

const handleApperenceChange = (state, handleChange) => (data) => {
    handleChange({
        ...state,
        ...data,
    });
};

const handleEmbeddedChange = (state, handleChange) => (data) => {
    handleChange({
        ...state,
        ...data,
    });
};

const handleImageAdd = (_, handleChange) => (data) => {
    handleChange('image', data);
};

const handleImageDelete = (_, handleChange) => (id) => {
    handleChange('image', id);
};

const handleImageChange = (_, handleChange) => (id, data) => {
    handleChange('image', id, data);
};

const handlePositionChange = (state, handleChange) => (data) => {
    handleChange({
        ...state,
        imageorient: data,
    });
};

const handleColumnChange = (state, handleChange) => (data) => {
    handleChange({
        ...state,
        imagecols: data,
    });
};

type OptionTabProps = {
    content: any;
    page: any;
    items: { [x: string]: any };
    onChange: (data: any) => void;
    onItemAdd?: (type: string, data: any) => void;
    onItemChange?: (type: string, id: string, data: any) => void;
    onItemDelete?: (type: string, id: string) => void;
};

type OptionTab = (props: OptionTabProps) => JSX.Element;

type OptionTabs = {
    [x: string]: OptionTab;
};

const tabs: OptionTabs = {
    general: ({ content, page, onChange }) => (
        <ContentPageOptions
            page={page}
            type={content.type}
            column={content.column}
            onChange={handleTypeChange(content, onChange)}
        />
    ),
    image: ({ content, items, onChange, onItemAdd, onItemChange, onItemDelete }) => (
        <ContentImage
            items={items.images || []}
            orientationValue={content.imageorient}
            columnValue={content.imagecols}
            onImagePositionChange={handlePositionChange(content, onChange)}
            onImageColumnChange={handleColumnChange(content, onChange)}
            onImageAdd={handleImageAdd(content, onItemAdd)}
            onImageDelete={handleImageDelete(content, onItemDelete)}
            onImageChange={handleImageChange(content, onItemChange)}
        />
    ),
    text: ({ content, onChange }) => (
        <React.Fragment>
            <ContentHeader content={content} onChange={handleHeaderChange(content, onChange)} />
            <ContentText content={content} onChange={handleTextChange(content, onChange)} />
        </React.Fragment>
    ),
    headline: ({ content, onChange }) => (
        <ContentHeader content={content} onChange={handleHeaderChange(content, onChange)} />
    ),
    apperence: ({ content, onChange }) => (
        <ContentApperence content={content} onChange={handleApperenceChange(content, onChange)} />
    ),
    embedded: ({ content, onChange }) => (
        <ContentEmbedded content={content} onChange={handleEmbeddedChange(content, onChange)} />
    ),
};

const handleTabChange = (next: string, handleChange: (x: string) => void) => (e) => {
    e.preventDefault();
    handleChange(next);
};

function ContentEditor({ page, content, items, onChange, onItemAdd, onItemChange, onItemDelete }) {
    const { t } = useTranslation();
    return (
        <TabContainer init="general">
            <ContainerBody>
                <EditorContainer>
                    <ContainerTabContainer>
                        <TabControls>
                            {(tab, changeTab) =>
                                typeTabs[content.type] &&
                                typeTabs[content.type].map((key) => (
                                    <ContentTabButton
                                        key={key}
                                        active={tab === key}
                                        onClick={handleTabChange(key, changeTab)}
                                    >
                                        {t(`content:form.tab.${key}.button`)}
                                    </ContentTabButton>
                                ))
                            }
                        </TabControls>
                    </ContainerTabContainer>
                    <TabControls>
                        {(tab) =>
                            tabs[tab] &&
                            tabs[tab]({
                                page,
                                content,
                                items,
                                onChange,
                                onItemAdd,
                                onItemChange,
                                onItemDelete,
                            })
                        }
                    </TabControls>
                </EditorContainer>
            </ContainerBody>
        </TabContainer>
    );
}

export default ContentEditor;
