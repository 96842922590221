import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import Select from 'react-select/async';
import Select from 'react-select';

import { useTranslation } from 'react-i18next';
import { faNewspaper } from '@fortawesome/pro-regular-svg-icons';
import { pageListSelector, pagesSelector, pageSelector } from 'store/pages/selectors';
import { fetchPageList, fetchPage } from 'store/pages/actions';
import Input, {
    InputGroup,
    InputLabel,
    InputWrapper,
    InputElement,
    InputGroupWrapper,
    InputGroupActions,
} from 'components/inputs/InputComponent';
import { Loading } from 'components/general/LoadinInfo';
import ControlButton from 'components/controls/ControlButton';
import { ModalWrapper } from 'components/controls/Modal';
import PageSelectComponent from './PageSelectComponent';

function ContentPageSelect(props) {
    const { id, label, value: initalValue = '', onChange: handleChangeFn } = props;
    const { t } = useTranslation();
    const [value, setValue] = useState(initalValue);
    const [selectModal, setSelectModal] = useState(false);

    const { isFetching, node: content_page } = useSelector(pageSelector(value));
    const [resolved, setResolved] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchPage(initalValue))
            .then((res) => setResolved(true))
            .catch((res) => setResolved(true));
    }, [dispatch, initalValue]);
    const handleModalClose = () => {
        setSelectModal(false);
    };
    const handleModalOpen = () => {
        setSelectModal(true);
    };
    const handleChange = (next: string) => {
        setValue(next);
        handleChangeFn(next);
    };
    return (
        <React.Fragment>
            <InputGroup>
                <InputLabel htmlFor={id}>{label}</InputLabel>
                <InputGroupWrapper>
                    <InputWrapper>
                        {(isFetching || !resolved) && <Loading />}
                        {!isFetching && resolved && content_page && (
                            <InputLabel>{content_page.name}</InputLabel>
                        )}
                        {!isFetching && resolved && !content_page && (
                            <InputLabel>{t('page:settings.content_page.placeholder')}</InputLabel>
                        )}
                    </InputWrapper>
                    <InputGroupActions>
                        <ControlButton
                            type="button"
                            icon={faNewspaper}
                            label={t('page:settings.content_page.select')}
                            onClick={handleModalOpen}
                        />
                    </InputGroupActions>
                </InputGroupWrapper>
            </InputGroup>
            <ModalWrapper isOpen={selectModal} onClose={handleModalClose}>
                {(close) => {
                    const handleSelect = (p) => {
                        handleChange(p.id);
                        close();
                    };
                    return <PageSelectComponent onChange={handleSelect} />;
                }}
            </ModalWrapper>
        </React.Fragment>
    );
}

export default ContentPageSelect;
