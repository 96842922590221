import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link, Route, Switch, withRouter, RouteComponentProps } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { fetchUser, logoutUser } from 'store/viewer/actions';
import { viewerSelector } from 'store/viewer/selectors';
import DashboardComponent from 'views/DashboardComponent';
import ErrorBoundary from 'components/general/ErrorBoundary';
import { RoutedErrorPage } from 'components/general/ErrorPage';
import LoginComponent from 'views/LoginComponent';
import NavBar from 'components/controls/NavBar';
import NavLink from 'components/controls/NavLink';
import PageCreateModalComponent from 'views/pages/PageCreateModalComponent';
import PagesComponent from 'views/PagesComponent';
import PortalCreateModalComponent from 'views/portals/PortalCreateModalComponent';
import PortalsComponent from 'views/PortalsComponent';
import PrivateRoute from 'components/general/PrivateRoute';
import ProfileComponent from 'components/users/ProfileComponent';

import 'style/main.scss';
import ContentsComponent from 'views/ContentsComponent';

function App({ location, history }: RouteComponentProps) {
    const { t } = useTranslation();
    const { isFetching, viewer, error } = useSelector(viewerSelector);
    const [previousLocation, setPreviousLocation] = useState(location);
    const [resolved, setResolved] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchUser())
            .then((res) => setResolved(true))
            .catch((res) => setResolved(true));
    }, [dispatch]);
    useEffect(() => {
        setPreviousLocation((current) => {
            if (history.action !== 'POP' && (!location.state || !location.state.modal)) {
                return location;
            }
            return current;
        });
    }, [history, history.action, location, location.state]);

    const handleLogout = () => {
        dispatch(logoutUser())
            .then((res) => {
                location.pathname = '/';
                setPreviousLocation(location);
                history.push('/');
            })
            .catch((err) => {
                console.info(err);
            });
    };
    // not initial render
    const name = t('app:name');
    const isModal = !!(location.state && location.state.modal && location !== previousLocation);
    return (
        <React.Fragment>
            <Helmet defaultTitle={name} titleTemplate={`%s - ${name}`} />
            <ErrorBoundary>
                <header className="app-header">
                    <Link to="/" className="app-logo c-logo">
                        {name}
                    </Link>
                    {resolved && !isFetching && viewer && (
                        <ProfileComponent viewer={viewer} onChange={handleLogout} />
                    )}
                </header>
                {resolved && !isFetching && (viewer || error) && (
                    <div className="app-body" role="main">
                        {viewer && (
                            <NavBar>
                                <NavLink to="/portals" label={t('navigation:portals')} />
                                <NavLink to="/pages" label={t('navigation:pages')} />
                                <NavLink to="/users" label={t('navigation:users')} />
                            </NavBar>
                        )}
                        <Switch location={isModal ? previousLocation : location}>
                            <PrivateRoute path="/" exact={true} component={DashboardComponent} />
                            <PrivateRoute path="/portals" component={PortalsComponent} />
                            <PrivateRoute path="/contents" component={ContentsComponent} />
                            <PrivateRoute path="/pages" component={PagesComponent} />
                            <Route path="/auth/login" component={LoginComponent} />
                            <PrivateRoute component={RoutedErrorPage} />
                        </Switch>
                        {isModal && (
                            <React.Fragment>
                                <Route
                                    path={'/pages/create'}
                                    component={PageCreateModalComponent}
                                />
                                <Route
                                    path={'/portals/create'}
                                    component={PortalCreateModalComponent}
                                />
                            </React.Fragment>
                        )}
                    </div>
                )}
            </ErrorBoundary>
        </React.Fragment>
    );
}

export default withRouter(App);
