import React, { useState, useEffect } from 'react';
import { faPlus, faTimes, faArrowUp, faArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router';

import { Page } from 'store/pages/types';
import { TemplateColumn } from 'store/templates/types';
import { pageContentSelector } from 'store/contents/selectors';
import { ContentPosElement } from 'store/contents/types';
import { fetchContentsColumn, updateContentPosition, deleteContent } from 'store/contents/actions';

import ControlButton from 'components/controls/ControlButton';
import { ModalWrapper } from 'components/controls/Modal';
import { Loading } from 'components/general/LoadinInfo';

import ContentTypeSelection from 'views/contents/components/ContentTypeSelection';
import ContentElement from 'views/contents/global/previews/ContentElement';

type ContentColumnProps = RouteComponentProps & {
    page: Page;
    column: TemplateColumn;
};

const renderComponentPosition = (
    dispatch: any,
    node: ContentPosElement,
    contents: ContentPosElement[],
    i: number
) => () => {
    const handleUpdatePostionUp = () => {
        dispatch(updateContentPosition(node.id, node.column, contents[i - 1].after))
            .then(() => dispatch(fetchContentsColumn(node.page, node.column)))
            .catch((err) => {
                console.info(err);
            });
    };
    const handleUpdatePostionDown = () => {
        dispatch(updateContentPosition(node.id, node.column, contents[i + 1].id))
            .then(() => dispatch(fetchContentsColumn(node.page, node.column)))
            .catch((err) => {
                console.info(err);
            });
    };
    return (
        <React.Fragment>
            {i > 0 && (
                <ControlButton
                    type="button"
                    prefix="button"
                    className="button-up"
                    icon={faArrowUp}
                    label="content:item.up.button"
                    onClick={handleUpdatePostionUp}
                />
            )}
            {i < contents.length - 1 && (
                <ControlButton
                    type="button"
                    prefix="button"
                    className="button-up"
                    icon={faArrowDown}
                    label="content:item.down.button"
                    onClick={handleUpdatePostionDown}
                />
            )}
        </React.Fragment>
    );
};

function PageContentColumnComponent({ page, column, history }: ContentColumnProps) {
    const { t } = useTranslation();
    const [modalCreate, setModalCreate] = useState(false);
    const dispatch = useDispatch();
    const { isFetching, contents } = useSelector(pageContentSelector(page.id, column.column));
    const [resolved, setResolved] = useState(false);
    const [createAfter, setCreateAfter] = useState('');
    useEffect(() => {
        dispatch(fetchContentsColumn(page.id, column.column))
            .then((res) => setResolved(true))
            .catch((err) => setResolved(true));
    }, [column.column, dispatch, page.id]);
    if (!resolved) {
        return <Loading />;
    }
    const handleOpenCreateModal = (cur) => () => {
        setModalCreate(true);
        setCreateAfter(cur.id);
    };
    const handleCloseCreateModal = () => {
        setModalCreate(false);
    };
    const handleElementDelete = (ev) => {
        setResolved(false);
        dispatch(deleteContent(ev.id))
            .then(dispatch(fetchContentsColumn(page.id, column.column)))
            .then((res) => setResolved(true))
            .catch((err) => setResolved(true));
    };
    if (isFetching || !resolved) {
        return <Loading />;
    }
    return (
        <section className="content-column">
            <header className="content-column__header">
                <h2>{column.name}</h2>
            </header>
            {(isFetching || !resolved) && <Loading />}
            {!isFetching && resolved && (
                <React.Fragment>
                    <div className="content-column__content">
                        {contents.map((node, index) => (
                            <ContentElement
                                key={node.id}
                                page={page.id}
                                portal={page.portal}
                                item={node.id}
                                renderPositions={renderComponentPosition(
                                    dispatch,
                                    node,
                                    contents,
                                    index
                                )}
                                onDelete={handleElementDelete}
                            >
                                {() => (
                                    <div className="content-element__footer">
                                        <ControlButton
                                            type="button"
                                            prefix="button"
                                            className="button-create"
                                            icon={faPlus}
                                            label="content:item.create.button"
                                            onClick={handleOpenCreateModal(node)}
                                        />
                                    </div>
                                )}
                            </ContentElement>
                        ))}
                    </div>
                    {contents.length <= 0 && (
                        <footer className="content-column__footer">
                            <ControlButton
                                type="button"
                                prefix="button"
                                className="button-create"
                                icon={faPlus}
                                label="content:item.create.button"
                                onClick={handleOpenCreateModal({ id: '' })}
                            />
                        </footer>
                    )}
                </React.Fragment>
            )}
            <ModalWrapper isOpen={modalCreate} onClose={handleCloseCreateModal}>
                {(close) => {
                    const handleTypeSelect = (type) => {
                        const params = new URLSearchParams();
                        params.append('page', page.id);
                        params.append('column', column.column);
                        params.append('type', type.value);
                        if (page.portal) {
                            params.append('portal', page.portal);
                        }
                        params.append('after', createAfter);
                        close();
                        history.push({
                            pathname: '/contents/create',
                            search: params.toString(),
                        });
                    };
                    return (
                        <React.Fragment>
                            <div className="modal-header">
                                <h1 className="modal-header__headline">
                                    {t('content:item.create.title')}
                                </h1>
                                <ControlButton
                                    type="button"
                                    label="modals:close"
                                    icon={faTimes}
                                    className="button-create"
                                    onClick={close}
                                />
                            </div>
                            <ContentTypeSelection onChange={handleTypeSelect} />
                        </React.Fragment>
                    );
                }}
            </ModalWrapper>
        </section>
    );
}

export default withRouter(PageContentColumnComponent);
