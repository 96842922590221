import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { History } from 'history';

import FormInputField from 'components/inputs/FormInputField';
import FormTextArea from 'components/inputs/FormTextArea';
import ControlButton from 'components/controls/ControlButton';

import { createPortal } from 'store/portals/actions';
import { Portal } from 'store/portals/types';
import {
    ContainerBody,
    ContainerComponent,
    ContainerHeader,
    ContainerHeadline,
    ContainerFooterSpacer,
    ContainerItemControls,
    ContainerFooter,
} from 'components/Containers';

type PortalCreateComponentProps = {
    history: History;
    onCloseRequest: () => void;
};

function PortalCreateComponent({ history, onCloseRequest }: PortalCreateComponentProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [portal, setPortal] = useState<Portal>({
        domain: '',
        name: '',
        description: '',
        keywords: '',
    });

    const handlePortalNameChange = (value: string) =>
        setPortal(Object.assign({}, portal, { name: value }));
    const handlePortalDomainChange = (value: string) =>
        setPortal(Object.assign({}, portal, { domain: value }));
    const handleDescriptionChange = (value: string) =>
        setPortal(Object.assign({}, portal, { description: value }));
    const handleKeywordsChange = (value: string) =>
        setPortal(Object.assign({}, portal, { keywords: value }));
    const handleCreate = (e) => {
        e.preventDefault();
        dispatch(createPortal(portal))
            .then((res) => {
                onCloseRequest(res);
                history.push(`/portals/${res.payload.id}`);
            })
            .catch((err) => {
                console.info(err);
            });
    };
    return (
        <form onSubmit={handleCreate}>
            <ContainerComponent>
                <ContainerHeader>
                    <ContainerHeadline>{t('portal:create.title')}</ContainerHeadline>
                </ContainerHeader>
                <ContainerBody>
                    <FormInputField
                        type="text"
                        name="portal_domain"
                        id="inputPortalSettingDomain"
                        label={t('portal:settings.domain')}
                        required={true}
                        autoFocus={true}
                        value={portal.domain}
                        onChange={handlePortalDomainChange}
                    />
                    <FormInputField
                        type="text"
                        name="portal_name"
                        id="inputPortalSettingName"
                        label={t('portal:settings.name')}
                        required={true}
                        value={portal.name}
                        onChange={handlePortalNameChange}
                    />
                    <FormTextArea
                        id="inputPortalSettingDescription"
                        name="description"
                        label={t('portal:settings.description')}
                        value={portal.description}
                        onChange={handleDescriptionChange}
                    />
                    <FormTextArea
                        id="inputPortalSettingKeywords"
                        name="keywords"
                        label={t('portal:settings.keywords')}
                        value={portal.keywords}
                        onChange={handleKeywordsChange}
                    />
                </ContainerBody>
                <ContainerFooter>
                    <ContainerFooterSpacer />
                    <ControlButton
                        type="submit"
                        label="portal:create.save"
                        icon={faPlus}
                        className="button-create"
                    />
                </ContainerFooter>
            </ContainerComponent>
        </form>
    );
}

export default PortalCreateComponent;
