import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router';

import PortalsOverviewComponent from './portals/PortalsOverviewComponent';
import PortalCreateComponent from './portals/components/PortalCreateComponent';
import PortalEditComponent from './portals/PortalEditComponent';

function PortalsComponent({ match }: RouteComponentProps) {
    return (
        <section className="app-content app-content--portals">
            <Switch>
                <Route path={`${match.path}/`} component={PortalsOverviewComponent} exact={true} />
                <Route path={`${match.path}/create`} component={PortalCreateComponent} />
                <Route path={`${match.path}/:portalID`} component={PortalEditComponent} />
            </Switch>
        </section>
    );
}

export default PortalsComponent;
