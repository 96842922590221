import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { faBars, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NavBar({ children }) {
    const { t } = useTranslation();
    const [open, setStatus] = useState(true);
    useEffect(() => {
        if (__BROWSER__) {
            if (
                document.cookie.split(';').filter((item) => item.includes('app-sidebar=false'))
                    .length
            ) {
                setStatus(false);
            }
        }
    }, []);
    const handleStatusChange = () => {
        setStatus(!open);
    };
    const title = t(open ? 'navigation:event.hide' : 'navigation:event.show');

    return (
        <nav
            className={classNames('app-sidebar ', {
                'app-sidebar--open': open,
                'app-sidebar--closed': !open,
            })}
        >
            <div className="app-sidebar__controls">
                <button onClick={handleStatusChange} className="app-sidebar__button" title={title}>
                    <FontAwesomeIcon icon={open ? faTimes : faBars} className="button__icon" />
                    <span className="button__text">{title}</span>
                </button>
            </div>
            <ul className="app-sidebar__content nav">{children}</ul>
        </nav>
    );
}

export default NavBar;
